import React from 'react';
import PropTypes from 'prop-types';

import { Grid, Typography } from '@material-ui/core';
import { Row } from '../../Resources';
import ActivitiesRegistrationHeader from 'components/ActivitiesRegistrationDetails/ActivitiesRegistrationHeader';

import { convertToReal } from 'helpers/converters';

export default function ConfigurationUpdate({ configuration, lastRegister, activityData }) {
  const { printTypes } = lastRegister;

  return(
    <Grid container>
      <Grid xs={12}>
        <ActivitiesRegistrationHeader
          activityData={activityData}
        />
        <Grid container style={{ marginTop: 10 }}>
          <Grid xs={12} md={6} style={{ padding: 5 }}>
            <Typography style={{ color: '#1e5168', marginBottom: 5 }}>Configuração Anterior</Typography>
            <Grid style={{ border: '1px solid #1e5168', borderRadius: 8, padding: 8 }}>
              <Row>
                <Typography>
                  <b>Comprovantes</b>
                </Typography>
              </Row>
              <Row>
                <Typography>Emissão de ticket</Typography>
                <Typography>{printTypes?.find(printType => +printType?.value == +lastRegister?.print)?.label}</Typography>
              </Row>
              <Row>
                <Typography>Observação na emissão</Typography>
                <Typography>{lastRegister?.printNote}</Typography>
              </Row>
              <Row>
                <Typography>
                  <b>Operação</b>
                </Typography>
              </Row>
              <Row>
                <Typography>Somente serviços</Typography>
                <Typography>{+lastRegister?.serviceOnly == 1 ? 'Ativado' : 'Desativado'}</Typography>
              </Row>
              <Row>
                <Typography>Convênio manual</Typography>
                <Typography>{+lastRegister?.manualCovenant == 1 ? 'Ativado' : 'Desativado'}</Typography>
              </Row>
              <Row>
                <Typography>Horário manual</Typography>
                <Typography>{+lastRegister?.manualTime == 1 ? 'Ativado' : 'Desativado'}</Typography>
              </Row>
              <Row>
                <Typography>Entrada sem cartão e impressão</Typography>
                <Typography>{+lastRegister?.recurrentClientEntryOption == 1 ? 'Ativado' : 'Desativado'}</Typography>
              </Row>
              <Row>
                <Typography>Pagamento antecipado com saída</Typography>
                <Typography>{+lastRegister?.prePaidExit == 1 ? 'Ativado' : 'Desativado'}</Typography>
              </Row>
              <Row>
                <Typography>Comprovante ao selecionar uma tabela convênio</Typography>
                <Typography>{+lastRegister?.requireReceiptCovantTypePrice == 1 ? 'Ativado' : 'Desativado'}</Typography>
              </Row>
              <Row>
                <Typography>Comprovante ao fazer um registro de despesa</Typography>
                <Typography>{+lastRegister?.requireReceiptExpense == 1 ? 'Ativado' : 'Desativado'}</Typography>
              </Row>
              <Row>
                <Typography>
                  <b>Caixa</b>
                </Typography>
              </Row>
              <Row>
                <Typography>Reserva de caixa</Typography>
                <Typography>{convertToReal(lastRegister?.cashReserve)}</Typography>
              </Row>
              <Row>
                <Typography>Fechamento de caixa</Typography>
                <Typography>{+lastRegister?.withdrawal == 0 ? 'Automático' : 'Manual'}</Typography>
              </Row>
            </Grid>
          </Grid>
          <Grid xs={12} md={6} style={{ padding: 5 }}>
            <Typography style={{ color: '#1e5168', marginBottom: 5 }}>Configuração Atual</Typography>
            <Grid style={{ border: '1px solid #1e5168', borderRadius: 8, padding: 8 }}>
              <Row>
                <Typography>
                  <b>Comprovantes</b>
                </Typography>
              </Row>
              <Row>
                <Typography>Emissão de ticket</Typography>
                <Typography>{printTypes?.find(printType => +printType?.value == +configuration?.print)?.label}</Typography>
              </Row>
              <Row>
                <Typography>Observação na emissão</Typography>
                <Typography>{configuration?.printNote}</Typography>
              </Row>
              <Row>
                <Typography>
                  <b>Operação</b>
                </Typography>
              </Row>
              <Row>
                <Typography>Somente serviços</Typography>
                <Typography>{+configuration?.serviceOnly == 1 ? 'Ativado' : 'Desativado'}</Typography>
              </Row>
              <Row>
                <Typography>Convênio manual</Typography>
                <Typography>{+configuration?.manualCovenant == 1 ? 'Ativado' : 'Desativado'}</Typography>
              </Row>
              <Row>
                <Typography>Horário manual</Typography>
                <Typography>{+configuration?.manualTime == 1 ? 'Ativado' : 'Desativado'}</Typography>
              </Row>
              <Row>
                <Typography>Entrada sem cartão e impressão</Typography>
                <Typography>{+configuration?.recurrentClientEntryOption == 1 ? 'Ativado' : 'Desativado'}</Typography>
              </Row>
              <Row>
                <Typography>Pagamento antecipado com saída</Typography>
                <Typography>{+configuration?.prePaidExit == 1 ? 'Ativado' : 'Desativado'}</Typography>
              </Row>
              <Row>
                <Typography>Comprovante ao selecionar uma tabela convênio</Typography>
                <Typography>{+configuration?.requireReceiptCovantTypePrice == 1 ? 'Ativado' : 'Desativado'}</Typography>
              </Row>
              <Row>
                <Typography>Comprovante ao fazer um registro de despesa</Typography>
                <Typography>{+configuration?.requireReceiptExpense == 1 ? 'Ativado' : 'Desativado'}</Typography>
              </Row>
              <Row>
                <Typography>
                  <b>Caixa</b>
                </Typography>
              </Row>
              <Row>
                <Typography>Reserva de caixa</Typography>
                <Typography>{convertToReal(configuration?.cashReserve)}</Typography>
              </Row>
              <Row>
                <Typography>Fechamento de caixa</Typography>
                <Typography>{+configuration?.withdrawal == 0 ? 'Automático' : 'Manual'}</Typography>
              </Row>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

ConfigurationUpdate.propTypes = {
  configuration: PropTypes.object.isRequired,
  lastRegister: PropTypes.object.isRequired,
  activityData: PropTypes.object.isRequired
};