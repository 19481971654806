import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Card from 'components/Card';
import Loader from 'components/Loader';
import NumericInput from 'components/NumericInput';
import Input from 'components/Input';
import Select from 'components/Select';
import Status from 'components/Status';
import Button from 'components/Button';
import Supplier from 'components/Supplier';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faPen } from '@fortawesome/free-solid-svg-icons';

import { loadSupplierList } from '../../../store/thunk';
import { setProductBasic, setSupplierUuid, setMeasurementUnitGroupId, clearSupplierErrors } from '../../../store/actions';

import { useStyles } from './styles';

export default function Basic({ formikProps }) {
  const dispatch = useDispatch();

  const classes = useStyles();

  const [isSupplierModalOpen, setIsSupplierModalOpen] = useState(false);

  const { values, errors, handleChange, setFieldValue } = formikProps;

  const { userId } = useSelector(state => state.profile);
  const { establishmentId } = useSelector(state => state.businessInfo);
  const { productBrands, productTypes, areasOfUse, measurementUnits } = useSelector(state => state.products.filters);
  const { isLoading, basicAndStorageResponseErrors, suppliers } = useSelector(state => state.productsUseAndSellRegistration);

  useEffect(() => {
    dispatch(loadSupplierList(userId, establishmentId));
  }, [establishmentId]);

  useEffect(() => {
    dispatch(setSupplierUuid(''));

    if(values?.supplierId) {
      const selectedSupplierUuid = suppliers?.find(supplier => +supplier?.id == +values?.supplierId)?.uuid || '';
      dispatch(setSupplierUuid(selectedSupplierUuid));
    }
  }, [values?.supplierId]);

  useEffect(() => {
    const groupId = measurementUnits
      ?.find(unit => +unit?.id == +values.measurementUnitId)
      ?.groupId || '';

    dispatch(setMeasurementUnitGroupId(groupId));
  }, [values.measurementUnitId]);

  const allowedMeasurementUnitsId = [1, 2, 5, 12, 15];

  const formattedProductBrand = productBrands?.map(brand => {
    return {
      value: brand?.id,
      label: brand?.name
    }
  });

  const formattedSuppliers = [
    {
      value: '',
      label: '--'
    },
    ...suppliers?.map(supplier => {
      return {
        value: supplier?.id,
        label: supplier?.supplierInfo?.corporateName
      }
    })
  ];

  const formattedAreasOfUse = areasOfUse?.map(area => {
    return {
      value: area?.id?.toString(),
      label: area?.description
    }
  });

  const formattedMeasurementUnits = measurementUnits
  ?.filter(unit => allowedMeasurementUnitsId.includes(unit?.id))
  ?.map(unit => {
    return {
      value: unit?.id,
      label: `${unit?.description} (${unit?.abbreviation})`
    }
  });

  const productTypesParentsId = productTypes
    ?.map(product => product.parentProductTypeId)
    ?.filter((product, index, array) => array?.indexOf(product) == index);

  const productTypesParents = productTypes
    ?.filter(product => productTypesParentsId?.includes(product?.id));

  const formattedProductType = productTypes
    ?.map(type => {
      return {
        parent: productTypesParents?.find(product => +product?.id == +type?.parentProductTypeId)?.description,
        value: type?.id,
        label: type?.description
      }
    })
    ?.filter(type => !!type.parent);

  return(
    <Card title="Insira as informações básicas do produto">
      {isSupplierModalOpen && (
        <Supplier
          isSupplierModalOpen={isSupplierModalOpen}
          setIsSupplierModalOpen={setIsSupplierModalOpen}
        />
      )}
      <Grid container>
        <Grid xs={12}>
          <Loader isLoading={isLoading}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Input
                  name="name"
                  type="text"
                  label="Nome do Produto"
                  value={values?.name}
                  onChange={handleChange}
                  error={errors?.name || !!basicAndStorageResponseErrors?.name}
                  helperText={errors?.name || basicAndStorageResponseErrors?.name}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Status
                  name="visible"
                  value={+values?.visible}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '10px' }}>
                <Select
                  name="supplierId"
                  label="Fornecedor"
                  options={formattedSuppliers}
                  value={values?.supplierId}
                  onChange={handleChange}
                />
                <Button
                  style={{ height: '50px' }}
                  type="button"
                  color="primary"
                  onClick={() => {
                    dispatch(clearSupplierErrors());
                    dispatch(setProductBasic(values));
                    setIsSupplierModalOpen(true);
                  }}
                >
                  <FontAwesomeIcon
                    size="lg"
                    color="#FFFFFF"
                    icon={!!values?.supplierId ? faPen : faPlus}
                  />
                </Button>
              </Grid>
              <Grid item xs={12} md={6}>
                <Autocomplete
                  id="productBrandId"
                  classes={classes}
                  options={formattedProductBrand}
                  value={formattedProductBrand?.find(brand => +brand.value == +values.productBrandId) || {}}
                  onChange={(_, params) => setFieldValue("productBrandId", params?.value || '')}
                  getOptionLabel={option => option.label}
                  renderInput={params => (
                    <TextField
                      {...params}
                      variant="outlined"
                      color="primary"
                      label="Marca"
                      error={errors?.productBrandId || !!basicAndStorageResponseErrors?.productBrandId}
                      helperText={errors?.productBrandId || basicAndStorageResponseErrors?.productBrandId}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Autocomplete
                  id="productTypeId"
                  classes={classes}
                  options={formattedProductType}
                  groupBy={option => option?.parent}
                  value={formattedProductType?.find(type => +type.value == +values.productTypeId) || {}}
                  onChange={(_, params) => setFieldValue("productTypeId", params?.value || '')}
                  getOptionLabel={option => option.label}
                  renderInput={params => (
                    <TextField
                      {...params}
                      variant="outlined"
                      color="primary"
                      label="Categoria do Produto"
                      error={errors?.productTypeId || !!basicAndStorageResponseErrors?.productTypeId}
                      helperText={errors?.productTypeId || basicAndStorageResponseErrors?.productTypeId}
                    />
                  )}
                  filterOptions={(options, { inputValue }) => {
                    const parents = options
                      ?.map(option => option?.parent?.toLowerCase())
                      ?.filter(option => !!option);

                    const parentList = [...new Set(parents)];

                    if(parentList?.includes(inputValue?.toLocaleLowerCase())) {
                      const availableOptions = options?.filter(option => option?.parent?.toLowerCase()?.includes(inputValue?.toLocaleLowerCase()));
                      return availableOptions || [];
                    }

                    return options?.filter(option => option?.label?.toLowerCase()?.includes(inputValue?.toLocaleLowerCase())) || [];
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Autocomplete
                  id="areasOfUse"
                  multiple
                  disableCloseOnSelect
                  limitTags={3}
                  classes={classes}
                  options={formattedAreasOfUse}
                  value={formattedAreasOfUse?.filter(area => formikProps?.values?.areasOfUse?.includes(area.value)) || []}
                  onChange={(_, areasOfUse) => setFieldValue("areasOfUse", areasOfUse?.map(area => area?.value))}
                  getOptionLabel={option => option.label}
                  renderInput={params => (
                    <TextField
                      {...params}
                      variant="outlined"
                      color="primary"
                      label="Aplicação do Produto"
                      error={errors?.areasOfUse || !!basicAndStorageResponseErrors?.areasOfUse}
                      helperText={errors?.areasOfUse || basicAndStorageResponseErrors?.areasOfUse}
                    />
                  )}
                  renderOption={(option, { selected }) => (
                    <li color="primary">
                      <Checkbox checked={selected} />
                      {option.label}
                    </li>
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Select
                  name="measurementUnitId"
                  label="Unidade de Medida"
                  options={formattedMeasurementUnits}
                  value={values.measurementUnitId}
                  onChange={handleChange}
                  error={errors?.measurementUnitId || !!basicAndStorageResponseErrors?.measurementUnitId}
                  helperText={errors?.measurementUnitId || basicAndStorageResponseErrors?.measurementUnitId}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <NumericInput
                  name="unitCost"
                  label="Valor do custo unitário"
                  value={values.unitCost}
                  onChange={value => setFieldValue("unitCost", value)}
                  error={errors?.unitCost}
                  helperText={errors?.unitCost}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <NumericInput
                  name="netContent"
                  type="text"
                  label="Quantidade de unidade de medida por item"
                  hasMaxValue
                  maxValue={999999.99}
                  value={values.netContent}
                  onChange={value => setFieldValue("netContent", value)}
                  error={errors?.netContent || !!basicAndStorageResponseErrors?.measurementUnitId}
                  helperText={errors?.netContent || basicAndStorageResponseErrors?.measurementUnitId}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <NumericInput
                  name="salePrice"
                  label="Valor de venda"
                  value={values.salePrice}
                  onChange={value => setFieldValue("salePrice", value)}
                  error={errors?.salePrice}
                  helperText={errors?.salePrice}
                />
              </Grid>
            </Grid>
          </Loader>
        </Grid>
      </Grid>
    </Card>
  );
}