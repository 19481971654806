import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';

import Button from '../Button';

import { growStyle } from './styles';

function Dropdown({ label, fullWidth, color, children }) {
  const [open, setOpen] = React.useState(false);

  const anchorRef = useRef(null);

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  }

  const handleClose = event => {
    if(anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  }

  return(
    <Grid container direction="column">
      <Grid item xs={12} ref={anchorRef}>
        <Button fullWidth={fullWidth} color={color} onClick={handleToggle}>
          {label} <ArrowDropDownIcon />
        </Button>
        <Popper
          transition
          disablePortal
          open={open}
          style={{ zIndex: 999 }}
          anchorEl={anchorRef.current}
        >
          {({ TransitionProps, placement }) => (
            <Grow {...TransitionProps} style={growStyle(placement)}>
              <ClickAwayListener onClickAway={handleClose}>
                <Paper>{children}</Paper>
              </ClickAwayListener>
            </Grow>
          )}
        </Popper>
      </Grid>
    </Grid>
  );
}

Dropdown.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
};

export default Dropdown;