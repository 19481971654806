import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { path } from 'ramda';
import moment from 'moment';

import { Grid, Typography, Card, CardHeader, CardContent, Collapse } from '@material-ui/core';
import Button from 'components/Button';
import Loader from 'components/Loader';
import MTable from 'components/MTable';
import PayrollSheet from 'components/PayrollSheet';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faPen } from '@fortawesome/free-solid-svg-icons';

import { clearPayrollSheet, setCommissionedUuid } from '../store/actions';
import { loadPayrollSheets, loadSinglePayrollSheet } from '../store/thunk';

import { convertToReal } from 'helpers/converters';

const headers = [
  { title: 'Data de vencimento', field: 'dueDate', sortable: false },
  { title: 'Data do pagamento', field: 'paymentDateTime', sortable: false },
  { title: 'Situação', field: 'situationId', sortable: false },
  { title: 'Valor', field: 'amount', sortable: false }
];

export default function ComissionedPayrollSheet() {
  const dispatch = useDispatch();

  const history = useHistory();
  const { name, uuid } = history?.location?.state?.data;

  const { userId } = useSelector(state => state.profile);
  const { establishmentId } = useSelector(state => state.businessInfo);
  const { list, isLoading } = useSelector(state => state.comissionedPayrollSheet);
  const { commissionSituations } = useSelector(state => state.comissionedList.filters);

  const [isPayrollSheetModalOpen, setIsPayrollSheetModalOpen] = useState(false);
  const [showInvoices, setShowInvoices] = useState([0]);

  useEffect(() => {
    dispatch(loadPayrollSheets(userId, establishmentId, uuid));
    dispatch(setCommissionedUuid(uuid));
  }, []);

  const handleInvoicesToggle = i => {
    if(showInvoices.includes(i)) {
      setShowInvoices(showInvoices.filter(id => id !== i));
    } else {
      setShowInvoices(showInvoices.concat(i));
    }

    return { showInvoices }
  }

  const openCurrentPayrollSheet = commissioned => {
    const { uuid } = commissioned;

    dispatch(clearPayrollSheet());
    dispatch(loadSinglePayrollSheet(userId, establishmentId, uuid));

    setIsPayrollSheetModalOpen(true);
  }

  return(
    <Grid
      container
      style={{ display: 'flex', flexDirection: 'column' }}
    >
      {isPayrollSheetModalOpen && (
        <PayrollSheet
          isPayrollSheetModalOpen={isPayrollSheetModalOpen}
          setIsPayrollSheetModalOpen={setIsPayrollSheetModalOpen}
        />
      )}
      <Loader isLoading={isLoading}>
        {(list?.length > 0) && (
          <>
            <Grid item xs={12}>
              <Typography paragraph variant='h5' color='primary'>
                Folhas de comissão do funcionário: {name}
              </Typography>
            </Grid>

            {list?.map((payroll, index) => {
              const currentYear = path([0, 'dueDate'], payroll)?.split('-')[0];
              const currentInvoice = showInvoices.includes(index);

              return(
                <Card>
                  <CardHeader
                    style={{ backgroundColor: '#1E5168' }}
                    title={
                      <Button
                        fullWidth
                        disableElevation
                        color='inherit'
                        style={{ textDecoration: 'none', color: 'white' }}
                        onClick={() => handleInvoicesToggle(index)}
                      >
                        <Typography variant='h6'>
                          {currentYear}
                        </Typography>
                        <FontAwesomeIcon
                          style={{ marginLeft: 10 }}
                          icon={faChevronDown}
                          color='white'
                        />
                      </Button>
                    }
                  />
                  <Collapse
                    in={currentInvoice}
                    key={index}
                  >
                    <CardContent>
                      <MTable
                        headers={headers}
                        data={payroll.map(payrollData => {
                          return {
                            ...payrollData,
                            dueDate: payrollData?.dueDate ? moment(payrollData?.dueDate).format('DD/MM/YYYY') : '-',
                            paymentDateTime: payrollData?.paymentDateTime ? moment(payrollData?.paymentDateTime).format('DD/MM/YYYY') : '-',
                            situationId: commissionSituations?.options?.find(situation => +situation?.id == +payrollData?.situationId)?.name,
                            amount: +payrollData?.situationId == 2 ? convertToReal(payrollData?.paidAmount) : convertToReal(payrollData?.amount)
                          }
                        })}
                        actions={[
                          {
                            icon: () => (
                              <FontAwesomeIcon
                                icon={faPen}
                                color='#2CA9D6'
                                size='xs'
                              />
                            ),
                            tooltip: 'Editar folha de comissão',
                            onClick: (_, rowData) => openCurrentPayrollSheet(rowData)
                          }
                        ]}
                      />
                    </CardContent>
                  </Collapse>
                </Card>
              );
            })}
          </>
        )}
        {(list?.length == 0) && (
          <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
            <Typography paragraph variant='h6' color='textSecondary'>
              Não há folhas de comissão encontradas para esse cliente
            </Typography>
          </Grid>
        )}
      </Loader>
    </Grid>
  );
}