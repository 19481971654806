import * as type from './constants';

export const setClients = (data, total) => ({
  type: type.SET_CLIENT,
  data,
  total,
});

export const handleLoading = status => ({
  type: type.HANDLE_LOADING,
  status,
});
