import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { toast } from 'react-toastify';
import moment from 'moment';

import Grid from '@material-ui/core/Grid';

import Modal from '../../../../components/Modal';
import Loader from '../../../../components/Loader';
import Datepicker from '../../../../components/Datepicker';
import Select from '../../../../components/Select';
import Input from '../../../../components/Input';
import ExportPDF from '../../../../components/ExportPDF';

import { onSelectChange, onTextChange } from '../../../../helpers/listeners';
import { textChange, handleBuildResume, clearBuildResumeFilters, changeDate } from '../../store/actions';
import { loadFinancialResume, loadUsageResume } from '../../store/thunk';
import { resumeTypes, months } from './sources';

import './styles.scss';

class BuildResumeModal extends Component {
  handleModal = () => {
    const { handleBuildResume, clearBuildResumeFilters } = this.props;

    handleBuildResume(false);
    clearBuildResumeFilters();
  }

  handleResumeCreation = () => {
    const {
      loadFinancialResume,
      loadUsageResume,
      resumeMonthId,
      resumeTypeId,
      type,
      startTime,
      endTime,
      year,
      userId,
      establishmentId
    } = this.props;

    let { startDate, endDate } = this.props;

    if (type == 'month') {
      startDate = moment(`${year}-${resumeMonthId}`, 'YYYY-MM')
        .startOf('month')
        .format('YYYY-MM-DD');

      endDate = moment(`${year}-${resumeMonthId}`, 'YYYY-MM')
        .endOf('month')
        .format('YYYY-MM-DD');
    }

    return +resumeTypeId == 1
      ? loadFinancialResume(userId, establishmentId, startDate, endDate, startTime, endTime)
      : loadUsageResume(userId, establishmentId, startDate, endDate, startTime, endTime);
  }

  render() {
    const {
      isOpen,
      isLoading,
      type,
      startDate,
      endDate,
      startTime,
      endTime,
      resumeTypeId,
      resumeMonthId,
      fieldChange,
      changeDate,
      year
    } = this.props;

    return (
      <Modal
        id="accounts"
        maxWidth="sm"
        scroll="body"
        title={type === 'day' ? 'Resumo diário' : 'Resumo mensal'}
        open={isOpen}
        onClose={this.handleModal}
        modalActions={
          <ExportPDF
            fileName={+resumeTypeId === 1 ? 'resumo_financeiro' : 'resumo_de_uso'}
            color="success"
            exportType={+resumeTypeId === 1 ? 'financialResume' : 'usageResume'}
            loading={isLoading}
            disabled={
              !resumeTypeId ||
              (type == 'month' && !resumeMonthId) ||
              (type == 'day' && (!moment(startDate).isValid() || !moment(endDate).isValid()))
            }
            requestData={this.handleResumeCreation}
            monthDate={
              type === 'month'
                ? {
                    startDate: moment(`${year}-${resumeMonthId}`)
                      .startOf('month')
                      .format('YYYY-MM-DD'),
                    endDate: moment(`${year}-${resumeMonthId}`)
                      .endOf('month')
                      .format('YYYY-MM-DD'),
                  }
                : null
            }
          >
            Gerar resumo
          </ExportPDF>
        }
      >
        <Loader isLoading={isLoading}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Select
                name="resumeTypeId"
                label="Escolha o tipo do resumo"
                options={resumeTypes}
                value={resumeTypeId}
                onChange={onSelectChange(fieldChange)}
              />
            </Grid>
          </Grid>
          {type === 'day' ? (
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} className="datapicker-range-container">
                <Datepicker
                  isMobile
                  startDate={startDate}
                  endDate={endDate}
                  changeDate={changeDate}
                  blockFutureDates
                  onBlockedDate={() => toast.error('A data selecionada não pode ser maior do que a data atual.')}
                />
              </Grid>
              <Grid item md={6} xs={12} style={{ paddingRight: 2.5 }}>
                <Input
                  id="startTime"
                  type="time"
                  label="Hora inicial"
                  placeholder="00:00"
                  value={startTime}
                  onChange={onTextChange(fieldChange)}
                />
              </Grid>
              <Grid item md={6} xs={12} style={{ paddingLeft: 2.5 }}>
                <Input
                  id="endTime"
                  type="time"
                  label="Hora final"
                  placeholder="00:00"
                  value={endTime}
                  onChange={onTextChange(fieldChange)}
                />
              </Grid>
            </Grid>
          ) : (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Select
                  name="resumeMonthId"
                  label="Escolha o mês"
                  options={months}
                  value={resumeMonthId}
                  onChange={onSelectChange(fieldChange)}
                />
              </Grid>
            </Grid>
          )}
        </Loader>
      </Modal>
    );
  }
}

BuildResumeModal.propTypes = {
  type: PropTypes.string.isRequired,
  handleBuildResume: PropTypes.func.isRequired,
  clearBuildResumeFilters: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  startTime: PropTypes.string.isRequired,
  endTime: PropTypes.string.isRequired,
  resumeTypeId: PropTypes.number.isRequired,
  resumeMonthId: PropTypes.number.isRequired,
  fieldChange: PropTypes.func.isRequired,
  changeDate: PropTypes.func.isRequired,
  loadFinancialResume: PropTypes.func.isRequired,
  loadUsageResume: PropTypes.func.isRequired,
  year: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
  ...state.cashFlow,
  page: state.pagination.activePage,
  pageSize: state.pagination.size,
  startDate: state.dateFilter.startDate,
  endDate: state.dateFilter.endDate,
  year: state.cashFlow.year
});

const mapDispatchToProps = {
  fieldChange: textChange,
  handleBuildResume,
  clearBuildResumeFilters,
  changeDate,
  loadFinancialResume,
  loadUsageResume
};

export default connect(mapStateToProps, mapDispatchToProps)(BuildResumeModal);