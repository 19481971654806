import * as types from './constants';

export const setProduct = data => ({
  type: types.SET_PRODUCT,
  data
});

export const setProductConsumeAndSellId = id => ({
  type: types.SET_PRODUCT_ID,
  id
});

export const setProductConsumeAndSellUuid = uuid => ({
  type: types.SET_PRODUCT_UUID,
  uuid
});

export const setSupplier = data => ({
  type: types.SET_SUPPLIER,
  data
});

export const setSuppliers = data => ({
  type: types.SET_SUPPLIERS,
  data
});

export const setSupplierErrors = errors => ({
  type: types.SET_SUPPLIER_ERRORS,
  errors
});

export const clearSupplierErrors = () => ({
  type: types.CLEAR_SUPPLIER_ERRORS
});

export const setSupplierUuid = uuid => ({
  type: types.SET_SUPPLIER_UUID,
  uuid
});

export const setProductBasic = data => ({
  type: types.SET_PRODUCT_BASIC,
  data
});

export const setProductStorage = data => ({
  type: types.SET_PRODUCT_STORAGE,
  data
});

export const setProductBasicAndStorageResponse = data => ({
  type: types.SET_PRODUCT_BASIC_AND_STORAGE_RESPONSE,
  data
});

export const setProductBasicAndStorageResponseErrors = data => ({
  type: types.SET_PRODUCT_BASIC_AND_STORAGE_RESPONSE_ERRORS,
  data
});

export const clearProductBasicAndStorageResponseErrors = () => ({
  type: types.CLEAR_PRODUCT_BASIC_AND_STORAGE_RESPONSE_ERRORS
});

export const setProductLoading = isLoading => ({
  type: types.SET_PRODUCT_LOADING,
  isLoading
});

export const setSupplierLoading = isLoading => ({
  type: types.SET_SUPPLIER_LOADING,
  isLoading
});

export const clearProductConsumeAndSell = () => ({
  type: types.CLEAR_PRODUCT
});

export const clearSupplier = () => ({
  type: types.CLEAR_SUPPLIER
});

export const setProductConsumption = data => ({
  type: types.SET_PRODUCT_CONSUMPTION,
  data
});

export const setProductConsumptionErrors = data => ({
  type: types.SET_PRODUCT_CONSUMPTION_RESPONSE_ERRORS,
  data
});

export const clearProductConsumptionErrors = () => ({
  type: types.CLEAR_PRODUCT_CONSUMPTION_RESPONSE_ERRORS
});

export const setMeasurementUnitGroupId = id => ({
  type: types.SET_MEASUREMENT_UNIT_GROUP_ID,
  id
});