import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';

import { Grid, Typography } from '@material-ui/core';

import { formatFilters } from 'helpers/formatters';
import { formatPermanence } from 'helpers/formatters';
import { convertFormattedMoneyToNumber, convertToReal } from 'helpers/converters';

import { Row, Column, getUserNameById, getContractNameById, getPaymentMethodNameById } from '../../Resources';
import ActivitiesRegistrationHeader from '../../ActivitiesRegistrationHeader';

import { loadFilters } from 'components/Filter/store/thunk';

export default function ServiceOrderUpdate({ serviceOrder, lastRegister, activityData }) {
  const dispatch = useDispatch();

  const { userId } = useSelector(state => state.profile);
  const { establishmentId } = useSelector(state => state.businessInfo);

  const { selects } = useSelector(state => state.filters);

  const userList = formatFilters(['user'], selects)[0]?.options;
  const contractNameList = formatFilters(['contract'], selects)[0]?.options;
  const paymentMethods = formatFilters(['paymentMethod'], selects)[0]?.options;

  useEffect(() => {
    dispatch(loadFilters(userId, establishmentId));
  }, []);

  return(
    <Grid container>
      <Grid xs={12}>
        <ActivitiesRegistrationHeader activityData={activityData} />
        <Grid container style={{ marginTop: 10 }}>
          <Grid xs={12} md={6} style={{ padding: 5 }}>
            <Typography style={{ color: '#1e5168', marginBottom: 5 }}>OS Anterior</Typography>
            <Grid style={{ border: '1px solid #1e5168', borderRadius: 8, padding: 8 }}>
              <Row>
                <Typography>Código da OS</Typography>
                <Typography>{lastRegister?.serviceOrder?.serviceOrderCode}</Typography>
              </Row>
              <Row>
                <Typography>Cartão</Typography>
                <Typography>{lastRegister?.serviceOrder?.cardCode}</Typography>
              </Row>
              <Row>
                <Typography>Operador da entrada</Typography>
                <Typography>{getUserNameById(userList, lastRegister?.serviceOrder?.userId)}</Typography>
              </Row>
              <Row>
                <Typography>Operador da saída</Typography>
                <Typography>{getUserNameById(userList, lastRegister?.serviceOrder?.userOutputId)}</Typography>
              </Row>
              <Row>
                <Typography>Entrada</Typography>
                <Typography>{moment(lastRegister?.serviceOrder?.entryDateTime).format('DD/MM/YYYY HH:mm:ss')}</Typography>
              </Row>
              <Row>
                <Typography>Saída</Typography>
                <Typography>{moment(lastRegister?.serviceOrder?.exitDateTime).format('DD/MM/YYYY HH:mm:ss')}</Typography>
              </Row>
              <Row>
                <Typography>Placa</Typography>
                <Typography>{lastRegister?.serviceOrder?.plate}</Typography>
              </Row>
              <Row>
                <Typography>Veículo</Typography>
                <Typography>{lastRegister?.serviceOrder?.vehicle?.vehicleModel} {lastRegister?.serviceOrder?.vehicle?.vehicleColor}</Typography>
              </Row>
              <Row>
                <Typography>Cobrança</Typography>
                <Typography>{lastRegister?.serviceOrder?.typePrice}</Typography>
              </Row>
              <Row>
                <Typography>Tipo de contrato</Typography>
                <Typography>{getContractNameById(contractNameList, lastRegister?.serviceOrder?.contractId)}</Typography>
              </Row>
              <Row>
                <Typography>Meio de pagamento</Typography>
                <Typography>{getPaymentMethodNameById(paymentMethods, lastRegister?.transaction?.paymentMethodId)}</Typography>
              </Row>
              <Row>
                <Typography>Período</Typography>
                <Typography>{formatPermanence([lastRegister?.serviceOrder?.exitDateTime, lastRegister?.serviceOrder?.entryDateTime])}</Typography>
              </Row>
              <Row>
                <Typography>Valor</Typography>
                <Typography>{convertToReal(parseFloat(lastRegister?.serviceOrder?.amount) + parseFloat(lastRegister?.serviceOrder?.amountServices))}</Typography>
              </Row>
              {Array.isArray(lastRegister?.serviceOrder?.services) && lastRegister?.serviceOrder?.services?.length > 0 && (
                <Column>
                  <Typography style={{ marginBottom: 5 }}>Serviços</Typography>
                  {lastRegister?.serviceOrder?.services?.map(service => (
                    <Grid xs={12} style={{ display: 'flex', flexDirection: 'row' }}>
                      <Typography>{service?.quantity}</Typography>
                      <Typography style={{ margin: '0px 20px 0px 20px' }}>{service?.description}</Typography>
                      <Typography>{convertToReal(convertFormattedMoneyToNumber(service?.amount))}</Typography>
                    </Grid>
                  ))}
                </Column>
              )}
            </Grid>
          </Grid>
          <Grid xs={12} md={6} style={{ padding: 5, marginBottom: 5 }}>
            <Typography style={{ color: '#1e5168', marginBottom: 5 }}>OS Atual</Typography>
            <Grid style={{ border: '1px solid #1e5168', borderRadius: 8, padding: 8 }}>
              <Row>
                <Typography>Código da OS</Typography>
                <Typography>{serviceOrder?.serviceOrderCode}</Typography>
              </Row>
              <Row>
                <Typography>Cartão</Typography>
                <Typography>{serviceOrder?.cardCode}</Typography>
              </Row>
              <Row>
                <Typography>Operador da entrada</Typography>
                <Typography>{serviceOrder?.entryUserName}</Typography>
              </Row>
              <Row>
                <Typography>Operador da saída</Typography>
                <Typography>{serviceOrder?.exitUserName}</Typography>
              </Row>
              <Row>
                <Typography>Entrada</Typography>
                <Typography>{serviceOrder?.entryDateTime}</Typography>
              </Row>
              <Row>
                <Typography>Saída</Typography>
                <Typography>{serviceOrder?.exitDateTime}</Typography>
              </Row>
              <Row>
                <Typography>Placa</Typography>
                <Typography>{serviceOrder?.plate}</Typography>
              </Row>
              <Row>
                <Typography>Veículo</Typography>
                <Typography>{serviceOrder?.vehicleModel} {serviceOrder?.vehicleColor}</Typography>
              </Row>
              <Row>
                <Typography>Cobrança</Typography>
                <Typography>{serviceOrder?.typePrice}</Typography>
              </Row>
              <Row>
                <Typography>Tipo de contrato</Typography>
                <Typography>{getContractNameById(contractNameList, serviceOrder?.contractId)}</Typography>
              </Row>
              <Row>
                <Typography>Meio de pagamento</Typography>
                <Typography>{serviceOrder?.paymentMethodName || '--'}</Typography>
              </Row>
              <Row>
                <Typography>Período</Typography>
                <Typography>{serviceOrder?.permanence}</Typography>
              </Row>
              <Row>
                <Typography>Valor</Typography>
                <Typography>{serviceOrder?.amountTotal}</Typography>
              </Row>
              {Array.isArray(serviceOrder?.services) && serviceOrder?.services?.length > 0 && (
                <Column>
                  <Typography style={{ marginBottom: 5 }}>Serviços</Typography>
                  {serviceOrder?.services?.map(service => (
                    <Grid xs={12} style={{ display: 'flex', flexDirection: 'row' }}>
                      <Typography>{service?.quantity}</Typography>
                      <Typography style={{ margin: '0px 20px 0px 20px' }}>{service?.description}</Typography>
                      <Typography>{convertToReal(convertFormattedMoneyToNumber(service?.amount))}</Typography>
                    </Grid>
                  ))}
                </Column>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

ServiceOrderUpdate.propTypes = {
  serviceOrder: PropTypes.object.isRequired,
  lastRegister: PropTypes.object.isRequired,
  activityData: PropTypes.object.isRequired
};