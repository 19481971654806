import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { path } from 'ramda';
import { Link } from 'react-router-dom';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Collapse from '@material-ui/core/Collapse';
import Typography from '@material-ui/core/Typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faChevronDown } from '@fortawesome/free-solid-svg-icons';

import { loadClientInvoices } from '../store/thunk';
import { setClientId } from '../../ClientRegistration/components/steps/BasicInfo/store/actions';
import { setInvoiceId, handleUpdateInvoice } from '../store/actions';
import { loadFilters } from '../../../components/Filter/store/thunk';

import MTable from '../../../components/MTable';
import Button from '../../../components/Button';

import Loader from '../../../components/Loader';
import Invoice from '../../../components/Invoice';
import InvoicePos from '../../../components/InvoicePos';
import InvoiceServiceContract from '../../../components/InvoiceServiceContract';

import { convertToBrl } from 'helpers/converters';

import { useStyles } from './styles';

const headers = [
  { title: 'Data do vencimento', field: 'dueDateTime', sortable: false },
  { title: 'Data do recebimento', field: 'paymentDateTime', sortable: false },
  { title: 'Situação', field: 'invoiceSituation', sortable: false },
  { title: 'Valor', field: 'amountTotal', sortable: false }
];

const invoicePosHeaders = [
  { title: 'Data do vencimento', field: 'dueDateTime', sortable: false },
  { title: 'Data do fechamento', field: 'closingDateTime', sortable: false },
  { title: 'Data do recebimento', field: 'paymentDateTime', sortable: false },
  { title: 'Situação', field: 'invoiceSituation', sortable: false },
  { title: 'Valor', field: 'amountTotal', sortable: false }
];

const width = window.innerWidth;
const tableEditActionWidth = width < 768 ? '100' : '80';

export default function ClientInvoices() {
  const dispatch = useDispatch();

  const classes = useStyles();

  const [invoiceServiceContractInvoiceDateTime, setInvoiceServiceContractInvoiceDateTime] = useState(false);
  const [isOpenInvoiceServiceContract, setIsOpenInvoiceServiceContract] = useState(false);
  const [isOpenInvoicePos, setIsOpenInvoicePos] = useState(false);
  const [showInvoices, setShowInvoices] = useState([0]);

  const { userId } = useSelector(state => state.profile);
  const { establishmentId } = useSelector(state => state.businessInfo);
  const clientId = useSelector(state => state.clientRegistration.basicInfo.clientId);
  const { list, isLoading, invoiceClientName, clientTypeId } = useSelector(state => state.clientInvoices);
  const isUpdatingInvoice = useSelector(state => state.clientInvoices.isUpdatingInvoice);

  const handleGoToEdit = row => {
    const { clientId, invoiceDateTime, serviceContractId } = row;

    dispatch(setClientId(clientId));
    dispatch(setInvoiceId(invoiceDateTime));

    if(clientTypeId === 4 || clientTypeId === 5) {
      setIsOpenInvoicePos(true);
      return;
    }

    if(serviceContractId) {
      setInvoiceServiceContractInvoiceDateTime(invoiceDateTime);
      setIsOpenInvoiceServiceContract(true);
      return;
    }

    dispatch(handleUpdateInvoice(true));
  }

  const handleInvoicesToggle = i => {
    if (showInvoices.includes(i)) {
      setShowInvoices(showInvoices.filter(id => id !== i));
    } else {
      setShowInvoices(showInvoices.concat(i));
    }

    return { showInvoices };
  }

  const handleInvoiceSituation = (opened, situation) => {
    const isInvoiceOpened = opened == 1;
    const isInvoicePaid = situation == 'Paga';

    return !isInvoiceOpened && !isInvoicePaid
      ? `Fechada/${situation}`
      : situation;
  }

  const convertFormattedMoneyToNumber = formattedAmount => {
    return +formattedAmount?.replace(/[R$ ]/g, '')?.replace(/[,]/g, '.')?.replace(/[.](?=.*[.])/g, '') || 0;
  }

  useEffect(() => {
    dispatch(loadFilters(userId, establishmentId));
    dispatch(handleUpdateInvoice(false));
    dispatch(loadClientInvoices(userId, establishmentId, clientId));
  }, []);

  return (
    <div className="py-4">
      <Loader spinner isLoading={isLoading}>
        {list.length > 0 ? (
          <>
            <Typography paragraph variant="h5" color="primary">
              Faturas do cliente: {invoiceClientName}
            </Typography>
            {list.map((year, i) => {
              const currentYear = path([0, 'dueDateTime'], year).split('/')[2];
              const currentInvoice = showInvoices.includes(i);

              const invoiceData = year?.map(invoice => {
                return {
                  ...invoice,
                  invoiceSituation: clientTypeId === 4 || clientTypeId === 5
                    ? handleInvoiceSituation(invoice.opened, invoice.invoiceSituation)
                    : invoice.invoiceSituation
                }
              });

              return (
                <Card>
                  <CardHeader
                    style={{ backgroundColor: '#1E5168' }}
                    title={
                      <Button
                        fullWidth
                        disableElevation
                        color="inherit"
                        style={{ textDecoration: 'none', color: 'white' }}
                        onClick={() => handleInvoicesToggle(i)}
                      >
                        <span
                          className={classes.root}
                          style={{ fontSize: '1.3rem', marginRight: '8px' }}
                        >
                          {currentYear}
                        </span>
                        <FontAwesomeIcon icon={faChevronDown} color="white" />
                      </Button>
                    }
                  />
                  <Collapse in={currentInvoice} key={i}>
                    <CardContent>
                      <MTable
                        loading={isLoading}
                        headers={(+clientTypeId == 4 || +clientTypeId == 5) ? invoicePosHeaders : headers}
                        data={invoiceData.map(invoice => {
                          return {
                            ...invoice,
                            amountTotal: invoice?.invoiceSituation == 'Paga'
                              ? invoice?.receivedAmount
                              : convertToBrl(convertFormattedMoneyToNumber(invoice?.amount) + convertFormattedMoneyToNumber(invoice?.closingAmount))
                          }
                        })}
                        actions={[
                          {
                            icon: () => (
                              <FontAwesomeIcon
                                icon={faPen}
                                color="#2CA9D6"
                                size="xs"
                              />
                            ),
                            tooltip: 'Editar fatura',
                            onClick: (event, rowData) => handleGoToEdit(rowData)
                          }
                        ]}
                        actionColumnWidth={tableEditActionWidth}
                      />
                    </CardContent>
                  </Collapse>
                </Card>
              );
            })}
          </>
        ) : (
          <Card>
            <CardHeader
              style={{ backgroundColor: '#1E5168' }}
              title={
                <Typography gutterBottom variant="h6" className={classes.root}>
                  Faturas do cliente
                </Typography>
              }
            />
            <CardContent className="text-center">
              <h5 className="text-secondary">
                Não há faturas criadas para esse cliente
              </h5>
              <p className="text-secondary">
                Configure a fatura desse cliente{' '}
                <Link to="/cadastro-de-cliente/cobrancas/">clicando aqui.</Link>
              </p>
            </CardContent>
          </Card>
        )}
      </Loader>
      {isUpdatingInvoice && (
        <Invoice
          isOpen={isUpdatingInvoice}
          establishmentId={establishmentId}
        />
      )}
      {isOpenInvoicePos &&
        <InvoicePos
          isOpen={isOpenInvoicePos}
          setIsOpenInvoicePos={setIsOpenInvoicePos}
          establishmentId={establishmentId}
        />
      }
      {isOpenInvoiceServiceContract &&
        <InvoiceServiceContract
          isOpenInvoiceServiceContract={isOpenInvoiceServiceContract}
          setIsOpenInvoiceServiceContract={setIsOpenInvoiceServiceContract}
          calculatedInvoiceDateTime={invoiceServiceContractInvoiceDateTime}
          isInvoicePaid={true}
        />
      }
    </div>
  );
}

ClientInvoices.defaultProps = {
  invoiceClientName: ''
}