import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ palette, spacing }) => ({
  container: {
    backgroundColor: 'white',
    paddingBottom: spacing(2),
  },
  paperSuccess: {
    textAlign: 'center',
    padding: spacing(4),
    paddingTop: spacing(2),
    color: palette.success.main,
    borderColor: palette.success.main,
    borderTop: 'solid 5px',
  },
  paperError: {
    textAlign: 'center',
    padding: spacing(4),
    paddingTop: spacing(2),
    color: palette.error.main,
    borderColor: palette.error.main,
    borderTop: 'solid 5px',
  },
  paperPrimary: {
    textAlign: 'center',
    padding: spacing(4),
    paddingTop: spacing(2),
    color: palette.primary.main,
    borderColor: palette.primary.main,
    borderTop: 'solid 5px',
  },
}));
