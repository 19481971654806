import React from 'react';
import moment from 'moment';

import { Grid, Typography } from '@material-ui/core';
import { Row } from '../../Resources';
import ActivitiesRegistrationHeader from '../../ActivitiesRegistrationHeader';

import { convertToReal } from 'helpers/converters';

export default function SellOrderRemotion({ lastRegister, activityData }) {
  return(
    <Grid container>
      <Grid xs={12}>
        <ActivitiesRegistrationHeader activityData={activityData} />
      </Grid>
      <Grid container style={{ marginTop: 10 }}>
        <Grid xs={12} style={{ padding: 5 }}>
          <Grid style={{ border: '1px solid #1e5168', borderRadius: 8, padding: 8 }}>
            <Row>
              <Typography>N° Pedido</Typography>
              <Typography>{lastRegister?.orderCode}</Typography>
            </Row>
            <Row>
              <Typography>Registro</Typography>
              <Typography>{moment(lastRegister?.registrationDateTime).format('DD/MM/YYYY HH:mm:ss')}</Typography>
            </Row>
            <Row>
              <Typography>Valor</Typography>
              <Typography>{convertToReal(lastRegister?.totalAmount)}</Typography>
            </Row>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}