import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import moment from 'moment';
import { map, range } from 'ramda';
import { difference } from 'lodash';

import { Chart, ArgumentAxis, ValueAxis, SplineSeries, Tooltip } from '@devexpress/dx-react-chart-material-ui';
import { ValueScale, EventTracker } from '@devexpress/dx-react-chart';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';

import FinalOnboarding from '../../../components/Onboarding/FinalOnboarding';

import { loadTotalOccupation, loadPaidOut, loadCanceled, loadAltered, loadCashflow, loadGraphics } from '../store/thunk';
import { loadFilters } from '../../../components/Filter/store/thunk';

import {
  formatCurrency,
  convertGraphicData,
  convertTypeToTimeRange,
  convertToPercentage,
  convertTypeToNumberOfDays,
  convertNumberToTime
} from '../../../helpers/converters';

import { formatDaysBetweenDates } from '../../../helpers/formatters';
import useWindowDimensions from '../../../helpers/hooks/useWindowDimensions';

import Loader from '../../../components/Loader';

import { useStyles } from './styles';

export default function Dashboard() {
  const dispatch = useDispatch();

  const classes = useStyles();
  const { width } = useWindowDimensions();
  const isMd = width < 1280;

  const { userId } = useSelector(state => state.profile);
  const { graphics, cashFlow, serviceOrders, occupation, isLoading } = useSelector(state => state.dashboard);
  const { establishmentId } = useSelector(state => state.businessInfo);
  const type = useSelector(state => state.filters.graphicTimeRange);
  const graphicTimeRange = useSelector(state => state.dateFilter);
  const { isFromRegistration, isFinalOnboardingOpen } = useSelector(state => state.onboarding);

  const { paidOut, canceled, altered } = serviceOrders;

  const [isTotalOccupationLoading, setIsTotalOccupationLoading] = useState(false);
  const [isPaidOutLoading, setIsPaidOutLoading] = useState(false);
  const [isCanceledLoading, setIsCanceledLoading] = useState(false);
  const [isAlteredLoading, setIsAlteredLoading] = useState(false);
  const [isGraphicsLoading, setIsGraphicsLoading] = useState(false);
  const [isCashflowLoading, setIsCashflowLoading] = useState(false);

  const startDate = moment().subtract(convertTypeToNumberOfDays(type), 'days');
  const endDate = moment().add(1, 'days');
  const setDayHours = map(convertNumberToTime, range(0, 24));

  const [customGraphicDatesArray, setCustomGraphicDatesArray] = useState([]);

  const graphicTimeRangeStartDate = moment(graphicTimeRange?.startDate).format('DD/MM/YYYY');
  const graphicTimeRangeEndDate = moment(graphicTimeRange?.endDate).format('DD/MM/YYYY');
  const isCustomTimeRangeSingleDay = moment(graphicTimeRangeEndDate, 'DD/MM/YYYY').diff(moment(graphicTimeRangeStartDate, 'DD/MM/YYYY'), 'days') == 0;

  const setDateTimeRanges =
    type == 'day' ||
    type == 'yesterday' ||
    (type == 'custom' && isCustomTimeRangeSingleDay)
      ? setDayHours
      : formatDaysBetweenDates(startDate, endDate);

  const { balance, entry, payments } = graphics;

  const setGraphicData = (i, cashFlowType) => {
    const graphicType = type == 'custom' && isCustomTimeRangeSingleDay
      ? 'day'
      : type;

    return convertGraphicData(cashFlowType, graphicType, setDateTimeRanges);
  }

  const mainChart = setDateTimeRanges.map((range, i) => {
    return {
      day: range,
      entry: setGraphicData(0, entry)[i],
      payments: setGraphicData(1, payments)[i],
      balance: setGraphicData(2, balance)[i]
    }
  });

  const formatCustomGraphicData = (entry, payments, balance) => {
    const customGraphicDates = balance.map(range => {
      return moment(range.days).format('DD/MM/YYYY');
    });

    const paymentsOffsetDays =
      difference(
        customGraphicDates,
        payments?.map(payment => moment(payment?.days).format('DD/MM/YYYY'))
      )
      .map(days => {
        return {
          days: moment(days, 'DD/MM/YYYY').format('YYYYMMDD'),
          amount: 0
        }
      });

    const paymentsToBalanceDays = [...paymentsOffsetDays, ...payments]
      ?.sort((a, b) => moment(a.days) - moment(b.days));

    const formattedCustomGraphicDates = customGraphicDates?.map((date, i) => {
      return {
        day: date,
        entry: parseFloat(entry[i]?.amount) || 0,
        balance: parseFloat(balance[i]?.amount) || 0,
        payments: moment(paymentsToBalanceDays[i]?.days).format('DD/MM/YYYY') == date
          ? parseFloat(paymentsToBalanceDays[i]?.amount)
          : 0
      }
    });

    let toDateGraphic = [];

    const startDate = moment(graphicTimeRange?.startDate).format('DD/MM/YYYY');
    const endDate = moment(graphicTimeRange?.endDate).format('DD/MM/YYYY');
    const lastGraphicDay = formattedCustomGraphicDates[formattedCustomGraphicDates.length - 1]?.day;

    if(lastGraphicDay != endDate) {
      let daysDifferenceToDate = moment(endDate, 'DD/MM/YYYY').diff(moment(lastGraphicDay, 'DD/MM/YYYY'), 'days') || 0;

      if(+daysDifferenceToDate == 0) {
        daysDifferenceToDate = moment(endDate, 'DD/MM/YYYY').diff(moment(startDate, 'DD/MM/YYYY'), 'days') + 1;
      }

      if(+daysDifferenceToDate < 0) {
        daysDifferenceToDate = 0;
      }

      toDateGraphic = Array(daysDifferenceToDate)
        .fill({})
        .map((_, index) => {
          return {
            day: moment(endDate, 'DD/MM/YYYY').subtract((daysDifferenceToDate - (index + 1)), 'days').format('DD/MM/YYYY'),
            entry: 0,
            payments: 0,
            balance: 0
          }
        });
    }

    const graphics = [...formattedCustomGraphicDates, ...toDateGraphic]
      .map(graphic => {
        return {
          ...graphic,
          day: moment(graphic?.day, 'DD/MM/YYYY').format('DD/MM')
        }
      });

    setCustomGraphicDatesArray(graphics);
  }

  useEffect(() => {
    if(type == 'custom' && !isCustomTimeRangeSingleDay) {
      formatCustomGraphicData(entry, payments, balance);
    }
  }, [type, graphics]);

  useEffect(() => {
    dispatch(loadFilters(userId, establishmentId));
  }, []);

  useEffect(() => {
    if(type) {
      const { endDate, startDate } = graphicTimeRange;

      const params = {
        userId,
        establishmentId,
        type,
        startDate,
        endDate
      }

      setIsTotalOccupationLoading(true);
      setIsPaidOutLoading(true);
      setIsCanceledLoading(true);
      setIsAlteredLoading(true);
      setIsCashflowLoading(true);
      setIsGraphicsLoading(true);

      dispatch(loadTotalOccupation(params)).then(() => {
        setIsTotalOccupationLoading(false);

        dispatch(loadPaidOut(params)).then(() => {
          setIsPaidOutLoading(false);

          dispatch(loadCanceled(params)).then(() => {
            setIsCanceledLoading(false);

            dispatch(loadAltered(params)).then(() => {
              setIsAlteredLoading(false);

              dispatch(loadCashflow(params)).then(() => {
                setIsCashflowLoading(false);

                dispatch(loadGraphics(params)).then(() => {
                  setIsGraphicsLoading(false);
                });
              });
            });
          });
        });
      });
    }
  }, [type, graphicTimeRange, establishmentId, userId]);

  return(
    <Loader isLoading={isLoading}>
      {(isFromRegistration && isFinalOnboardingOpen) && <FinalOnboarding />}
      <Grid container spacing={2} className={classes.grid}>
        <Grid item xs={12} sm={6} lg={3}>
          <Paper className={classes.container}>
            <Loader isLoading={isTotalOccupationLoading}>
              <Typography variant="subtitle1" color="primary" align="center">
                Ocupação total
              </Typography>
              <Typography variant="h4" color="primary" align="center">
                {occupation?.total}
              </Typography>
            </Loader>
          </Paper>
          <Typography variant="body2" className={classes.footer}>
            {!isTotalOccupationLoading && (
              <>Total: {occupation?.vacancies}</>
            )}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <Paper className={classes.container}>
            <Loader isLoading={isPaidOutLoading}>
              <Typography variant="subtitle1" color="primary" align="center">
                Ordem de serviço pagas
              </Typography>
              <Typography variant="h4" color="primary" align="center">
                {paidOut?.total}
              </Typography>
            </Loader>
          </Paper>
          <Typography variant="body2" className={classes.footer}>
            {!isPaidOutLoading && (
              <>Total: {formatCurrency(paidOut?.totalAmount || 0)}</>
            )}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <Paper className={classes.container}>
            <Loader isLoading={isCanceledLoading}>
              <Typography variant="subtitle1" color="primary" align="center">
                Ordem de serviço canceladas
              </Typography>
              <Typography variant="h4" color="primary" align="center">
                {canceled?.total}
              </Typography>
            </Loader>
          </Paper>
          <Typography variant="body2" className={classes.footer}>
            {!isCanceledLoading && (
              <>Total: {formatCurrency(canceled?.totalAmount || 0)}</>
            )}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <Paper className={classes.container}>
            <Loader isLoading={isAlteredLoading}>
              <Typography variant="subtitle1" color="primary" align="center">
                Ordem de serviço alteradas
              </Typography>
              <Typography variant="h4" color="primary" align="center">
                {altered?.total}
              </Typography>
            </Loader>
          </Paper>
          <Typography variant="body2" className={classes.footer}>
            {!isAlteredLoading && (
              <>{convertToPercentage(altered?.total, paidOut?.total)} do total de OSs</>
            )}
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <Paper className={isMd ? classes.cashFlowContainer : classes.cashFlowMdContainer}>
            <Grid className={isCashflowLoading ? classes.flexCenter : null}>
              <Loader isLoading={isCashflowLoading}>
                <Grid container spacing={2} style={{ padding: '5px 0px 20px 0px' }}>
                  <Grid item lg={4} xs={12} align="center">
                    <Typography variant="subtitle2" color="textSecondary">
                      Receita
                    </Typography>
                    <Typography variant="button" color="primary">
                      {formatCurrency(cashFlow?.entry || 0)}
                    </Typography>
                    <LinearProgress
                      variant="determinate"
                      color="primary"
                      value={100}
                      className={classes.progress}
                    />
                  </Grid>
                  <Grid item lg={4} xs={12} align="center">
                    <Typography variant="subtitle2" color="textSecondary">
                      Despesa
                    </Typography>
                    <Typography variant="button" color="primary">
                      {formatCurrency(cashFlow?.exit || 0)}
                    </Typography>
                    <LinearProgress
                      variant="determinate"
                      color="error"
                      value={100}
                      className={[classes.progress, classes.error]}
                    />
                  </Grid>
                  <Grid item lg={4} xs={12} align="center">
                    <Typography variant="subtitle2" color="textSecondary">
                      Lucro
                    </Typography>
                    <Typography variant="button" color="primary">
                      {formatCurrency(cashFlow?.balance || 0)}
                    </Typography>
                    <LinearProgress
                      variant="determinate"
                      color="success"
                      value={100}
                      className={[classes.progress, classes.success]}
                    />
                  </Grid>
                </Grid>
              </Loader>
            </Grid>
            <Grid className={isGraphicsLoading ? classes.flexCenter : null}>
              <Loader isLoading={isGraphicsLoading}>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography variant="body1" color="primary">
                      Fluxo de caixa
                    </Typography>
                    <Typography variant="subtitle2" color="textSecondary">
                      {convertTypeToTimeRange(type)}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Chart
                    height={300}
                    style={{ paddingBottom: '20px' }}
                    data={(type == 'custom' && !isCustomTimeRangeSingleDay) ? customGraphicDatesArray : mainChart}
                  >
                    <ValueScale name="balance" />
                    <ValueScale name="day" />
                    <ArgumentAxis showLabels={width > 768} />
                    <ValueAxis
                      scaleName="day"
                      position="right"
                      showGrid={false}
                      showLine
                      showTicks
                    />
                    <SplineSeries
                      scaleName="day"
                      name="Receitas"
                      valueField="entry"
                      argumentField="day"
                      color="#1E5168"
                    />
                    <SplineSeries
                      scaleName="day"
                      name="Despesas"
                      valueField="payments"
                      argumentField="day"
                      color={red[600]}
                    />
                    <SplineSeries
                      scaleName="day"
                      name="Lucro"
                      valueField="balance"
                      argumentField="day"
                      color={green[600]}
                    />
                    <EventTracker />
                    <Tooltip
                      contentComponent={tooltip => (
                        <>
                          <Typography
                            variant="body1"
                            color="primary"
                            className={classes.bold}
                          >
                            {tooltip.targetItem.series}
                          </Typography>
                          <Typography variant="body2" color="primary">
                            {formatCurrency(tooltip.text || 0)}
                          </Typography>
                        </>
                      )}
                    />
                  </Chart>
                </Grid>
              </Loader>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Loader>
  );
}