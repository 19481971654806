import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Loader from '../../../../../../components/Loader';
import Select from '../../../../../../components/Select';

import { loadAutomations } from '../store/thunk';

import { convertToOptions } from '../../../../../../helpers/converters';

import { useStyles } from './styles';

export default function Automations({ formikInjectProps }) {
  const dispatch = useDispatch();

  const classes = useStyles();

  const filters = useSelector(state => state.filters.selects);

  const availableTypePrices = convertToOptions(['typePrice'], filters);

  const availableServices = convertToOptions(['services'], filters)?.map(service => {
    return service?.serviceId ? {
      label: service?.description,
      value: service?.serviceId
    } : {
      label: '--',
      value: ''
    }
  });

  const userId = useSelector(state => state.profile.userId);
  const establishmentId = useSelector(state => state.businessInfo.establishmentId);
  const { clientId } = useSelector(state => state.clientRegistration.basicInfo);
  const { isLoading } = useSelector(state => state.clientRegistration.automations);
  const clientTypeId = useSelector(state => state.clientSelection.clientTypeId);

  useEffect(() => {
    (async() => {
      const params = {
        userId,
        establishmentId,
        clientId
      }

      const { typePrices, services } = await dispatch(loadAutomations(params));

      formikInjectProps.setFieldValue('typePrices', typePrices);
      formikInjectProps.setFieldValue('services', services);
    })();
  }, []);

  return (
    <Card>
      <CardHeader
        style={{ backgroundColor: '#1E5168', padding: '8px' }}
        title={
          <Typography gutterBottom variant="h6" className={classes.root}>
            Automatizações de cobrança
          </Typography>
        }
      />
      <Loader isLoading={isLoading}>
        <CardContent>
          <Grid container spacing={3}>
            {clientTypeId !== 5 &&
              <Grid item md={6} xs={12}>
                <Typography variant="body1">
                  Vincular tabelas de estacionamento:
                </Typography>
                <Select
                  multiple
                  name="typePrices"
                  label="Selecione a(s) tabela(s)"
                  options={availableTypePrices}
                  value={formikInjectProps.values.typePrices}
                  onChange={e => formikInjectProps.handleChange(e)}
                />
              </Grid>
            }
            <Grid item md={6} xs={12}>
              <Typography variant="body1">Vincular serviços:</Typography>
              <Select
                multiple
                name="services"
                label="Selecione o(s) serviço(s)"
                options={availableServices}
                value={formikInjectProps.values.services}
                onChange={e => formikInjectProps.handleChange(e)}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Loader>
    </Card>
  );
}

Automations.defaultProps = {
  formikInjectProps: {
    handleChange: () => { },
    setFieldValue: () => { },
    values: {
      name: '',
      email: ''
    },
    errors: {
      name: false,
      email: false
    }
  }
};

Automations.propTypes = {
  formikInjectProps: PropTypes.shape()
};