import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import VMasker from 'vanilla-masker';

import { Formik, Form } from 'formik';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import Button from '../../../components/Button';

import BasicInfo from './steps/BasicInfo';
import ConfigRPS from './steps/ConfigRps';
import ProvisionalReceiptInfo from './steps/ProvisionalReceiptInfo';
import ProvisionalReceiptFilter from './steps/ProvisionalReceiptFilter';

import TermsOfUseModal from './TermsOfUseModal';

import { setProvisionalReceiptInfo, registerFieldChange } from '../store/actions';
import { loadProvisionalReceiptInfo, createRps, upsertRPS, updateCertificate, upsertConfigRps, updateFilters } from '../store/thunk';

import { basicInfoValidations } from './steps/BasicInfo/validations';
import { configRpsValidations } from './steps/ConfigRps/validations';
import { authenticationValidations } from './steps/ProvisionalReceiptInfo/validations';

import { useStyles } from './styles';

export default function ProvisionalReceiptConfig() {
  const dispatch = useDispatch();

  const classes = useStyles();

  const history = useHistory();

  const { userId } = useSelector(state => state.profile);
  const { establishmentId } = useSelector(state => state.businessInfo);
  const businessLoading = useSelector(state => state.businessInfo.isLoading);
  const { profileId } = useSelector(state => state.profile.submit);
  const { termsOfUseDate, isTermsOfUseChecked } = useSelector(state => state.login);

  const {
    isLoading,
    isCityLoaded,
    certificateFileFromAPI,
    configType,
    isRpsConfig,
    custom_settings,
    logoFile,
    basicInfo,
    rpsConfig,
    rpsFilter,
    certificate,
    certificateFile,
    certificate: certificateData,
    login: loginData,
    token: tokenData,
    authentication: {
      certificate: certificateBoolean,
      login: loginBoolean,
      password: passwordBoolean,
      sequence
    }
  } = useSelector(state => state.provisionalReceiptConfig);

  const isCertificate = !!certificateBoolean;
  const isLogin = !!loginBoolean;
  const isPassword = !!passwordBoolean;

  const isUserAdmin = +profileId == 1;

  const [isTermsOfUseModalOpen, setIsTermsOfUseModalOpen] = useState(!isTermsOfUseChecked && !termsOfUseDate);

  const [currentIndex, setCurrentIndex] = useState(0);

  const configurations = [
    {
      index: 0,
      id: 'authentication',
      title: 'Seleção de município',
      component: ProvisionalReceiptInfo,
      validations: (+configType == 0) ? null : authenticationValidations
    },
    {
      index: 1,
      id: 'basicInfo',
      title: 'Informações básicas',
      component: BasicInfo,
      validations: (+configType == 0) ? null : basicInfoValidations
    },
    ...sequence ? (
      [{
        index: 2,
        id: 'configRps',
        title: 'Configuração RPS',
        component: ConfigRPS,
        validations: (+configType == 0) ? null : configRpsValidations
      }]
    ) : (
      []
    ),
    {
      index: sequence ? 3 : 2,
      id: 'rpsInfo',
      title: 'Filtro de envio',
      component: ProvisionalReceiptFilter,
      validations: null
    }
  ];

  const currentConfiguration = configurations.find(config => config.index == currentIndex) || {};
  const isBasicConfig = +currentConfiguration.id == 1;

  useEffect(() => {
    if(establishmentId) {
      setCurrentIndex(0);
      dispatch(loadProvisionalReceiptInfo(userId, establishmentId));
    }
  }, [establishmentId]);

  const handlePreviousStep = () => {
    if(currentIndex == 0) {
      return history.push('/notas-fiscais');
    }

    setCurrentIndex(currentIndex - 1);
  }

  const handleNextStep = () => {
    if(currentIndex == configurations[configurations.length - 1].index) {
      return;
    }

    setCurrentIndex(currentIndex + 1);
  }

  const handleSubmit = values => {
    if(currentConfiguration.id == 'authentication') {
      if(+configType == 0) {
        return handleNextStep();
      }

      dispatch(registerFieldChange('cityCode', values?.cityCode));

      if(!values?.cityCode) {
        return toast.error('Escolha um município para continuar.');
      }

      if(isCertificate) {
        if(values?.certificateName && !values?.certificateFile) {
          return handleNextStep();
        }

        if(!certificateFileFromAPI) {
          if(!values?.certificateFile) {
            return toast.error('Escolha um certificado para continuar.');
          }
        }

        if(values?.certificateFile) {
          if(!values?.autenticatorPassword && !values?.autenticatorPasswordConfirm) {
            return handleNextStep();
          }

          const params = {
            userId,
            establishmentId,
            certificateFile: values?.certificateFile,
            autenticatorPassword: values?.autenticatorPassword,
            autenticatorPasswordConfirm: values?.autenticatorPasswordConfirm
          }

          dispatch(updateCertificate(params))
            .then(() => handleNextStep());
        }
      }

      if(!isCertificate) {
        return handleNextStep();
      }
    }

    if(currentConfiguration.id == 'basicInfo') {
      if(+configType == 0) {
        return handleNextStep();
      }

      let params = {
        logo: logoFile,
        fantasyName: values?.fantasyName,
        companyName: values?.companyName,
        documentId: VMasker.toNumber(values?.documentId),
        phone: values?.phone,
        email: values?.email,
        postalCode: values?.postalCode,
        street: values?.street,
        number: values?.number,
        complement: values?.complement,
        district: values?.district,
        city: values?.city,
        state: values?.state,
        cityCode: values?.cityCode,
        municipalSubscription: values?.municipalSubscription,
        stateSubscription: values?.stateSubscription,
        taxationRegime: values?.taxationRegime,
        taxationRegimeSpecial: values?.taxationRegimeSpecial,
        encouraging: values?.encouraging,
        simpleNational: values?.simpleNational,
        issRetain: values?.issRetain,
        streetType: values?.streetType,
        taxIncentive: values?.taxIncentive,
        nfse: {
          nfseNational: values?.nfseNational,
          serviceCode: values?.serviceCode,
          taxCode: values?.serviceItem,
          description: values?.description,
          taxType: values?.taxType,
          issChargeability: values?.issChargeability,
          code: values?.code,
          aliquot: values?.aliquot?.replaceAll(',', '.')
        }
      }

      if(isCertificate) {
        params = {
          ...params,
          ...(basicInfo?.certificateId != certificateData?.certificateId) && certificateData
        }
      }

      if(isCertificate && isLogin && !isPassword) {
        params = {
          ...params,
          nfse: {
            ...params.nfse,
            login: values?.autenticatorUser
          }
        }
      }

      if(isLogin && isPassword) {
        params = {
          ...params,
          nfse: {
            ...params.nfse,
            login: values?.autenticatorUser,
            password: values?.autenticatorUserPassword
          }
        }
      }

      if(!isLogin && isPassword) {
        params = {
          ...params,
          nfse: {
            ...params.nfse,
            token: values?.autenticatorToken
          }
        }
      }

      if(isRpsConfig) {
        return dispatch(upsertRPS(userId, establishmentId, params))
          .then(() => {
            dispatch(setProvisionalReceiptInfo({ ...params, ...params.nfse, ...certificate }));
            return handleNextStep();
          });
      }

      return dispatch(createRps(userId, establishmentId, params))
        .then(() => {
          dispatch(setProvisionalReceiptInfo({ ...params, ...params.nfse, ...certificate }));
          return handleNextStep();
        });
    }

    if(currentConfiguration.id == 'configRps') {
      if(+configType == 0) {
        return handleNextStep();
      }

      const params = {
        ...values,
        rpsGenerateOnline: 1
      }

      return dispatch(upsertConfigRps(userId, establishmentId, params))
        .then(() => handleNextStep());
    }

    if(currentConfiguration.id == 'rpsInfo') {
      const {
        statusRps,
        automaticSendNfse
      } = values;

      const {
        invoices,
        serviceOrders,
        selectedTransactions,
        paymentMethods
      } = values.automaticGenerate;

      const params = {
        taxSettingsUuid: basicInfo?.uuid,
        statusRps: !!statusRps ? 1 : 0,
        automaticSendNfse: !!automaticSendNfse ? 1 : 0,
        automaticGenerateNfse: JSON.stringify({
          invoices: !!invoices ? 1 : 0,
          serviceOrders: !!serviceOrders ? 1 : 0,
          selectedTransactions: !!selectedTransactions ? 1 : 0,
          paymentMethods: +serviceOrders == 0 ? paymentMethods : []
        })
      }

      return dispatch(updateFilters(userId, establishmentId, params))
        .then(() => history.push('/notas-fiscais'));
    }
  }

  const getInitialValues = () => {
    if(currentConfiguration.id == 'authentication') {
      return {
        ...certificateData,
        certificateFile,
        isCertificate,
        autenticatorPassword: '',
        autenticatorPasswordConfirm: '',
        cityCode: basicInfo?.cityCode || '',
        documentId: basicInfo?.documentId
      }
    }

    if(currentConfiguration.id == 'basicInfo') {
      return {
        ...basicInfo,
        ...custom_settings,
        ...tokenData,
        ...loginData,
        isLogin,
        isPassword,
        isUserAdmin
      }
    }

    if(currentConfiguration.id == 'configRps') {
      return rpsConfig;
    }

    if(currentConfiguration.id == 'rpsInfo') {
      return rpsFilter;
    }

    return {}
  }

  return(
    <Grid className={classes.root}>
      {isTermsOfUseModalOpen && (
        <TermsOfUseModal
          isOpen={isTermsOfUseModalOpen}
          setIsOpen={setIsTermsOfUseModalOpen}
        />
      )}
      <Stepper
        alternativeLabel
        nonLinear
        activeStep={currentIndex}
        style={{ background: 'transparent', padding: 0, paddingBottom: 24 }}
      >
        {configurations.map((nav, index) => (
          <Step key={nav.path}>
            <StepButton
              disabled={!isRpsConfig}
              completed={currentIndex > index}
              onClick={() => setCurrentIndex(index)}
            >
              {nav.title}
            </StepButton>
          </Step>
        ))}
      </Stepper>
      <Grid>
        <Formik
          enableReinitialize
          initialValues={getInitialValues()}
          validationSchema={currentConfiguration.validations}
          validateOnChange={false}
          onSubmit={handleSubmit}
        >
          {({ ...formikProps }) => {
            return(
              <Form>
                {(currentIndex == currentConfiguration.index) && (
                  <currentConfiguration.component formikProps={formikProps} />
                )}
                <Grid className={classes.footer}>
                  <Container>
                    <Grid container justify="space-between">
                      <Button
                        loading={isLoading || businessLoading}
                        color="secondary"
                        type="button"
                        onClick={handlePreviousStep}
                      >
                        Voltar
                      </Button>
                      <Button
                        disabled={isBasicConfig && !isCityLoaded}
                        loading={isLoading || businessLoading}
                        color="success"
                        type="submit"
                      >
                        Avançar
                      </Button>
                    </Grid>
                  </Container>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </Grid>
    </Grid>
  );
}