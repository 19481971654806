import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import moment from 'moment';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faCheck } from '@fortawesome/free-solid-svg-icons';

import { Formik, Form } from 'formik';
import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MuiDialogActions from '@material-ui/core/DialogActions';

import FileUploader from '../../../../components/FileUploader';
import Modal from '../../../../components/Modal';
import Loader from '../../../../components/Loader';
import Input from '../../../../components/Input';
import Button from '../../../../components/Button';
import ButtonConfirmation from '../../../../components/ButtonConfirmation';
import SingleDatePicker from '../../../../components/SingleDatePicker';
import Select from '../../../../components/Select';

import { paymentValidations, passwordValidations } from '../validations';
import { handleRegistration, handleConfirm, setRemove, clearChanges } from '../../store/actions';
import { setAttachment } from '../../store/actions';
import { sendNfse } from 'pages/ProvisionalReceiptConfig/store/thunk';
import { loadNfseConcept } from 'pages/ProvisionalReceipt/store/thunk';
import { setRpsInfo } from 'pages/ProvisionalReceipt/store/actions';
import { fetchUsersSituation } from 'components/Filter/store/services';
import { loadConfigurations } from '../../../Operational/store/thunk';
import { upsertTransaction, removeTransaction, loadResume, upsertAttachment } from '../../store/thunk';
import { convertDateToDB, convertFormattedMoneyToNumber, convertFormattedMoneyAndNegativeMoneyToNumber, convertToBrl } from '../../../../helpers/converters';
import { getFileExtensionFromUrl } from '../../../../helpers/common';

import useWindowDimensions from 'helpers/hooks/useWindowDimensions';

import { SITE_RESTRICTIONS } from '../../../../helpers/restrictions';

export default function Payment({ isOpen }) {
  const dispatch = useDispatch();

  const { width } = useWindowDimensions();
  const isSm = width < 960;
  const isMobile = width < 728;

  const { userId } = useSelector(state => state.profile);
  const { establishmentId } = useSelector(state => state.businessInfo);
  const { token } = useSelector(state => state.login);
  const { startDate, endDate } = useSelector(state => state.dateFilter);
  const { qp, selects } = useSelector(state => state.filters);
  const { submit, isConfirm, isRemoving, isLoading } = useSelector(state => state.transactions);
  const { isSendNfseLoading } = useSelector(state => state.provisionalReceiptConfig);
  const page = useSelector(state => state.pagination.activePage);
  const pageSize = useSelector(state => state.pagination.size);
  const userRestrictions = useSelector(state => state.profile.userRestrictions);

  const {
    sourceId,
    isNfInfo,
    nfInfo: {
      rpsSeries,
      rpsNumber,
      nfNumber
    }
  } = useSelector(state => state.provisionalReceipt);

  const { cashierId, transactionId, situationId, attachment } = submit;

  const [taxSettings, setTaxSettings] = useState(0);

  const [isAttachmentModalOpen, setIsAttachmentModalOpen] = useState(false);
  const [attachmentRemoveLoading, setAttachmentRemoveLoading] = useState(false);
  const [isAttachmentError, setIsAttachmentError] = useState(false);

  const [isInvalidAmount, setIsInvalidAmount] = useState(false);

  const availableAccounts = selects
    ?.find(select => select?.id == 'account')?.options
    ?.filter(account => +account?.accountTypeId != 1);

  const paymentCategories = selects
    ?.find(select => select?.id == 'paymentCategory')?.options
    ?.map(option => {
      return {
        value: option?.categoryId,
        label: option?.categoryName
      }
    })
    ?.filter(option => option?.value);

  const isOnlyOneAccountAvailable = availableAccounts
    ?.filter(account => !!account?.value)
    ?.length == 1;

  const getOnlyAccountAvailable = () => {
    const account = availableAccounts?.filter(account => !!account?.value)[0]?.value || '';
    return account;
  }

  useEffect(() => {
    dispatch(loadConfigurations(userId, establishmentId));
  }, []);

  useEffect(() => {
    (async () => {
      if(submit?.establishmentId) {
        const { data } = await fetchUsersSituation(userId, token);

        const establishmentTaxSettings = data?.establishments
          ?.find(establishment => +establishment?.establishmentId == +submit?.establishmentId)
          ?.taxSettings;

        setTaxSettings(establishmentTaxSettings);
      }
    })();
  }, [submit?.establishmentId]);

  useEffect(() => {
    dispatch(setRpsInfo({
      rpsSeries: '',
      rpsNumber: '',
      nfNumber: '',
      nfStatus: ''
    }));

    if(+taxSettings == 1 && !!transactionId && (+situationId == 2 || +situationId == 3)) {
      if(sourceId || transactionId) {
        dispatch(loadNfseConcept(userId, establishmentId, sourceId || transactionId));
      }
    }
  }, [taxSettings, transactionId, situationId]);

  const handleClosePayment = () => {
    dispatch(handleRegistration(false));
  }

  const handleUploadFile = e => {
    const receipt = e.target.files[0];

    dispatch(upsertAttachment(userId, establishmentId, receipt))
      .then(() => toast.warn('Salvar para persistir o comprovante!'))
      .catch(() => dispatch(setAttachment(null)));
  }

  const handleRemovePayment = data => {
    const extraProps = {
      userId,
      establishmentId,
      page,
      pageSize,
      startDate,
      endDate
    }

    const params = { transactionId, ...data };

    dispatch(removeTransaction(params, extraProps));
  }

  const handleSavePayment = (values, actions) => {
    const { resetForm } = actions;

    const extraProps = {
      userId,
      establishmentId,
      page,
      pageSize,
      startDate,
      endDate
    }

    const params = {
      isConfirm,
      ...values,
      previsionDateTime: moment(values.previsionDateTime).format('YYYY-MM-DD HH:mm:ss'),
      paymentDateTime: moment(values.paymentDateTime).format('YYYY-MM-DD HH:mm:ss'),
      attachment
    }

    if(+convertFormattedMoneyAndNegativeMoneyToNumber(params?.amount) == 0) {
      setIsInvalidAmount(true);
      return;
    }

    return dispatch(upsertTransaction(params, extraProps)).then(() => {
      resetForm();
      dispatch(loadResume(userId, establishmentId, { startDate, endDate, qp }));
    });
  }

  return(
    <Formik
      enableReinitialize={transactionId}
      initialValues={{
        ...submit,
        amount: convertToBrl(convertFormattedMoneyToNumber(submit?.amount)),
        accountId: isOnlyOneAccountAvailable && !transactionId
          ? getOnlyAccountAvailable()
          : submit?.accountId
      }}
      validationSchema={paymentValidations}
      onSubmit={handleSavePayment}
    >
      {({ errors, values, handleChange, setFieldValue }) => (
        <Modal
          id="payment-modal"
          scroll="body"
          title={transactionId ? 'Editar despesa' : 'Adicionar despesa'}
          open={isOpen}
          onClose={handleClosePayment}
        >
          {isAttachmentModalOpen && (
            <Modal
              scroll="body"
              open={isAttachmentModalOpen}
              onClose={() => setIsAttachmentModalOpen(false)}
              title="Comprovante"
            >
              <Grid container justify="center" style={{ padding: 5 }}>
                {isAttachmentError ? (
                  <Typography>
                    Pré visualização não disponível. <a href={attachment} target='_blank'>Clique aqui para fazer o download do {getFileExtensionFromUrl(attachment)}.</a>
                  </Typography>
                ) : (
                  <img
                    src={attachment}
                    width={isSm ? "100%" : "50%"}
                    height="auto"
                    onError={() => setIsAttachmentError(true)}
                  />
                )}
              </Grid>
              <Grid container style={{ marginTop: 10 }}>
                <Grid xs={6} style={{ padding: 5 }}>
                  <Button
                    fullWidth
                    type="button"
                    onClick={() => setIsAttachmentModalOpen(false)}
                  >
                    Voltar
                  </Button>
                </Grid>
                <Grid xs={6} style={{ padding: 5 }}>
                  <Button
                    fullWidth
                    type="button"
                    color="error"
                    disabled={userRestrictions?.includes(+SITE_RESTRICTIONS.REMOVE_TRANSACTION_ATTACHMENT_AND_CASHIER)}
                    loading={attachmentRemoveLoading}
                    onClick={() => {
                      setAttachmentRemoveLoading(true);

                      return setTimeout(() => {
                        toast.warn('Salvar para persistir o comprovante!');

                        dispatch(setAttachment(null));

                        setIsAttachmentModalOpen(false);
                        setAttachmentRemoveLoading(false);
                      }, 1000);
                    }}
                  >
                    Excluir
                  </Button>
                </Grid>
              </Grid>
            </Modal>
          )}
          <Form>
            <Loader isLoading={isLoading}>
              <Grid container spacing={3}>
                <Grid item sm={8} xs={12}>
                  <Input
                    autoFocus
                    id="description"
                    name="description"
                    label="Descrição"
                    value={values.description}
                    error={errors.description}
                    helperText={errors.description}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item sm={4} xs={12}>
                  <Select
                    name="categoryId"
                    label="Categoria"
                    options={paymentCategories}
                    value={parseInt(values.categoryId, 10)}
                    error={errors.categoryId}
                    helperText={errors.categoryId}
                    onChange={e => setFieldValue(e.target.name, e.target.value)}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3} alignItems="flex-end">
                <Grid item sm={(cashierId == null) ? 4 : 6} xs={12}>
                  <Typography variant="subtitle1" color="primary">
                    Data do vencimento
                  </Typography>
                  {values.transactionId && (
                    <SingleDatePicker
                      name="previsionDateTime"
                      initialDate={values.transactionId ? values.previsionDateTime : moment().format('YYYY-MM-DD HH:mm:ss')}
                      onDateChange={date => setFieldValue('previsionDateTime', convertDateToDB(date))}
                    />
                  )}
                  {!values.transactionId && (
                    <SingleDatePicker
                      name="previsionDateTime"
                      initialDate={values.previsionDateTime}
                      onDateChange={date => setFieldValue('previsionDateTime', convertDateToDB(date))}
                    />
                  )}
                </Grid>
                {(cashierId == null) && (
                  <Grid item sm={4} xs={12}>
                    <Select
                      name="accountId"
                      label="Conta origem"
                      options={availableAccounts}
                      disabled={cashierId}
                      value={parseInt(values.accountId, 10)}
                      error={errors.accountId}
                      helperText={errors.accountId}
                      onChange={e => setFieldValue(e.target.name, e.target.value)}
                    />
                  </Grid>
                )}
                <Grid item sm={(cashierId == null) ? 4 : 6} xs={12}>
                  <Input
                    id="amount"
                    name="amount"
                    label="Valor"
                    type="money"
                    placeholder="R$ 0,00"
                    value={values.amount}
                    error={errors.amount || isInvalidAmount}
                    helperText={errors.amount || (isInvalidAmount && 'O valor precisa ser maior que zero')}
                    onChange={handleChange}
                    maxLength={13}
                  />
                </Grid>
              </Grid>
              <Divider />
              <Grid container spacing={3}>
                <Grid item sm={6} xs={12}>
                  <Grid container alignItems="center">
                    <FormControlLabel
                      label="Pago"
                      control={
                        <Switch
                          id="paid"
                          name="paid"
                          color="primary"
                          onChange={() => dispatch(handleConfirm(!isConfirm))}
                          checked={isConfirm}
                        />
                      }
                    />
                    {submit?.userName && (
                      <Typography>Transação realizada por: {submit?.userName}</Typography>
                    )}
                  </Grid>
                </Grid>
                {rpsNumber > 0 && (
                  <Grid item sm={6} xs={12} style={{ display: 'flex', justifyContent: isMobile ? 'flex-start' : 'flex-end' }}>
                    <p>RPS: {rpsSeries}-{rpsNumber} | N˚ NFSe: {nfNumber}</p>
                  </Grid>
                )}
              </Grid>
              {isConfirm && (
                <Grid container>
                  <Grid item sm={6} xs={12}>
                    <Typography variant="subtitle1" color="primary">
                      Data da despesa
                    </Typography>
                    {values.transactionId && (
                      <SingleDatePicker
                        name="paymentDateTime"
                        blockFutureDates
                        onBlockedDate={() => toast.error('A data da despesa não pode ser maior do que a data atual.')}
                        initialDate={values.transactionId ? values.paymentDateTime : moment().format('YYYY-MM-DD HH:mm:ss')}
                        onDateChange={date => setFieldValue('paymentDateTime', convertDateToDB(date))}
                      />
                    )}
                    {!values.transactionId && (
                      <SingleDatePicker
                        name="paymentDateTime"
                        blockFutureDates
                        onBlockedDate={() => toast.error('A data da despesa não pode ser maior do que a data atual.')}
                        initialDate={values.paymentDateTime}
                        onDateChange={date => setFieldValue('paymentDateTime', convertDateToDB(date))}
                      />
                    )}
                  </Grid>
                </Grid>
              )}
            </Loader>
            {isRemoving ? (
              <Formik
                enableReinitialize
                initialValues={{ reason: '', userPassword: '' }}
                validationSchema={passwordValidations}
                onSubmit={handleRemovePayment}
              >
                {newFormik => (
                  <Form>
                    <Grid container spacing={3}>
                      <Grid item sm={6} xs={12}>
                        <Input
                          id="reason"
                          type="textarea"
                          label="Observação"
                          placeholder="Digite sua observação"
                          error={newFormik.errors.reason}
                          helperText={newFormik.errors.reason}
                          value={newFormik.values.reason}
                          onChange={newFormik.handleChange}
                        />
                      </Grid>
                      <Grid item sm={6} xs={12}>
                        <Input
                          id="userPassword"
                          type="password"
                          label="Informe a sua senha"
                          placeholder="Digite sua senha"
                          error={newFormik.errors.userPassword}
                          helperText={newFormik.errors.userPassword}
                          value={newFormik.values.userPassword}
                          onChange={newFormik.handleChange}
                        />
                      </Grid>
                    </Grid>
                    <Divider />
                    <MuiDialogActions>
                      <Button
                        type="button"
                        color="error"
                        loading={isLoading}
                        onClick={() => dispatch(clearChanges())}
                      >
                        Cancelar remoção
                      </Button>
                      <Button color="primary" loading={isLoading}>
                        Concluir remoção
                      </Button>
                    </MuiDialogActions>
                  </Form>
                )}
              </Formik>
            ) : (
              <>
                <Divider />
                <MuiDialogActions>
                  {!isNfInfo && (
                    <ButtonConfirmation
                      title="Enviar NFS-e"
                      color="success"
                      disabled={isLoading}
                      loading={isLoading || isSendNfseLoading}
                      modalTitle="Enviar NFS-e"
                      modalText="Tem certeza que deseja enviar a nota fiscal?"
                      modalButtons={[
                        {
                          color: 'success',
                          title: 'Sim',
                          onClick: () => dispatch(sendNfse(userId, submit?.establishmentId, transactionId))
                        }
                      ]}
                    />
                  )}
                  {!attachment && (
                    <FileUploader
                      name="attachment"
                      displayFileName={false}
                      onChange={e => handleUploadFile(e)}
                    >
                      <FontAwesomeIcon
                        icon={faPlus}
                        color="#FFFFFF"
                        size="xs"
                        style={{ marginRight: 5 }}
                      />
                      Comprovante
                    </FileUploader>
                  )}
                  {!!attachment && (
                    <Button
                      type="button"
                      onClick={() => setIsAttachmentModalOpen(true)}
                    >
                      <FontAwesomeIcon
                        icon={faCheck}
                        color="#6DBB7B"
                        size="xs"
                        style={{ marginRight: 5 }}
                      />
                      Comprovante
                    </Button>
                  )}
                  {transactionId && (
                    <Button
                      type="button"
                      color="error"
                      loading={isLoading}
                      onClick={() => dispatch(setRemove())}
                      disabled={userRestrictions?.includes(SITE_RESTRICTIONS.SINGLE_TRANSACTIONS)}
                    >
                      Remover despesa
                    </Button>
                  )}
                  <Button color="success" loading={isLoading}>
                    Salvar
                  </Button>
                </MuiDialogActions>
              </>
            )}
          </Form>
        </Modal>
      )}
    </Formik>
  );
}

Payment.propTypes = {
  isOpen: PropTypes.bool.isRequired
};