import { makeStyles } from '@material-ui/core/styles';
import grey from '@material-ui/core/colors/grey';

export const useStyles = makeStyles(({ spacing, palette }) => ({
  checkbox: {
    width: '100%',
    justifyContent: 'center',
    border: 'solid',
    borderColor: grey[300],
    borderWidth: 1,
    borderRadius: 8,
    padding: spacing(3),
    marginBottom: spacing(2),
    margin: 0
  },
  checkboxError: {
    borderColor: palette.error.main
  },
  infoCard: {
		display: 'flex',
		flexDirection: 'column',
		marginTop: 10,
    marginBottom: 15,
    padding: 15,
    backgroundColor: '#fcd40a',
    border: '1px solid #38a8d8',
    borderRadius: 10
  },
	infoCardText: {
		color: '#1E5168',
		fontSize: 15,
		fontWeight: 'bold'
	}
}));