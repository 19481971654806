import React, { useState, useRef } from 'react';
import { CSVLink } from 'react-csv';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { numericFormatter } from 'react-number-format';
import moment from 'moment';
import { map } from 'ramda';

import Button from '../Button';

import { formatFilters, formatPhone } from '../../helpers/formatters';
import { renameProp } from '../../helpers/common';
import { formatPermanence } from '../../helpers/formatters';

import {
  convertList,
  convertToOptions,
  convertToBrl,
  convertToEstablishmentName
} from '../../helpers/converters';

export default function ExportCSV({ fileName, disabled, exportType, requestData, exportHeaders, formatComponentData }) {
  const exportButton = useRef(null);

  const [exportData, setExportData] = useState([]);
  const [operationalExportData, setOperationalExportData] = useState([]);

  const [headers] = useState(map(renameProp('id', 'key'), exportHeaders));

  const { qp, selects, isExporting } = useSelector(state => state.filters);
  const { establishmentId } = useSelector(state => state.businessInfo);
  const { establishments } = useSelector(state => state.login);
  const { areasOfUse } = useSelector(state => state.products.filters);

  const availablePaymentMethods = convertToOptions(['paymentMethod'], selects);

  const establishmentName =
    convertToEstablishmentName(establishmentId, establishments) ||
    'Todos os estabelecimentos';

  const getEstablishmentExportName = exportType => {
    if(exportType == 'clients') {
      return `${establishmentName}-clientes.xls`;
    }

    if(exportType == 'products') {
      return `${establishmentName}-produtos.xls`;
    }

    if(exportType == 'sellOrders') {
      return `${establishmentName}-historico-de-venda.xls`;
    }

    return establishmentName;
  }

  const handleExportation = () => {
    const wholeData = { page: '', pageSize: '', exportation: true, qp };

    requestData(wholeData, 'xls')
      .then(async data => {
        if(exportType === 'comissioned') {
          const payrollSituations = data?.payrollSituations?.options;

          const formattedExportData = data?.data?.map(data => {
            return {
              name: data?.name,
              roleName: data?.roleName,
              situationId: payrollSituations?.find(situation => situation?.id == data?.payroll?.situationId)?.name || '',
              dueDate: moment(data?.payroll?.dueDate).format('DD/MM/YYYY'),
              amount: data?.payroll?.amount ? `R$ ${data?.payroll?.amount}` : ''
            }
          });

          await setExportData(formattedExportData);
        }

        if(exportType === 'operationalExport') {
          const {
            resume: { totalSoUpdated, totalSoCanceled, totalSoPg, totalAmountSoPg },
            search: { startDateTime, endDateTime },
            establishment,
            content,
            mediumPeriod,
            totalPeriod
          } = data;

          const formattedContent = content?.map((item, index) => {
            return {
              ...item,
              ...index == 0 ? {
                startDateTime,
                endDateTime,
                totalSoUpdated,
                totalAmountSoPg,
                totalSoPg,
                totalSoCanceled,
                totalAmountSoPg,
                totalPeriod,
                mediumPeriod
              } : {}
            }
          });

          const formattedExportData = !establishment
            ? formattedContent?.map(data => {
                return [
                  data?.establishmentName,
                  data?.serviceOrderCode || '',
                  data?.clientName || '',
                  data?.plate || '',
                  data?.vehicleModel || '',
                  data?.vehicleColor || '',
                  !!data?.cardCode ? data?.cardCode : '',
                  moment(data?.entryDateTime).format('DD/MM/YYYY'),
                  data?.entryDateTime?.split(' ')[1] || '',
                  data?.exitDateTime == '0001-01-01 00:00:00' ? '' : moment(data?.exitDateTime).format('DD/MM/YYYY'),
                  data?.exitDateTime?.split(' ')[1] == '00:00:00' ? '' : data?.exitDateTime?.split(' ')[1],
                  formatPermanence([data?.exitDateTime, data?.entryDateTime]),
                  data?.userName,
                  data?.userOutputName,
                  data?.typePrice || '',
                  data?.services?.map(service => `${service?.description} ${!!service?.quantity ? `(${service?.quantity})` : ''} - ${convertToBrl(+service?.amount)}`)?.join(', ') || '',
                  data?.paymentMethodName || '',
                  data?.observations?.observation || '',
                  (parseFloat(data?.amount) + parseFloat(data?.amountServices) - parseFloat(data?.discountAmount))?.toFixed(2)?.toString()?.replace('.', ','),
                  !!data?.startDateTime ? `${moment(data?.startDateTime, 'DD/MM/YYYY').format('DD/MM/YYYY')} - ${moment(data?.endDateTime, 'DD/MM/YYYY').format('DD/MM/YYYY')}` : '',
                  data?.totalSoUpdated?.toString() || '',
                  data?.totalSoPg?.toString() || '',
                  data?.totalSoCanceled?.toString() || '',
                  !!data?.totalAmountSoPg ? convertToBrl(parseFloat(data?.totalAmountSoPg))?.toString() : '',
                  data?.totalPeriod || '',
                  data?.mediumPeriod || ''
                ];
              })
            : formattedContent?.map(data => {
                return [
                  data?.serviceOrderCode || '',
                  data?.clientName || '',
                  data?.plate || '',
                  data?.vehicleModel || '',
                  data?.vehicleColor || '',
                  !!data?.cardCode ? data?.cardCode : '',
                  moment(data?.entryDateTime).format('DD/MM/YYYY'),
                  data?.entryDateTime?.split(' ')[1] || '',
                  data?.exitDateTime == '0001-01-01 00:00:00' ? '' : moment(data?.exitDateTime).format('DD/MM/YYYY'),
                  data?.exitDateTime?.split(' ')[1] == '00:00:00' ? '' : data?.exitDateTime?.split(' ')[1],
                  formatPermanence([data?.exitDateTime, data?.entryDateTime]),
                  data?.userName,
                  data?.userOutputName,
                  data?.typePrice || '',
                  data?.services?.map(service => `${service?.description} ${!!service?.quantity ? `(${service?.quantity})` : ''} - ${convertToBrl(+service?.amount)}`)?.join(', ') || '',
                  data?.paymentMethodName || '',
                  data?.observations?.observation || '',
                  (parseFloat(data?.amount) + parseFloat(data?.amountServices) - parseFloat(data?.discountAmount))?.toFixed(2)?.toString()?.replace('.', ','),
                  !!data?.startDateTime ? `${moment(data?.startDateTime, 'DD/MM/YYYY').format('DD/MM/YYYY')} - ${moment(data?.endDateTime, 'DD/MM/YYYY').format('DD/MM/YYYY')}` : '',
                  data?.totalSoUpdated?.toString() || '',
                  data?.totalSoPg?.toString() || '',
                  data?.totalSoCanceled?.toString() || '',
                  !!data?.totalAmountSoPg ? convertToBrl(parseFloat(data?.totalAmountSoPg))?.toString() : '',
                  data?.totalPeriod || '',
                  data?.mediumPeriod || ''
                ];
              });

          const operationalExportFormattedData = [
            !establishment
              ? ['Estabelecimento', 'Nº', 'Cliente', 'Placa', 'Modelo', 'Cor', 'Cartão', 'Data Entrada', 'Hora Entrada', 'Data Saída', 'Hora Saída', 'Permanência', 'Operador', 'Operador Saída', 'Cobrança', 'Serviços', 'Pagamento', 'Observação', 'Valor', 'Período', 'Total de OS alterada', 'Total de OS paga', 'Total de OS cancelada', 'Total de OS', 'Período total', 'Período médio']
              : ['Nº', 'Cliente', 'Placa', 'Modelo', 'Cor', 'Cartão', 'Data Entrada', 'Hora Entrada', 'Data Saída', 'Hora Saída', 'Permanência', 'Operador', 'Operador Saída', 'Cobrança', 'Serviços', 'Pagamento', 'Observação', 'Valor', 'Período', 'Total de OS alterada', 'Total de OS paga', 'Total de OS cancelada', 'Total de OS', 'Período total', 'Período médio'],
            ...formattedExportData
          ];

          setOperationalExportData(operationalExportFormattedData);
        }

        if(exportType === 'clients') {
          const exportedData = await convertList(
            ['category', 'clientInvoiceSituation'],
            formatFilters(['category', 'clientInvoiceSituation'], selects),
            formatComponentData(exportType === 'extract' ? data : data.content, true)
          );

          const formattedData = exportedData?.map(content => {
            return {
              ...content,
              vehicles: content?.vehicles?.join(", "),
              paymentMethodId: availablePaymentMethods?.find(paymentMethod => +paymentMethod?.value == +content?.paymentMethodId)?.label
            }
          });

          await setExportData(formattedData);
        }

        if(exportType === 'extract') {
          const exportedData = await convertList(
            ['category', 'clientInvoiceSituation'],
            formatFilters(['category', 'clientInvoiceSituation'], selects),
            formatComponentData(exportType === 'extract' ? data : data.content, true)
          );

          const formattedData = exportedData?.map(content => {
            return {
              ...content,
              vehicles: content?.vehicles?.join(", "),
              paymentMethodId: availablePaymentMethods?.find(paymentMethod => +paymentMethod?.value == +content?.paymentMethodId)?.label
            }
          });

          await setExportData(formattedData);
        }

        if(exportType === 'products') {
          const exportedData = data?.map(item => {
            const areasOfUseDescription = areasOfUse
              ?.filter(area => item?.areasOfUse?.includes(area?.id?.toString()))
              ?.map(area => area?.description)
              ?.join(', ');

            return [
              item?.name || '',
              item?.brandName || '',
              item?.productTypeName || '',
              areasOfUseDescription || '',
              numericFormatter(item?.quantityToUse?.toString() || '', { thousandSeparator: '.', decimalSeparator: ',', decimalScale: 2, fixedDecimalScale: 2 }),
              numericFormatter(item?.minQuantityToUse?.toString() || '', { thousandSeparator: '.', decimalSeparator: ',', decimalScale: 2, fixedDecimalScale: 2 }),
              numericFormatter(item?.netContent?.toString() || '', { thousandSeparator: '.', decimalSeparator: ',', decimalScale: 2, fixedDecimalScale: 2 }),
              item?.measurementUnitAbbreviation || ''
            ]
          });

          const exportFormattedData = [
            ['Nome', 'Marca', 'Categoria do Produto', 'Aplicação do Produto', 'Estoque Consumo Atual', 'Estoque Consumo Mínimo', 'Qnd. und. de medida por item', 'Unidade de medida'],
            ...exportedData
          ];

          await setExportData(exportFormattedData);
        }

        if(exportType === 'sellOrders') {
          if(data?.length == 0) {
            return toast.error('Nenhuma informação encontrada para exportação.');
          }

          const exportedData = data?.map(item => {
            return [
              item?.clientName,
              formatPhone(item?.clientPhone),
              item?.paymentMethodName,
              `R$ ${numericFormatter(item?.totalAmount?.toString(), { thousandSeparator: '.', decimalSeparator: ',', decimalScale: 2, fixedDecimalScale: 2 })}`,
              item?.situationDescription,
              `R$ ${numericFormatter(item?.productsAmount?.toString(), { thousandSeparator: '.', decimalSeparator: ',', decimalScale: 2, fixedDecimalScale: 2 })}`,
              `R$ ${numericFormatter(item?.discountAmount?.toString(), { thousandSeparator: '.', decimalSeparator: ',', decimalScale: 2, fixedDecimalScale: 2 })}`,
              item?.userName,
              item?.orderItems?.map(order => `${order?.name} (${order?.quantity}) - R$ ${numericFormatter(order?.amount?.toString(), { thousandSeparator: '.', decimalSeparator: ',', decimalScale: 2, fixedDecimalScale: 2 })}`)?.join(', ')
            ]
          });

          const exportFormattedData = [
            ['Cliente', 'Telefone', 'Meio de Pagamento', 'Valor Total', 'Situação', 'Valor dos Produtos', 'Desconto', 'Usuário', 'Pedidos'],
            ...exportedData
          ];

          await setExportData(exportFormattedData);
        }

        await exportButton.current.link.click();
      })
      .catch(() => toast.error('Não foi possível exportar, tente novamente mais tarde'));
  }

  return(
    <>
      <Button
        disabled={disabled}
        fullWidth
        disableElevation
        color="inherit"
        loading={isExporting}
        onClick={() => handleExportation()}
      >
        XLS
      </Button>
      <CSVLink
        ref={exportButton}
        headers={exportType === 'operationalExport' ? null : headers}
        data={exportType === 'operationalExport' ? operationalExportData : exportData}
        separator=";"
        filename={
          (exportType == 'clients' || exportType == 'products' || exportType == 'sellOrders') && establishmentId
            ? getEstablishmentExportName(exportType)
            : `${fileName}.xls`
        }
      />
    </>
  );
}