import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';

import { Grid } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import Paper from '@material-ui/core/Paper';
import MTable from '../../../components/MTable';
import Filter from '../../../components/Filter/components';
import FilterMobile from '../../../components/FilterMobile/components';

import AccountRegistration from './AccountRegistration';

import { loadFilters } from '../../../components/Filter/store/thunk';
import { formatFilters } from '../../../helpers/formatters';
import { loadAccount, loadAccounts } from '../store/thunk';
import { openRegistrationModal, clearRegistration } from '../store/actions';

const headers = [
  { title: 'Descrição', field: 'accountName', sortable: false },
  { title: 'Banco', field: 'bankName', sortable: false }
];

const width = window.innerWidth;
const isMobile = width < 1024;
const tableRowWidth = width < 768 ? 120 : 200;

export default function Accounts() {
  const dispatch = useDispatch();

  const { userId } = useSelector(state => state.profile);
  const { establishmentId } = useSelector(state => state.businessInfo);
  const { list, registration, total, isLoading } = useSelector(state => state.accounts);
  const { selects, qp } = useSelector(state => state.filters);

  const filters = formatFilters(['status'], selects);

  const [accountsPage, setAccountsPage] = useState(1);
  const accountsPageSize = 10;
  const accountsPageCount = Math.ceil(total / accountsPageSize);

  const handleLoadAccounts = ({ page, qp }) => {
    if(userId) {
      return dispatch(loadAccounts({ page, pageSize: accountsPageSize, qp, userId, establishmentId }));
    }
  }

  const handleRegistration = () => {
    dispatch(clearRegistration());
    dispatch(openRegistrationModal(true));
  }

  const handleUpdate = account => {
    const params = {
      userId,
      establishmentId: establishmentId,
      accountId: account.accountId
    }

    dispatch(loadAccount(params));
  }

  useEffect(() => {
    handleLoadAccounts({ page: 1, qp });
    dispatch(loadFilters(userId, establishmentId));
  }, [userId, establishmentId]);

  useEffect(() => {
    handleLoadAccounts({ page: accountsPage, qp });
  }, [accountsPage]);

  return (
    <>
      {registration && <AccountRegistration isOpen={registration} />}
      <Paper>
        {isMobile ? (
          <FilterMobile
            filter
            hasSearchTerm
            hasModalButton="Adicionar conta"
            filters={filters}
            toggleModal={handleRegistration}
            handlePageRequest={handleLoadAccounts}
          />
        ) : (
          <Filter
            filter
            hasSearchTerm
            hasModalButton="Adicionar conta"
            filters={filters}
            toggleModal={handleRegistration}
            handlePageRequest={handleLoadAccounts}
          />
        )}
        <MTable
          loading={isLoading}
          headers={headers}
          data={list}
          actions={[
            rowData => ({
              icon: () => (rowData.mainAccount == 1 && rowData.accountTypeId == 1)
                ? null
                : (
                  <FontAwesomeIcon
                    icon={faPen}
                    color="#2CA9D6"
                    size="xs"
                  />
                ),
              tooltip: (rowData.mainAccount == 1 && rowData.accountTypeId == 1) ? null : 'Editar',
              onClick: (_, rowData) => handleUpdate(rowData),
              disabled: (rowData.mainAccount == 1 && rowData.accountTypeId == 1)
            })
          ]}
          actionColumnWidth={tableRowWidth}
        />
        <Grid
          container
          xs={12}
          style={{ padding: 10, display: 'flex', justifyContent: 'flex-end' }}
        >
          <Pagination
            color="primary"
            variant="outlined"
            shape="rounded"
            count={accountsPageCount}
            page={accountsPage}
            onChange={(_, page) => setAccountsPage(page)}
          />
        </Grid>
      </Paper>
    </>
  );
}