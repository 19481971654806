import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

import { filter, propEq } from 'ramda';
import moment from 'moment';

import { loadCashFlow } from '../store/thunk';
import { verifyTypeAndSetCurrentYear, setYearRange, handleBuildResume, clearBuildResumeFilters } from '../store/actions';
import { loadFilters } from '../../../components/Filter/store/thunk';

import MTable from '../../../components/MTable';
import Button from '../../../components/Button';
import Resume from './Resume';
import PeriodType from './PeriodType';
import BuildResumeModal from './BuildResumeModal';

import { useStyles } from './styles';

let headers = [];

const dayHeaders = [
  { title: 'Data', field: 'day', sorting: true, defaultSort: 'asc' },
  { title: 'Recebimentos', field: 'amountReceipt', sorting: false },
  { title: 'Pagamentos', field: 'amountPayment', sorting: false }
];

const monthHeaders = [
  {
    title: 'Mês',
    field: 'month',
    sorting: true,
    customSort: (a, b) => a.monthId - b.monthId
  },
  {
    title: 'Total de recebimentos',
    field: 'amountReceipt',
    sorting: false
  },
  { title: 'Total de pagamentos', field: 'amountPayment', sorting: false },
  { title: 'Lucro', field: 'amountBalance', sorting: false }
];

headers = dayHeaders;

export default function CashFlow() {
  const classes = useStyles();

  const dispatch = useDispatch();

  const { userId } = useSelector(state => state.profile);
  const establishmentId = useSelector(state => state.businessInfo.establishmentId);
  const establishments = useSelector(state => state.login.establishments);

  const {
    type,
    month,
    year,
    buildingResume,
    resume,
    content,
    isLoading
  } = useSelector(state => state.cashFlow);

  const mountHeader = type => {
    headers = type === 'day' ? dayHeaders : monthHeaders;
  }

  const requestCashFlow = () => {
    dispatch(loadCashFlow(userId, establishmentId, type, month, year));
  }

  const filterEstablishmentForSetYearRange = () => {
    const getElementById = filter(propEq('establishmentId', establishmentId));
    const establishmentFiltered = getElementById(establishments);

    const baseYear = parseInt(
      establishmentFiltered[0]?.created_at
        ? moment(establishmentFiltered[0]?.created_at).format('YYYY')
        : moment().format('YYYY'),
      10
    );

    const finalYear = baseYear + 50;

    setYearRange([baseYear, finalYear]);
  }

  useEffect(() => {
    async function loadData() {
      await mountHeader(type);
      await filterEstablishmentForSetYearRange();

      await dispatch(verifyTypeAndSetCurrentYear());
      await requestCashFlow();
      await dispatch(loadFilters(userId, establishmentId));
    }

    if(establishmentId) {
      loadData();
    }
  }, [establishmentId]);

  useEffect(() => {
    if(establishmentId || type || year || month) {
      mountHeader(type);

      if(establishmentId) {
        filterEstablishmentForSetYearRange();
      }

      if(establishmentId) {
        requestCashFlow();
      }
    }
  }, [establishmentId, type, year, month]);

  const onBuildResume = () => {
    dispatch(clearBuildResumeFilters());
    dispatch(handleBuildResume(true));
  }

  return (
    <>
      {buildingResume && (
        <BuildResumeModal
          isOpen={buildingResume}
          userId={userId}
          establishmentId={establishmentId}
        />
      )}
      <Resume resume={resume} />
      <Container className={classes.container}>
        <Grid container>
          <Grid item xs={12} md={4}>
            <Button type="button" color="success" onClick={onBuildResume}>
              Gerar Resumo
            </Button>
          </Grid>
          <Grid item xs={12} md={8}>
            <Grid container justify="flex-end">
              <Grid item>
                <PeriodType active={type} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <MTable hasSorting loading={isLoading} headers={headers} data={content} />
    </>
  );
}