import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  tableHead: {
    backgroundColor: '#1E5168 !important'
  },
  tableHeadText: {
    color: '#FFFFFF !important',
    textAlign: 'center !important'
  },
  tableRowText: {
    color: '#1E5168',
    textAlign: 'center'
  },
  tableHeadSuperlogica: {
    backgroundColor: '#F5F5F5 !important'
  },
  tableHeadTextSuperlogica: {
    color: '#1E5168 !important',
    textAlign: 'center !important',
    height: '60px !important',
    padding: '0 !important'
  },
  tableRowSuperlogica: {
    backgroundColor: '#F5F5F5 !important'
  }
}));