import React from 'react';
import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';

export default function Subtitle({ children, ...props }) {
  return (
    <Typography
      gutterBottom
      variant="subtitle2"
      color="textSecondary"
      {...props}
    >
      <i>{children}</i>
    </Typography>
  );
}

Subtitle.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired
};