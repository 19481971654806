import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { includes } from 'ramda';

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import Input from '../../../../../../components/Input';
import Loader from '../../../../../../components/Loader';
import Select from '../../../../../../components/Select';
import { convertToOptions } from '../../../../../../helpers/converters';

import useWindowDimensions from '../../../../../../helpers/hooks/useWindowDimensions';
import { useStyles } from './styles';

import { loadRestrictions } from '../store/thunk';

export default function Restrictions({ formikInjectProps }) {
  const dispatch = useDispatch();

  const classes = useStyles();

  const windowSize = useWindowDimensions();
  const fullWidthWeekButton = windowSize.width < 640;

  const userId = useSelector(state => state.profile.userId);
  const establishmentId = useSelector(state => state.businessInfo.establishmentId);
  const { clientId } = useSelector(state => state.clientRegistration.basicInfo);
  const filters = useSelector(state => state.filters.selects);
  const { isLoading } = useSelector(state => state.clientRegistration.restrictions);

  const availableTypePrices = convertToOptions(['typePrice'], filters);

  useEffect(() => {
    (async () => {
      const params = {
        userId,
        establishmentId,
        clientId
      };

      const {
        startTime,
        endTime,
        weekDaysPermission,
        vacancies,
        restrictionTypePrice
      } = await dispatch(loadRestrictions(params));

      const weekDaysPermissionArray = Array.isArray(weekDaysPermission) ?
        weekDaysPermission.map(weekDay => parseInt(weekDay)) : [];

      formikInjectProps.setFieldValue('startTime', checkTime(startTime));
      formikInjectProps.setFieldValue('endTime', checkTime(endTime));
      formikInjectProps.setFieldValue('weekDaysPermission', weekDaysPermissionArray);
      formikInjectProps.setFieldValue('vacancies', vacancies);
      formikInjectProps.setFieldValue('restrictionTypePrice', restrictionTypePrice);
    })();
  }, []);

  const weekDaysPermissionInjectState = formikInjectProps.values.weekDaysPermission || [];

  const days = [
    { label: 'S', weekday: 'Segunda-feira' },
    { label: 'T', weekday: 'Terça-feira' },
    { label: 'Q', weekday: 'Quarta-feira' },
    { label: 'Q', weekday: 'Quinta-feira' },
    { label: 'S', weekday: 'Sexta-feira' },
    { label: 'S', weekday: 'Sábado' },
    { label: 'D', weekday: 'Domingo' }
  ];

  const checkTime = time => {
    if(!time) return;
    const formattedTime = time.replaceAll(':', '');
    return +formattedTime > 0 ? time : null;
  }

  const onSelectDay = (id, setFieldValue) => {
    const weekDaysPermissionControl = includes(id, weekDaysPermissionInjectState)
      ? weekDaysPermissionInjectState.filter(day => day !== id)
      : weekDaysPermissionInjectState.concat(id);

    setFieldValue('weekDaysPermission', weekDaysPermissionControl);

    formikInjectProps.setFieldValue('weekDaysPermission', weekDaysPermissionControl);
  };

  return (
    <Grid container justify="center">
      <Box mt={3} mb={4}>
        <Card>
          <CardHeader
            style={{ backgroundColor: '#1E5168', padding: '8px' }}
            title={
              <Typography gutterBottom variant="h6" className={classes.root}>
                Restrição de uso (Opcional)
              </Typography>
            }
          />
          <Loader isLoading={isLoading}>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <Input
                    name="startTime"
                    type="time"
                    label="Hora inicial"
                    placeholder="00:00"
                    value={formikInjectProps.values.startTime}
                    error={formikInjectProps.errors.startTime}
                    helperText={formikInjectProps.errors.startTime}
                    onChange={e => {
                      formikInjectProps.handleChange(e);
                    }}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Input
                    name="endTime"
                    type="time"
                    label="Hora final"
                    placeholder="00:00"
                    value={formikInjectProps.values.endTime}
                    error={formikInjectProps.errors.endTime}
                    helperText={formikInjectProps.errors.endTime}
                    onChange={e => {
                      formikInjectProps.handleChange(e);
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item md={12} xs={12} className={classes.containerButtons}>
                  <Typography variant="body1" className={classes.markLabel}>
                    Marque os dias que o cliente pode usar
                  </Typography>
                  {days.map((day, index) => {
                    const id = index + 1;
                    return (
                      <Button
                        id={id}
                        key={id}
                        disableElevation
                        fullWidth={fullWidthWeekButton}
                        color="primary"
                        variant={formikInjectProps.values?.weekDaysPermission?.includes(id) ? 'contained' : 'outlined'}
                        active={formikInjectProps.values?.weekDaysPermission?.includes(id)}
                        onClick={() => onSelectDay(id, formikInjectProps.setFieldValue)}
                      >
                        {fullWidthWeekButton ? day.weekday : day.label}
                      </Button>
                    );
                  })}
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item md={12} xs={12}>
                  <Input
                    name="vacancies"
                    type="number"
                    label="Quantidade de vagas disponíveis"
                    placeholder="0"
                    value={formikInjectProps.values.vacancies}
                    error={formikInjectProps.errors.vacancies}
                    helperText={formikInjectProps.errors.vacancies}
                    onChange={e => {
                      formikInjectProps.handleChange(e);
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item md={12} xs={12}>
                  <Typography variant="body1">
                    Qual tabela cobrar caso esteja fora da restrição
                  </Typography>
                  <Select
                    name="restrictionTypePrice"
                    label="Selecione a tabela"
                    options={availableTypePrices}
                    value={formikInjectProps.values.restrictionTypePrice}
                    error={formikInjectProps.errors.restrictionTypePrice}
                    helperText={formikInjectProps.errors.restrictionTypePrice}
                    onChange={e => {
                      formikInjectProps.handleChange(e);
                    }}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Loader>
        </Card>
      </Box>
    </Grid>
  );
}

Restrictions.defaultProps = {
  formikInjectProps: {
    handleChange: () => {},
    setFieldValue: () => {},
    values: {
      startTime: '',
      endTime: '',
      weekDaysPermission: [],
      vacancies: '',
      restrictionTypePrice: '',
    },
    errors: {
      startTime: false,
      endTime: false,
      weekDaysPermission: false,
      vacancies: false,
      restrictionTypePrice: false,
    },
  },
};

Restrictions.propTypes = {
  formikInjectProps: PropTypes.shape(),
};