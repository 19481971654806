import React from 'react';
import moment from 'moment';
import { Grid, Typography } from '@material-ui/core';

export default function PasswordUpdate({ data }) {
  const { description, userName, name, createdAt } = data;

  return(
    <Grid container>
      <Grid xs={12}>
        {description?.includes('Redefinição') ? (
          <Typography>
            <b>{name}</b> redefiniu a sua senha em <b>{moment(createdAt, 'DD/MM/YYYY HH:mm:ss').format('LLL')}</b>.
          </Typography>
        ) : (
          <Typography>
            <b>{userName}</b> alterou a senha do usuário <b>{name}</b> em <b>{moment(createdAt, 'DD/MM/YYYY HH:mm:ss').format('LLL')}</b>.
          </Typography>
        )}
      </Grid>
    </Grid>
  );
}