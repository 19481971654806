import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Typography } from '@material-ui/core';
import { Row } from './Resources';

export default function ActivitiesRegistrationHeader({ activityData }) {
  const getDate = (updatedAt, createdAt) => {
    if(!!updatedAt) {
      return moment(updatedAt).format('DD/MM/YYYY HH:mm:ss');
    }

    return moment(createdAt).format('DD/MM/YYYY HH:mm:ss');
  }

  return(
    <>
      <Row justifyContentStart>
        <Typography>{getDate(activityData?.updatedAt, activityData?.createdAt)}</Typography>
      </Row>
      <Row justifyContentStart>
        <Typography>Responsável:</Typography>
        <Typography>{activityData?.userName}</Typography>
      </Row>
      <Row justifyContentStart>
        <Typography>Observação:</Typography>
        <Typography>{activityData?.description}</Typography>
      </Row>
    </>
  );
}

ActivitiesRegistrationHeader.propTypes = {
  activityData: PropTypes.object.isRequired
}