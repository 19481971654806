import { toast } from 'react-toastify';

import {
  setIsLoading,
  setComissionedPayroll,
  setComissionedPayrollErrors,
  clearComissionedPayrollErrors
} from './actions';

import {
  fetchComissionedPayroll,
  createComissionedPayroll,
  updateComissionedPayroll
} from './services';

export const loadComissionedPayroll = (userId, establishmentId, uuid) => dispatch => {
  dispatch(setIsLoading(true));
  
  return fetchComissionedPayroll(userId, establishmentId, uuid)
    .then(({ data }) => dispatch(setComissionedPayroll(data)))
    .finally(() => dispatch(setIsLoading(false)));
}

export const upsertComissionedPayroll = (userId, establishmentId, uuid, params, history) => dispatch => {
  dispatch(setIsLoading(true));

  if(!uuid) {
    return createComissionedPayroll(userId, establishmentId, params)
      .then(({ data }) => {
        dispatch(setComissionedPayroll(data));
        toast.success('Comissionado criado com sucesso.');
        history.push('/comissionamento');
      })
      .catch(error => {
        if(!error.errors) {
          dispatch(clearComissionedPayrollErrors());
          toast.error('Houve um erro ao criar o comissionado.');
        }

        dispatch(setComissionedPayrollErrors(error.errors));
      })
      .finally(() => dispatch(setIsLoading(false)));
  }

  return updateComissionedPayroll(userId, establishmentId, uuid, params)
    .then(({ data }) => {
      dispatch(setComissionedPayroll(data));
      toast.success('Comissionado atualizado com sucesso.');
    })
    .catch(error => {
      if(!error.errors) {
        dispatch(clearComissionedPayrollErrors());
        toast.error('Houve um erro ao atualizar o comissionado.');
      }

      dispatch(setComissionedPayrollErrors(error.errors));
    })
    .finally(() => dispatch(setIsLoading(false)));
}