import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'formik';

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Select from '../../../../../../components/Select';

import { loadClient } from '../../BasicInfo/store/thunk';

import { convertToOptions } from '../../../../../../helpers/converters';

import { useStyles } from './styles';

export default function LinkServices({ formikInjectProps }) {
  const dispatch = useDispatch();

  const classes = useStyles();

  const filters = useSelector(state => state.filters.selects);

  const availableServices = convertToOptions(['services'], filters)
    .map(service => {
      return {
        value: service?.serviceId,
        label: service?.description
      }
    })
    .filter(service => !!service?.value)
    .concat({
      value: '0',
      label: '--'
    })
    .reverse();

  const userId = useSelector(state => state.profile.userId);
  const establishmentId = useSelector(state => state.businessInfo.establishmentId);
  const { clientId } = useSelector(state => state.clientRegistration.basicInfo);

  useEffect(() => {
    (async () => {
      const params = {
        userId,
        establishmentId,
        clientId
      }

      const { servicesLink } = await dispatch(loadClient(params));

      formikInjectProps.setFieldValue(
        'servicesLink',
        Array.isArray(servicesLink) ? servicesLink : [servicesLink]
      );
    })();
  }, []);

  return (
    <Grid item md={12} xs={12}>
      <Card>
        <CardHeader
          style={{ backgroundColor: '#1E5168', padding: '8px' }}
          title={
            <Typography gutterBottom variant="h6" className={classes.root}>
              Vincular serviços
            </Typography>
          }
        />
        <CardContent>
          <Form>
            <Grid container spacing={3} direction="row" alignItems="stretch">
              <Grid item md={8} xs={12}>
                <Select
                  multiple
                  name="servicesLink"
                  label="Vincular serviços"
                  options={availableServices}
                  value={formikInjectProps.values.servicesLink}
                  error={formikInjectProps.errors.servicesLink}
                  helperText={formikInjectProps.errors.servicesLink}
                  onChange={e => {
                    formikInjectProps.setFieldValue(
                      'servicesLink',
                      e.target.value
                    );
                  }}
                />
              </Grid>
            </Grid>
          </Form>
        </CardContent>
      </Card>
    </Grid>
  );
}

LinkServices.defaultProps = {
  formikInjectProps: {
    handleChange: () => {},
    setFieldValue: () => {},
    values: {
      name: '',
      email: ''
    },
    errors: {
      name: false,
      email: false
    }
  }
}

LinkServices.propTypes = {
  formikInjectProps: PropTypes.shape()
};