import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { toast } from 'react-toastify';

import { Grid, Paper, Typography, TextField } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import MTable from '../../../components/MTable';
import Select from 'components/Select';
import Button from 'components/Button';
import Filter from 'components/Filter/components';
import Modal from '../../../components/Modal';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

import {
  loadNotificationMessage,
  loadNotifications,
  loadNotificationSituations,
  updateNotificationSituationId
} from '../store/thunk';

import { formatWhatsAppMessageLineBreak, formatToNumbersOnly } from 'helpers/formatters';
import { convertDateToDB } from 'helpers/converters';

import { clearSearch } from 'components/Filter/store/actions';

import { useStyles } from './styles';

const headers = [
  {
    title: 'Data',
    field: 'previsionDateTime',
    headerStyle: {
      width: 80,
      minWidth: 80
    }
  },
  {
    title: 'Categoria',
    field: 'notificationCategoryName',
    headerStyle: {
      width: 80,
      minWidth: 80
    }
  },
  {
    title: 'Descrição',
    field: 'description',
    headerStyle: {
      width: 80,
      minWidth: 80
    }
  },
  {
    title: 'Situação',
    field: 'notificationSituationName',
    headerStyle: {
      width: 80,
      minWidth: 80
    }
  }
];

export default function NotificationsList() {
  const classes = useStyles();

  const dispatch = useDispatch();

  const { userId } = useSelector(state => state.profile);
  const { establishmentId } = useSelector(state => state.businessInfo);
  const { qp } = useSelector(state => state.filters);
  const isLoading = useSelector(state => state.notifications.isLoading);
  const total = useSelector(state => state.notifications.total);

  const currentNotificationFilterId = useSelector(state => state.notifications.currentNotificationFilterId) || 2;
  const currentPrevisionFilterId = useSelector(state => state.notifications.currentPrevisionFilterId) || 2;

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isNoPhoneModalOpen, setIsNoPhoneModalOpen] = useState(false);

  const [currentNotification, setCurrentNotification] = useState({});
  const [notificationSituationId, setNotificationSituationId] = useState(null);
  const [previousNotificationSituationId, setPreviousNotificationSituationId] = useState(null);
  const [notificationPrevisionDateTime, setNotificationPrevisionDateTime] = useState(null);
  const [delayDaysQnt, setDelayDaysQnt] = useState(null);

  const [notificationsPage, setNotificationsPage] = useState(1);
  const notificationsPageSize = 10;
  const notificationsPageCount = Math.ceil(total / notificationsPageSize);

  const setNotificationSituationName = situation => {
    if(situation == 'Em Aberto') {
      return '1° mensagem';
    }

    if(situation == 'Adiado') {
      return '2° mensagem';
    }

    return situation;
  }

  const notificationSituations = useSelector(state => state.notifications.situations).map(situation => {
    return {
      label: setNotificationSituationName(situation.name),
      value: situation.id
    }
  });

  const filters = [
    {
      advanced: false,
      id: 'notificationSituationId',
      label: 'Situação do lembrete',
      type: 'select',
      options: [
        {
          value: '0',
          label: '1° e 2° mensagem',
          disabled: true,
          selected: true
        },
        ...notificationSituations
      ],
      defaultValue: '0'
    },
    {
      advanced: false,
      id: 'previsionSituationId',
      label: 'Situação do vencimento',
      type: 'select',
      options: [
        {
          value: '0',
          label: 'Em aberto e atrasado',
          disabled: true,
          selected: true
        },
        {
          value: 1,
          label: 'Atrasado'
        },
        {
          value: 2,
          label: 'Em aberto'
        }
      ],
      defaultValue: '0'
    }
  ];

  const handleNotificationActionDateTimeColor = date => {
    const today = moment();
    const yesterday = moment().subtract(1, 'days').format('DD/MM/YYYY');

    const formattedDate = moment(date).format('DD/MM/YYYY');

    if(yesterday == formattedDate) {
      return <Typography className={classes.yellow}>{formattedDate}</Typography>;
    }

    if(moment(today).isSame(date, 'day')) {
      return <Typography className={classes.green}>{formattedDate}</Typography>;
    }

    if(moment(today).isAfter(date)) {
      return <Typography className={classes.red}>{formattedDate}</Typography>;
    }

    if(moment(today).isBefore(date)) {
      return <Typography className={classes.blue}>{formattedDate}</Typography>;
    }
  }

  const notifications = useSelector(state => state.notifications.content).map(notification => {
    return {
      ...notification,
      previsionDateTime: handleNotificationActionDateTimeColor(notification.previsionDateTime),
      notificationCategoryName: notification.notificationCategoryName,
      description: notification.description,
      notificationSituationName: setNotificationSituationName(notification.notificationSituationName)
    }
  });

  const handleEditNotification = item => {
    setDelayDaysQnt(null);
    setCurrentNotification(item);
    setNotificationSituationId(item.notificationSituationId);
    setNotificationPrevisionDateTime(item.previsionDateTime.props.children);
    setIsEditModalOpen(true);
  }

  const handleSendWhatsAppNotification = async notificationId => {
    const params = {
      userId,
      establishmentId,
      notificationId
    }

    const { content, phones } = await loadNotificationMessage(params);

    const doClientHavePhone = phones.length;
    const phone = phones[0];

    const isPhoneNull =
      phone == '' || phone == null || phone == undefined;

    if(isPhoneNull) {
      return toast.error('Cliente sem telefone cadastrado.');
    }

    const formattedContent = formatWhatsAppMessageLineBreak(content);
    const formattedPhone = formatToNumbersOnly(phone);

    const message = `https://wa.me/+55${formattedPhone}/?text=${formattedContent}`;

    return doClientHavePhone
      ? window.open(message, '_blank')?.focus()
      : setIsNoPhoneModalOpen(true);
  }

  const handleSaveNotification = () => {
    const canSituationKeepFilter =
      previousNotificationSituationId == 3 || previousNotificationSituationId == 4;

    const userSelectedSituation =
      notificationSituations.find(situation => situation.value == notificationSituationId);

    const params = {
      userId,
      establishmentId,
      id: currentNotification.id,
      notificationSituationId: currentNotificationFilterId,
      previsionSituationId: currentPrevisionFilterId,
      notificationSituationId,
      actionReason: delayDaysQnt
        ? `O lembrete foi adiado em ${delayDaysQnt} dia(s)`
        : `Situação do lembrete alterada para: ${userSelectedSituation.label}`,
      qp: canSituationKeepFilter
        ? `&notificationSituationId=${previousNotificationSituationId}&previsionSituationId=${currentPrevisionFilterId}`
        : '&order=asc',
      page: notificationsPage,
      limit: notificationsPageSize
    }

    if(userSelectedSituation?.value == 2) {
      const formattedPrevisionDateTime =
        moment(notificationPrevisionDateTime, 'DD/MM/YYYY')
        .add(delayDaysQnt, 'days')
        .format('DD/MM/YYYY');

      params['previsionDateTime'] = convertDateToDB(formattedPrevisionDateTime);
    }

    if(!canSituationKeepFilter) {
      dispatch(clearSearch());
    }

    return(
      dispatch(updateNotificationSituationId(params)),
      setIsEditModalOpen(false)
    );
  }

  const handleLoadNotifications = ({ page, qp }) => {
    const params = {
      userId,
      establishmentId,
      page,
      limit: notificationsPageSize,
      qp: qp == '' ? '&order=asc' : qp,
      notificationSituationId: currentNotificationFilterId,
      previsionSituationId: currentPrevisionFilterId
    }

    return dispatch(loadNotifications(params));
  }

  useEffect(() => {
    if(establishmentId) {
      handleLoadNotifications({ page: notificationsPage, qp });
    }
  }, [notificationsPage]);

  useEffect(() => {
    const params = {
      userId,
      establishmentId,
      notificationSituationId: currentNotificationFilterId,
      previsionSituationId: currentPrevisionFilterId,
      qp: '&order=asc'
    }

    handleLoadNotifications({ page: 1, qp });
    return dispatch(loadNotificationSituations(params));
  }, [establishmentId]);

  useEffect(() => {
    setPreviousNotificationSituationId(notificationSituationId);
  }, [isEditModalOpen]);

  return(
    <Paper>
      <Filter
        xs={12}
        md={3}
        filter
        filters={filters}
        handlePageRequest={handleLoadNotifications}
        hasLinkButton='/configuracao-de-lembrete'
        linkButtonText='Configuração de Lembrete'
      />
      <MTable
        loading={isLoading}
        data={notifications}
        headers={headers}
        actions={[
          {
            icon: () => (
              <FontAwesomeIcon
                icon={faWhatsapp}
                color='#25D366'
                size='xs'
              />
            ),
            tooltip: 'Encaminhar lembrete por WhatsApp',
            onClick: (event, rowData) => handleSendWhatsAppNotification(rowData?.id)
          },
          {
            icon: () => (
              <FontAwesomeIcon
                icon={faPen}
                color='#2CA9D'
                size='xs'
              />
            ),
            tooltip: 'Editar lembrete',
            onClick: (event, rowData) => handleEditNotification(rowData)
          }
        ]}
      />
      <Grid
        container
        xs={12}
        style={{ padding: 10, display: 'flex', justifyContent: 'flex-end' }}
      >
        <Pagination
          color="primary"
          variant="outlined"
          shape="rounded"
          count={notificationsPageCount}
          page={notificationsPage}
          onChange={(_, page) => setNotificationsPage(page)}
        />
      </Grid>
      {isEditModalOpen && (
        <Modal
          open={isEditModalOpen}
          onClose={() => setIsEditModalOpen(false)}
          title='O que deseja fazer?'
        >
          <Select
            options={notificationSituations}
            value={notificationSituationId}
            onChange={event => setNotificationSituationId(event.target.value)}
          />
          {notificationSituationId === 2 && (
            <Typography
              className={classes.delayDaysQntText}
            >
              Enviar próxima mensagem em
              <TextField
                className={classes.delayDaysQntTextField}
                variant='outlined'
                value={delayDaysQnt}
                onChange={event => setDelayDaysQnt(event.target.value)}
              />
              dias
            </Typography>
          )}
          <Grid
            container
            xs={12}
            justifyContent='flex-end'
          >
            <Button
              color='success'
              onClick={handleSaveNotification}
              disabled={
                (notificationSituationId === 2) &&
                (delayDaysQnt === null || delayDaysQnt === '' || delayDaysQnt == 0)
              }
            >
              Salvar
            </Button>
          </Grid>
        </Modal>
      )}
      {isNoPhoneModalOpen && (
        <Modal
          open={isNoPhoneModalOpen}
          onClose={() => setIsNoPhoneModalOpen(false)}
          title='Cliente sem telefone'
        >
          <Typography className={classes.blue}>
            O cliente precisa ter número de celular cadastrado para enviar número de WhatsApp
          </Typography>
        </Modal>
      )}
    </Paper>
  );
}