import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Grid, FormControlLabel, Checkbox, TextField, Typography, Badge } from '@material-ui/core';
import Modal from 'components/Modal';
import Loader from 'components/Loader';
import Input from 'components/Input';
import Select from 'components/Select';
import Button from 'components/Button';
import ExportPDF from 'components/ExportPDF';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import { convertFormattedMoneyToNumber, convertToReal } from 'helpers/converters';
import useWindowDimensions from 'helpers/hooks/useWindowDimensions';

import { loadComissionedFilters, loadComissionedList } from 'pages/ComissionedList/store/thunk';
import { loadTransaction, loadResume } from 'pages/Transactions/store/thunk';

import {
  loadPayrollExport,
  loadPayrolls,
  upsertPayroll,
  payPayroll,
  undoPayPayroll,
  payPartialPayroll,
  undoPayPartialPayroll
} from '../../pages/ComissionedPayrolls/store/thunk';

const StyledBadge = withStyles(() => ({
  badge: {
    right: -50,
    top: 14,
    padding: '14px 10px',
    fontSize: '15px',
    color: '#FFFFFF',
    borderRadius: '5px'
  }
}))(Badge);

const useStyles = makeStyles({
  success: { backgroundColor: '#4CAF50' },
  warning: { backgroundColor: '#FF9800' }
});

export default function Payroll({ isPayrollModalOpen, setIsPayrollModalOpen, pageOrigin }) {
  const dispatch = useDispatch();

  const classes = useStyles();

  const { width } = useWindowDimensions();
  const isMobile = width < 960;

  const { userId } = useSelector(state => state.profile);
  const { establishmentId } = useSelector(state => state.businessInfo);
  const { submit, employeeUuid, isLoadingSinglePayroll } = useSelector(state => state.comissionedPayrolls);
  const { startDate, endDate } = useSelector(state => state.dateFilter);
  const { qp } = useSelector(state => state.filters);
  const page = useSelector(state => state.pagination.activePage);
  const pageSize = useSelector(state => state.pagination.size);

  const payrollCategories = useSelector(state => state.comissionedList.filters?.categories)?.option?.map(category => {
    return {
      label: category?.name,
      value: category?.id
    }
  });

  const [isPaymentPartial, setIsPaymentPartial] = useState(false);
  const [isPaymentIntegral, setIsPaymentIntegral] = useState(false);
  const [confirmPayment, setConfirmPayment] = useState(false);
  const [confirmUndoPayment, setConfirmUndoPayment] = useState(false);
  const [isPartialPaymentRequest, setIsPartialPaymentRequest] = useState(false);
  const [currentPartialPaymentUuid, setCurrentPartialPaymentUuid] = useState(null);

  const [payrollData, setPayrollData] = useState({
    name: null,
    employeeRoleName: null,
    dueDate: moment(),
    paymentDateTime: moment(),
    fixedAmount: null,
    amount: null,
    paidAmount: null,
    partialPayment: null,
    payrollCategoryId: null,
    account: null,
    situationId: null,
    transaction: {},
    partialPaymentList: []
  });

  const [payrollUndoPaymentData, setPayrollUndoPaymentData] = useState({
    reason: null,
    userPassword: null
  });

  const paymentType = +payrollData.partialPaymentList?.length > 0 ? 'partial' : 'integral';
  const isPayrollOpen = +payrollData.situationId == 1;
  const isPayrollPaid = +payrollData.situationId == 2;

  const isValueLeftToPay = (convertFormattedMoneyToNumber(submit?.amount) - convertFormattedMoneyToNumber(submit?.paidAmount)) >= 0;
  const isPartialPaymentAndPaid = payrollData.partialPaymentList?.length > 0 && isPayrollPaid;
  const isPayrollClosed = (!isPaymentIntegral && !isPaymentPartial && !isPayrollPaid && !(payrollData.partialPaymentList?.length > 0));

  useEffect(() => {
    if(userId) {
      dispatch(loadComissionedFilters({ userId, establishmentId }));
    }
  }, [userId]);

  useEffect(() => {
    setPayrollData({
      ...submit,
      fixedAmount: submit?.amount,
      amount: convertToReal(parseFloat(convertFormattedMoneyToNumber(submit?.amount) - convertFormattedMoneyToNumber(submit?.paidAmount)).toFixed(2)),
      paymentDateTime: !isPayrollPaid
        ? moment().format('YYYY-MM-DD')
        : submit?.paymentDateTime
    });
  }, [submit]);

  useEffect(() => {
    if(isPartialPaymentRequest) {
      const currentPartialPaymentTotal = convertFormattedMoneyToNumber(submit?.amount) - payrollData.partialPaymentList?.reduce((total, partialPayment) => +total + convertFormattedMoneyToNumber(partialPayment?.amount), 0);

      setPayrollData({
        ...payrollData,
        amount: convertToReal(currentPartialPaymentTotal)
      });
    }
  }, [payrollData.partialPaymentList]);

  const loadTransactionsAndResume = () => {
    dispatch(
      loadTransaction({
        qp,
        page: page || 1,
        pageSize: pageSize || 10,
        extraProps: { userId, establishmentId, startDate, endDate }
      })
    );

    dispatch(loadResume(userId, establishmentId, { startDate, endDate, qp }));
  }

  const handlePayment = payrollUuid => {
    const paymentDate = moment(payrollData.paymentDateTime).format('YYYY-MM-DD');
    const paymentTime = moment().format('HH:mm:ss');

    const params = {
      paymentDateTime: `${paymentDate} ${paymentTime}`,
      paidAmount: convertFormattedMoneyToNumber(payrollData.paidAmount)
    }

    setConfirmPayment(false);

    dispatch(payPayroll(userId, establishmentId, payrollUuid, params))
      .then(() => {
        const loadPayrollsParams = {
          userId,
          establishmentId,
          pg: 1,
          employeeId: employeeUuid,
          limit: '',
          orderBy: 'dueDate',
          sort: 'asc'
        }

        setIsPayrollModalOpen(false);

        if(pageOrigin == '/funcionarios') {
          return dispatch(loadComissionedList({ userId, establishmentId, page: 1, limit: 10, qp: '' }));
        }

        dispatch(loadPayrolls(loadPayrollsParams));
      });
  }

  const handleUndoPayment = payrollUuid => {
    dispatch(undoPayPayroll(userId, establishmentId, payrollUuid, payrollUndoPaymentData))
      .then(() => {
        const loadPayrollsParams = {
          userId,
          establishmentId,
          pg: 1,
          employeeId: employeeUuid,
          limit: '',
          orderBy: 'dueDate',
          sort: 'asc'
        }

        dispatch(loadComissionedList({ userId, establishmentId, page: 1, limit: 10, qp: '' }));
        dispatch(loadPayrolls(loadPayrollsParams));

        if(pageOrigin == 'transactions') {
          loadTransactionsAndResume();
        }

        setIsPayrollModalOpen(false);
        setConfirmUndoPayment(false);
      });
  }

  const handlePartialPayment = payrollUuid => {
    const paymentDate = moment(payrollData.paymentDateTime).format('YYYY-MM-DD');
    const paymentTime = moment().format('HH:mm:ss');

    const params = {
      paymentDateTime: `${paymentDate} ${paymentTime}`,
      amount: +convertFormattedMoneyToNumber(payrollData.amount),
      description: payrollData.description
    }

    setConfirmPayment(false);

    dispatch(payPartialPayroll(userId, establishmentId, payrollUuid, params))
      .then(() => setIsPartialPaymentRequest(true));
  }

  const handleUndoPartialPayment = (payrollUuid, uuid) => {
    dispatch(undoPayPartialPayroll(userId, establishmentId, payrollUuid, uuid, payrollUndoPaymentData))
      .then(() => {
        setConfirmUndoPayment(false);
        setIsPartialPaymentRequest(true);

        if(pageOrigin == 'transactions') {
          loadTransactionsAndResume();
        }
      });
  }

  const handleSavePayroll = payrollUuid => {
    const params = {
      amount: +convertFormattedMoneyToNumber(payrollData.fixedAmount),
      dueDate: moment(payrollData?.dueDate).format('YYYY-MM-DD')
    }

    dispatch(upsertPayroll(userId, establishmentId, payrollUuid, params))
      .then(() => {
        dispatch(loadComissionedList({ userId, establishmentId, page: 1, limit: 10, qp: '' }));
        setIsPayrollModalOpen(false);
      });
  }

  const badgeSituation = () => {
    if(isPayrollPaid) {
      return 'Paga';
    }

    if(isPayrollOpen) {
      return 'Aberta';
    }

    return 'Aberta';
  }

  const badgeColor = () => {
    if(isPayrollPaid) {
      return classes.success;
    }

    return classes.warning;
  }

  const labelStatus = () => {
    if(isMobile) {
      return(
        <div>
          <Typography
            variant='h6'
            style={{ marginLeft: 8 }}
          >
            Folha de pagamento
          </Typography>
          <StyledBadge
            badgeContent={badgeSituation()}
            classes={{ badge: badgeColor() }}
            style={{ marginBottom: 20 }}
          />
        </div>
      );
    }

    if(!isMobile) {
      return(
        <div>
          <StyledBadge
            badgeContent={badgeSituation()}
            classes={{ badge: badgeColor() }}
          >
            Folha de pagamento
          </StyledBadge>
        </div>
      );
    }
  }

  return(
    <Modal
      id='payroll'
      title={labelStatus()}
      scroll='body'
      maxWidth='md'
      open={isPayrollModalOpen}
      onClose={() => {
        if((payrollData?.partialPaymentList?.length > 0) && isPartialPaymentRequest) {
          const loadPayrollsParams = {
            userId,
            establishmentId,
            pg: 1,
            employeeId: employeeUuid,
            limit: '',
            orderBy: 'dueDate',
            sort: 'asc'
          }

          if(!!employeeUuid) {
            dispatch(loadPayrolls(loadPayrollsParams));
          }

          dispatch(loadComissionedList({ userId, establishmentId, page: 1, limit: 10, qp: '' }));
      }

        setIsPayrollModalOpen(false);
      }}
    >
      {confirmPayment && (
        <Modal
          id='confirmPayment'
          title='Confirmar pagamento'
          scroll='body'
          maxWidth='md'
          open={confirmPayment}
          onClose={() => setConfirmPayment(false)}
        >
          <Grid container>
            <Grid item xs={12} style={{ display: 'flex', flexDirection: 'column' }}>
              <Typography variant='h6' color='primary'>
                Data de pagamento: {moment(payrollData.paymentDateTime, 'YYYY-MM-DD').format('DD/MM/YYYY')}
              </Typography>
              {isPaymentIntegral ? (
                <Typography variant='h6' color='primary'>
                  Valor: {convertToReal(convertFormattedMoneyToNumber(payrollData.paidAmount))}
                </Typography>
              ) : (
                <Typography variant='h6' color='primary'>
                  Valor: {convertToReal(convertFormattedMoneyToNumber(payrollData.amount))}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} style={{ display: 'flex', flexDirection: 'row', gap: 10, marginTop: 10 }}>
              <Button
                type='button'
                color='primary'
                onClick={() => setConfirmPayment(false)}
              >
                Cancelar
              </Button>
              <Button
                type='button'
                color='success'
                onClick={() => {
                  (isPaymentPartial || paymentType == 'partial')
                    ? handlePartialPayment(submit?.uuid)
                    : handlePayment(submit?.uuid)
                }}
              >
                Sim
              </Button>
            </Grid>
          </Grid>
        </Modal>
      )}
      <Loader isLoading={isLoadingSinglePayroll}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4} spacing={2}>
            <Input
              disabled
              name='name'
              label='Funcionário'
              value={payrollData.name || ''}
              onChange={event => setPayrollData({ ...payrollData, name: event.target.value })}
            />
          </Grid>
          <Grid item xs={12} md={4} spacing={2}>
            <Input
              disabled
              name='employeeRoleName'
              label='Cargo'
              value={payrollData.employeeRoleName || ''}
              onChange={event => setPayrollData({ ...payrollData, employeeRoleName: event.target.value })}
            />
          </Grid>
          <Grid item xs={12} md={4} spacing={2}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                disabled={isPayrollPaid}
                autoFocus={false}
                label='Data de vencimento'
                inputFormat='DD/MM/YYYY'
                value={payrollData.dueDate}
                onChange={date => setPayrollData({ ...payrollData, dueDate: date })}
                renderInput={params => (
                  <TextField
                    {...params}
                    fullWidth
                    name='dueDate'
                    variant='outlined'
                    inputProps={{
                      ...params.inputProps
                    }}
                    SelectProps={{
                      MenuProps: {
                        disableEnforceFocus: true
                      }
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} spacing={2}>
            <Input
              disabled={!isPayrollClosed}
              name='amount'
              label='Valor'
              type='money'
              value={convertToReal(convertFormattedMoneyToNumber(payrollData?.fixedAmount))}
              onChange={event => setPayrollData({ ...payrollData, fixedAmount: event.target.value })}
            />
          </Grid>
          <Grid item xs={12} md={6} spacing={2}>
            <Select
              disabled
              name='payrollCategoryId'
              label='Categoria da despesa'
              options={payrollCategories}
              value={+payrollData.payrollCategoryId}
              onChange={event => setPayrollData({ ...payrollData, payrollCategoryId: event.target.value })}
            />
          </Grid>
        </Grid>
        <Grid container style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
          <Grid item xs={12}>
            <hr />
          </Grid>
          <Grid item md={isPayrollOpen ? 9 : 8} xs={12}>
            <FormControlLabel
              disabled={isPayrollPaid || payrollData.partialPaymentList?.length > 0}
              label='Pagar Integral'
              control={
                <Checkbox
                  name='payIntegral'
                  type='checkbox'
                  checked={isPaymentIntegral || (isPayrollPaid && paymentType == 'integral')}
                  onChange={event => {
                    setPayrollData({ ...payrollData, paidAmount: payrollData.amount });
                    setIsPaymentIntegral(event.target.checked);
                    setIsPaymentPartial(false);
                  }}
                />
              }
            />
            <FormControlLabel
              disabled={isPayrollPaid || payrollData.partialPaymentList?.length > 0}
              label='Pagar Parcial'
              control={
                <Checkbox
                  name='payPartial'
                  type='checkbox'
                  checked={isPaymentPartial || (isPayrollPaid && paymentType == 'partial') || payrollData.partialPaymentList?.length > 0}
                  onChange={event => {
                    setIsPaymentPartial(event.target.checked);
                    setIsPaymentIntegral(false);
                  }}
                />
              }
            />
          </Grid>
          {isPayrollPaid && (!!payrollData.transaction?.userName && !!payrollData.transaction?.paymentDateTime) && (
            <Grid item md={isPayrollOpen ? 3 : 4} xs={12} style={{ display: 'flex', flexDirection: 'row', justifyContent: isMobile ? 'flex-start' : 'flex-end' }}>
              <p>Pago por {payrollData.transaction?.userName} ás {moment(payrollData.transaction?.paymentDateTime).format('DD/MM/YYYY')} {payrollData.transaction?.paymentDateTime?.split(' ')[1]}</p>
            </Grid>
          )}
        </Grid>
        {(isPaymentIntegral || (isPayrollPaid && paymentType == 'integral')) && (
          <Grid container spacing={2}>
            <Grid item md={6} xs={12}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  disabled={isPayrollPaid}
                  autoFocus={false}
                  label='Data de pagamento'
                  inputFormat='DD/MM/YYYY'
                  value={payrollData.paymentDateTime}
                  onChange={date => setPayrollData({ ...payrollData, paymentDateTime: date })}
                  renderInput={params => (
                    <TextField
                      {...params}
                      fullWidth
                      name='paymentDateTime'
                      variant='outlined'
                      inputProps={{
                        ...params.inputProps
                      }}
                      SelectProps={{
                        MenuProps: {
                          disableEnforceFocus: true
                        }
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item md={6} xs={12}>
              <Input
                disabled={isPayrollPaid}
                name='amount'
                label='Valor pago'
                type='money'
                value={convertToReal(convertFormattedMoneyToNumber(payrollData.paidAmount))}
                onChange={event => setPayrollData({ ...payrollData, paidAmount: event.target.value })}
              />
            </Grid>
          </Grid>
        )}
        {(isPaymentPartial || (isPayrollPaid && paymentType == 'partial') || payrollData.partialPaymentList?.length > 0) && (
          <>
            {payrollData?.partialPaymentList?.length > 0 && (
              <>
                {payrollData?.partialPaymentList?.map(partialPayment => (
                  <Grid container spacing={2} style={{ display: 'flex', alignItems: 'center', marginBottom: isMobile ? 15 : 0 }}>
                    <Grid item md={3} xs={12}>
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DatePicker
                          disabled
                          autoFocus={false}
                          label='Data de pagamento'
                          inputFormat='DD/MM/YYYY'
                          value={partialPayment.paymentDateTime || ""}
                          renderInput={params => (
                            <TextField
                              {...params}
                              fullWidth
                              name='paymentDateTime'
                              variant='outlined'
                              inputProps={{
                                ...params.inputProps
                              }}
                              SelectProps={{
                                MenuProps: {
                                  disableEnforceFocus: true
                                }
                              }}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <Input
                        disabled
                        name='amount'
                        label='Valor pago'
                        type='money'
                        value={convertToReal(convertFormattedMoneyToNumber(partialPayment.amount)) || ""}
                      />
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <Input
                        disabled
                        name='userName'
                        label='Pago por'
                        type='text'
                        value={partialPayment?.userName || ""}
                      />
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <Button
                        type='button'
                        color='error'
                        onClick={() => {
                          setCurrentPartialPaymentUuid(partialPayment?.uuid);
                          setConfirmUndoPayment(true);
                        }}
                      >
                        Desfazer
                        <FontAwesomeIcon
                          style={{ marginLeft: 10 }}
                          size="lg"
                          color="#FFFFFF"
                          icon={faTrash}
                        />
                      </Button>
                    </Grid>
                  </Grid>
                ))}
              </>
            )}
            {!isPartialPaymentAndPaid && (
              <Grid container spacing={2} style={{ display: 'flex', alignItems: 'center' }}>
                <Grid item md={3} xs={12}>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                      disabled={isPayrollPaid}
                      autoFocus={false}
                      label='Data de pagamento'
                      inputFormat='DD/MM/YYYY'
                      value={payrollData.paymentDateTime}
                      onChange={date => setPayrollData({ ...payrollData, paymentDateTime: date })}
                      renderInput={params => (
                        <TextField
                          {...params}
                          fullWidth
                          name='paymentDateTime'
                          variant='outlined'
                          inputProps={{
                            ...params.inputProps
                          }}
                          SelectProps={{
                            MenuProps: {
                              disableEnforceFocus: true
                            }
                          }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item md={3} xs={12}>
                  <Input
                    disabled={isPayrollPaid}
                    name='amount'
                    label='Valor a ser pago'
                    type='money'
                    value={convertToReal(convertFormattedMoneyToNumber(payrollData.amount))}
                    onChange={event => setPayrollData({ ...payrollData, amount: event.target.value })}
                  />
                </Grid>
                {isValueLeftToPay && (
                  <Grid item md={3} xs={12}>
                    <Button
                      type='button'
                      color='success'
                      onClick={() => setConfirmPayment(true)}
                    >
                      Pagar Parcela
                      <FontAwesomeIcon
                        style={{ marginLeft: 10 }}
                        size="lg"
                        color="#FFFFFF"
                        icon={faPlus}
                      />
                    </Button>
                  </Grid>
                )}
              </Grid>
            )}
          </>
        )}
        {confirmUndoPayment && (
          <Grid container spacing={2}>
            <Grid item md={6} xs={12}>
              <Input
                name='reason'
                label='Motivo do cancelamento'
                type='text'
                value={payrollUndoPaymentData.reason}
                onChange={event => setPayrollUndoPaymentData({ ...payrollUndoPaymentData, reason: event.target.value })}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Input
                name='userPassword'
                label='Confirme sua senha'
                type='password'
                value={payrollUndoPaymentData.userPassword}
                onChange={event => setPayrollUndoPaymentData({ ...payrollUndoPaymentData, userPassword: event.target.value })}
              />
            </Grid>
            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                style={{ marginRight: 10 }}
                type='button'
                color='error'
                onClick={() => setConfirmUndoPayment(false)}
              >
                Cancelar
              </Button>
              <Button
                type='button'
                color='success'
                onClick={() => {
                  (isPaymentPartial || paymentType == 'partial')
                    ? handleUndoPartialPayment(submit?.uuid, currentPartialPaymentUuid)
                    : handleUndoPayment(submit?.uuid);
                }}
              >
                Confirmar
              </Button>
            </Grid>
          </Grid>
        )}
        {!confirmUndoPayment && (
          <Grid container spacing={2}>
            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <ExportPDF
                style={{ marginRight: 10 }}
                type='button'
                exportType='receiptPayrollExtract'
                color='primary'
                fileName='folha-de-pagamento'
                requestData={() => dispatch(loadPayrollExport(userId, establishmentId, submit?.uuid))}
              >
                Recibo
              </ExportPDF>
              {isPayrollClosed && (
                <Button
                  style={{ marginRight: 10 }}
                  type='submit'
                  color='success'
                  onClick={() => handleSavePayroll(submit?.uuid)}
                >
                  Salvar
                </Button>
              )}
              {(isPaymentIntegral && !isPayrollPaid) && (
                <Button
                  type='submit'
                  color='success'
                  onClick={() => setConfirmPayment(true)}
                >
                  Pagar
                </Button>
              )}
              {(paymentType != 'partial' && isPayrollPaid) && (
                <Button
                  type='submit'
                  color='error'
                  onClick={() => setConfirmUndoPayment(true)}
                >
                  Desfazer Pagamento
                </Button>
              )}
            </Grid>
          </Grid>
        )}
      </Loader>
    </Modal>
  );
}