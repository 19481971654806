import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faUser } from '@fortawesome/free-solid-svg-icons';

import Paper from '@material-ui/core/Paper';
import { Grid } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import MTable from '../../../components/MTable';
import Filter from '../../../components/Filter/components';
import FilterMobile from '../../../components/FilterMobile/components';

import { setEmployeeId } from '../../EmployeesRegistration/store/actions';
import { loadEmployees } from '../store/thunk';
import { loadProfile } from '../../Profile/store/thunk';
import { loadFilters } from '../../../components/Filter/store/thunk';
import { formatFilters } from '../../../helpers/formatters';

import { RESTRICTION_PAGE } from '../../../helpers/restrictions';

const headers = [
  { title: 'UserID', field: 'userId', sorting: false },
  { title: 'Nome', field: 'name', sorting: false },
  { title: 'Telefone', field: 'phone', sorting: false },
  { title: 'E-mail', field: 'email', sorting: false },
  { title: 'Cargo', field: 'profileName', sorting: false },
  { title: 'Situação', field: 'status', sorting: false },
  { title: 'Revenda', field: 'resaleName', sorting: false }
];

const noUserIdHeaders = [
  { title: 'Nome', field: 'name', sorting: false },
  { title: 'Telefone', field: 'phone', sorting: false },
  { title: 'E-mail', field: 'email', sorting: false },
  { title: 'Cargo', field: 'profileName', sorting: false },
  { title: 'Situação', field: 'status', sorting: false }
];

const width = window.innerWidth;
const tableRowWidth = width < 768 ? 120 : 100;

export default function EmployeesList() {
  const dispatch = useDispatch();

  const history = useHistory();

  const {
    userId,
    userRestrictions,
    submit: { profileId }
  } = useSelector(state => state.profile);

  const { establishmentId } = useSelector(state => state.businessInfo);
  const { selects, qp } = useSelector(state => state.filters);
  const { list, isLoading, total } = useSelector(state => state.employeesList);

  const filters = formatFilters(['status', 'profile'], selects);

  const isUserAdmin = +profileId == 1;

  const jumpResales = selects
    ?.find(select => select?.id == 'resale')?.options
    ?.filter(option => !!option?.id);

  const formattedProfiles = filters
    ?.find(filter => filter?.id == 'profile')?.options
    ?.map(option => {
      return option?.value == ''
        ? {
          label: '--',
          value: ''
        } : {
          label: option?.name,
          value: option?.value
        }
    });

  const formattedFilters = filters?.map(filter => {
    return filter?.id == 'profile'
      ? {
        ...filter,
        options: formattedProfiles
      } : {
        ...filter
      }
  });

  const [employeesPage, setEmployeesPage] = useState(1);
  const employeesPageSize = 10;
  const employeesPageCount = Math.ceil(total / employeesPageSize);

  const formattedList = list.map(employee => {
    return {
      ...employee,
      profileName: formattedProfiles?.filter(profile => profile.value == employee.profileId)[0]?.label,
      resaleName: jumpResales?.find(resale => resale?.id == employee?.resaleId)?.label || '-',
      name: +employee?.mainUser == 1 && isUserAdmin
        ? (
          <>
            {employee?.name}
            <FontAwesomeIcon
              icon={faUser}
              color="#2CA9D6"
              size="xs"
              style={{ marginLeft: 10 }}
            />
          </>
        )
        : employee?.name
    }
  });

  const setTableRowColor = row => {
    const resaleColor = jumpResales?.find(resale => resale?.id == row?.resaleId)?.hexColor || '#FFFFFF';

    const style = {
      backgroundColor: '#FFFFFF'
    }

    if(+row?.mainUser == 1 && isUserAdmin) {
      style.backgroundColor = '#DEE7FC';
    }

    if(!!row?.resaleId && isUserAdmin) {
      style.backgroundColor = resaleColor;
    }

    return style;
  }

  useEffect(() => {
    dispatch(loadProfile(userId));
  }, []);

  useEffect(() => {
    if(userRestrictions?.includes(RESTRICTION_PAGE.VIEW_USER)) {
      toast.error('Ação não autorizada.');
      history.push('/visao-geral');
    }
  }, [userRestrictions]);

  useEffect(() => {
    dispatch(loadEmployees({ page: 1, pageSize: employeesPageSize, qp, userId, establishmentId }));
    dispatch(loadFilters(userId, establishmentId));
  }, [userId, establishmentId]);

  useEffect(() => {
    handleLoadEmployees({ page: employeesPage, qp, userId, establishmentId });
  }, [employeesPage]);

  const handleLoadEmployees = ({ page, qp }) => {
    dispatch(loadEmployees({ page, pageSize: employeesPageSize, qp, userId, establishmentId }));
  }

  const handleUpdateEmployees = (event, rowData) => {
    dispatch(setEmployeeId(rowData.employeeId));
    history.push('/editar-usuario');
  }

  const width = window.innerWidth;
  const isMobile = width < 1024;

  return(
    <Paper>
      {isMobile ? (
        <FilterMobile
          filter
          hasSearchTerm
          filters={formattedFilters}
          hasLinkButton={
            !userRestrictions?.includes(RESTRICTION_PAGE.CREATE_USER) &&
            '/novo-usuario'
          }
          linkButtonText="Adicionar usuário"
          handlePageRequest={handleLoadEmployees}
        />
      ) : (
        <Filter
          filter
          hasSearchTerm
          filters={formattedFilters}
          hasLinkButton={
            !userRestrictions?.includes(RESTRICTION_PAGE.CREATE_USER) &&
            '/novo-usuario'
          }
          linkButtonText="Adicionar usuário"
          handlePageRequest={handleLoadEmployees}
        />
      )}
      <MTable
        hasSorting
        loading={isLoading}
        headers={profileId == 1 ? headers : noUserIdHeaders}
        data={formattedList}
        rowStyle={setTableRowColor}
        actions={[
          {
            icon: () => (
              <FontAwesomeIcon
                icon={faPen}
                color="#2CA9D6"
                size="xs"
                style={{
                  opacity: userRestrictions?.includes(RESTRICTION_PAGE.EDIT_USER)
                    ? 0.5
                    : 1
                }}
              />
            ),
            tooltip: 'Editar usuário',
            onClick: handleUpdateEmployees,
            disabled: userRestrictions?.includes(RESTRICTION_PAGE.EDIT_USER),
          }
        ]}
        actionColumnWidth={tableRowWidth}
      />
      <Grid
        container
        xs={12}
        style={{ padding: 10, display: 'flex', justifyContent: 'flex-end' }}
      >
        <Pagination
          color="primary"
          variant="outlined"
          shape="rounded"
          count={employeesPageCount}
          page={employeesPage}
          onChange={(_, page) => setEmployeesPage(page)}
        />
      </Grid>
    </Paper>
  );
}