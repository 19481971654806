import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form } from 'formik';

import Tooltip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinusCircle, faPlus } from '@fortawesome/free-solid-svg-icons';
import MTable from '../../../../../../components/MTable';
import { Pagination as Pagination } from '@material-ui/lab';
import Button from '../../../../../../components/Button';
import ModalConfirmation from '../../../../../../components/ModalConfirmation';
import Input from '../../../../../../components/Input';

import { vehicleValidations, cardValidations } from './validations';
import { loadVehicles, loadCards, registerVehicle, removeVehicle, registerCard, removeCard } from '../store/thunk';
import { toggleToRemoveVehicle, toggleToRemoveCard } from '../store/actions';

import useWindowDimensions from '../../../../../../helpers/hooks/useWindowDimensions';
import { useStyles } from './styles';

const vehiclesHeader = [
  { title: 'Placa', field: 'plate', sortable: false },
  { title: 'Modelo', field: 'vehicleModel', sortable: false },
  { title: 'Cor', field: 'vehicleColor', sortable: false }
];

const cardsHeader = [{ title: 'Cartões', field: 'cardCode', sortable: false }];

export default function VehicleRegister() {
  const dispatch = useDispatch();

  const classes = useStyles();
  const windowSize = useWindowDimensions();
  const tableRowWidth = windowSize.width < 768 ? '120' : '100';
  const addButtonLabel = windowSize.width > 959 ? null : 'Adicionar';

  const { establishmentId } = useSelector(state => state.businessInfo);
  const userId = useSelector(state => state.profile.userId);
  const clientId = useSelector(state => state.clientRegistration.basicInfo.clientId);

  const {
    vehicles,
    cards,
    isRemovingVehicle,
    plateToRemove,
    cardToRemove,
    isRemovingCard,
    loadingVehicles,
    loadingCards
  } = useSelector(state => state.clientRegistration.vehicle);

  const vehiclesPerPage = 5;
  const vehiclePages = Math.ceil(vehicles.length / vehiclesPerPage);

  const [vehiclesList, setVehiclesList] = useState(vehicles.slice(0, vehiclesPerPage));
  const [vehiclesCurrentPage, setVehiclesCurrentPage] = useState(1);

  const initialVehicleValues = {
    plate: '',
    vehicleModel: '',
    vehicleColor: ''
  }

  const initialCardValues = {
    cardCode: ''
  }

  useEffect(() => {
    const params = {
      userId,
      establishmentId,
      clientId
    }

    dispatch(loadVehicles(params));
    dispatch(loadCards(params));
  }, []);

  useEffect(() => {
    handleLoadVehicles(vehiclesCurrentPage);
  }, [vehicles]);

  const handleRegisterVehicle = (values, { resetForm }) => {
    const { plate, vehicleModel, vehicleColor } = values;

    const params = {
      plate: plate && plate.toUpperCase(),
      vehicleModel,
      vehicleColor,
      userId,
      establishmentId,
      clientId
    }

    dispatch(registerVehicle(params));
    resetForm({ values: '' });
  }

  const onSelectVehicleToRemove = plate => {
    dispatch(toggleToRemoveVehicle(!isRemovingVehicle, plate));
  }

  const handleToggleToRemoveVehicle = () => {
    dispatch(toggleToRemoveVehicle(!isRemovingVehicle, null));
  }

  const onRemoveVehicle = () => {
    const params = {
      plate: plateToRemove,
      userId,
      establishmentId,
      clientId
    }

    dispatch(removeVehicle(params, userId));
  }

  const handleRegisterCard = (values, { resetForm }) => {
    const params = {
      cardCode: values.cardCode,
      userId,
      establishmentId,
      clientId
    }

    dispatch(registerCard(params));
    resetForm({ values: '' });
  }

  const onSelectCardToRemove = cardCode => {
    dispatch(toggleToRemoveCard(!isRemovingCard, cardCode));
  }

  const handleToggleToRemoveCard = () => {
    dispatch(toggleToRemoveCard(!isRemovingCard, null));
  }

  const onRemoveCard = () => {
    const params = {
      cardToRemove,
      userId,
      establishmentId,
      clientId
    }

    dispatch(removeCard(params));
  }

  const handleLoadVehicles = (page) => {
    setVehiclesCurrentPage(page);
    setVehiclesList(vehicles.slice((vehiclesPerPage * page) - vehiclesPerPage, vehiclesPerPage * page));
  }

  return (
    <Grid container spacing={3}>
      <Grid item md={8} xs={12}>
        <Card>
          <CardHeader
            style={{ backgroundColor: '#1E5168', padding: '8px' }}
            title={
              <Typography gutterBottom variant="h6" className={classes.root}>
                Cadastro de veículo
              </Typography>
            }
          />
          <CardContent>
            <Grid container direction="column" spacing={1}>
              <Grid item xs={12}>
                <Formik
                  enableReinitialize
                  initialValues={initialVehicleValues}
                  validationSchema={vehicleValidations}
                  onSubmit={handleRegisterVehicle}
                >
                  {({ ...formikProps }) => {
                    const {
                      handleChange,
                      errors,
                      values: { plate, vehicleModel, vehicleColor },
                    } = formikProps;
                    return (
                      <Form>
                        <Grid
                          container
                          spacing={3}
                          direction="row"
                          alignItems="stretch"
                        >
                          <Grid item md={3} xs={12}>
                            <Input
                              name="plate"
                              type="plate"
                              label="Placa"
                              placeholder="AAA0000"
                              value={plate && plate.toUpperCase()}
                              error={errors.plate}
                              helperText={errors.plate}
                              onChange={e => {
                                handleChange(e);
                              }}
                            />
                          </Grid>
                          <Grid item md={4} xs={12}>
                            <Input
                              type="text"
                              name="vehicleModel"
                              label="Modelo"
                              placeholder="Modelo do veículo"
                              value={vehicleModel}
                              error={errors.vehicleModel}
                              helperText={errors.vehicleModel}
                              onChange={e => {
                                handleChange(e);
                              }}
                            />
                          </Grid>
                          <Grid item md={3} xs={12}>
                            <Input
                              type="text"
                              name="vehicleColor"
                              label="Cor"
                              placeholder="Cor do veículo"
                              value={vehicleColor}
                              error={errors.vehicleColor}
                              helperText={errors.vehicleColor}
                              onChange={e => {
                                handleChange(e);
                              }}
                            />
                          </Grid>
                          <Grid item md={2} xs={12}>
                            <Tooltip
                              arrow
                              open={plate}
                              classes={{
                                tooltip: classes.tooltip,
                                arrow: classes.arrow,
                              }}
                              title="Clique no botão para adicionar o veículo"
                              placement="top"
                            >
                              <span>
                                <Button
                                  fullWidth
                                  type="submit"
                                  id="addVehicle"
                                  color="primary"
                                  loading={loadingVehicles}
                                  className={classes.buttonPlus}
                                >
                                  <FontAwesomeIcon
                                    icon={faPlus}
                                    color="#ffffff"
                                    style={{
                                      marginRight: addButtonLabel
                                        ? '10px'
                                        : '0px',
                                    }}
                                  />
                                  {addButtonLabel}
                                </Button>
                              </span>
                            </Tooltip>
                          </Grid>
                        </Grid>
                      </Form>
                    );
                  }}
                </Formik>
              </Grid>
              <Grid item xs={12}>
                <MTable
                  loading={loadingVehicles}
                  headers={vehiclesHeader}
                  data={vehiclesList}
                  actions={[
                    {
                      icon: () => (
                        <FontAwesomeIcon
                          icon={faMinusCircle}
                          color="#F66D6E"
                          size="xs"
                        />
                      ),
                      tooltip: 'Remover',
                      onClick: (event, rowData) =>
                        onSelectVehicleToRemove(rowData.plate),
                    },
                  ]}
                  actionColumnWidth={tableRowWidth}
                />
                <Grid
                  container
                  xs={12}
                  justifyContent="flex-end"
                  style={{ marginTop: 15 }}
                >
                  <Pagination
                    color="primary"
                    variant="outlined"
                    shape="rounded"
                    count={vehiclePages}
                    page={vehiclesCurrentPage}
                    onChange={(_, page) => handleLoadVehicles(page)}
                  />
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item md={4} xs={12}>
        <Card className="mt-4">
          <CardHeader
            style={{ backgroundColor: '#1E5168', padding: '8px' }}
            title={
              <Typography gutterBottom variant="h6" className={classes.root}>
                Código identificador
              </Typography>
            }
          />
          <CardContent>
            <Formik
              enableReinitialize
              initialValues={initialCardValues}
              validationSchema={cardValidations}
              onSubmit={handleRegisterCard}
            >
              {({ ...formikProps }) => {
                const {
                  handleChange,
                  errors,
                  values: { cardCode },
                } = formikProps;
                return (
                  <Form>
                    <Grid
                      container
                      spacing={3}
                      direction="row"
                      alignItems="stretch"
                    >
                      <Grid item md={8} xs={12}>
                        <Input
                          id="cardCode"
                          name="cardCode"
                          type="number"
                          label="Cartão"
                          placeholder="000000"
                          value={cardCode}
                          error={errors.cardCode}
                          helperText={errors.cardCode}
                          onChange={e => {
                            handleChange(e);
                          }}
                        />
                      </Grid>
                      <Grid item md={4} xs={12}>
                        <Button
                          fullWidth
                          type="submit"
                          color="primary"
                          loading={loadingCards}
                          className={classes.buttonPlus}
                        >
                          <FontAwesomeIcon
                            icon={faPlus}
                            color="#ffffff"
                            style={{
                              marginRight: addButtonLabel ? '10px' : '0px'
                            }}
                          />
                          {addButtonLabel}
                        </Button>
                      </Grid>
                    </Grid>
                  </Form>
                );
              }}
            </Formik>
            <MTable
              loading={loadingCards}
              headers={cardsHeader}
              data={cards}
              actions={[
                {
                  icon: () => (
                    <FontAwesomeIcon
                      icon={faMinusCircle}
                      color="#F66D6E"
                      size="xs"
                    />
                  ),
                  tooltip: 'Remover',
                  onClick: (event, rowData) => onSelectCardToRemove(rowData.cardCode)
                }
              ]}
              actionColumnWidth={tableRowWidth}
            />
          </CardContent>
        </Card>
      </Grid>
      <ModalConfirmation
        open={isRemovingVehicle}
        isLoading={loadingVehicles}
        onClose={handleToggleToRemoveVehicle}
        handleConfirmation={onRemoveVehicle}
      >
        Deseja remover o veículo referente a placa{' '}
        <strong>{plateToRemove}</strong>?
      </ModalConfirmation>
      <ModalConfirmation
        open={isRemovingCard}
        isLoading={loadingCards}
        onClose={handleToggleToRemoveCard}
        handleConfirmation={onRemoveCard}
      >
        Deseja remover o cartão <strong>{cardToRemove}</strong>?
      </ModalConfirmation>
    </Grid>
  );
}