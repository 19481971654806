import React from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Loader from 'components/Loader';

import { formatEmptyValue } from '../../../../helpers/formatters';
import { formatCurrency } from '../../../../helpers/converters';
import { useStyles } from './styles';

const ResumeItem = ({ title, value, ...rest }) => {
  const classes = useStyles({ rest });

  return(
    <Grid container justify="space-between" className={classes.item}>
      <Grid item>
        <Typography variant="body1" color="primary">
          {title}
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="body1" color="primary" className={classes.value}>
          {value}
        </Typography>
      </Grid>
    </Grid>
  );
}

const Resume = ({ resume, isLoading }) => {
  const classes = useStyles();

  return(
    <Paper
      elevation={0}
      className={classes.paper}
      style={{ padding: isLoading ? 50 : 15 }}
    >
      <Loader isLoading={isLoading}>
        {!resume ? (
          <Grid>
            <Typography variant="body1" color="primary">
              Não há resumo disponível
            </Typography>
          </Grid>
        ) : (
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <ResumeItem
                title="Total de faturamento"
                value={
                  <Typography style={{ color: '#43a047', fontWeight: 'bold' }}>
                    {formatCurrency(resume.totalAmountSoPg)}
                  </Typography>
                }
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <ResumeItem
                title="Total de OS pagas"
                value={
                  <Typography style={{ color: '#43a047', fontWeight: 'bold' }}>
                    {formatEmptyValue(resume.totalSoPg)}
                  </Typography>
                }
              />
              <ResumeItem
                title="Total de OS alteradas"
                value={formatEmptyValue(resume.totalSoUpdated)}
              />
              <ResumeItem
                title="Total de OS canceladas"
                value={formatEmptyValue(resume.totalSoCanceled)}
              />
              <ResumeItem
                title="Período médio"
                value={
                  resume.averagePeriod
                    ? formatEmptyValue(
                        moment
                          .duration(parseFloat(resume.averagePeriod), 'seconds')
                          .format('D [dia(s)], H [h e] m [min]')
                      )
                    : 'Sem período'
                }
              />
            </Grid>
          </Grid>
        )}
      </Loader>
    </Paper>
  );
}

ResumeItem.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired
};

Resume.defaultProps = {
  resume: null
};

Resume.propTypes = {
  resume: PropTypes.shape({
    totalAmountSoPg: PropTypes.number,
    totalAmountSoRotary: PropTypes.number,
    totalAmountSoRecurrent: PropTypes.number,
    totalAmountSoCovenant: PropTypes.number,
    totalSoPg: PropTypes.number,
    totalSoUpdated: PropTypes.number,
    totalSoCanceled: PropTypes.number,
    averagePeriod: PropTypes.number,
    totalPeriod: PropTypes.string
  })
};

export default Resume;