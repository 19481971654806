import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { compose, assoc, set, over, lensProp, omit } from 'ramda';
import { Formik, Form as FormikForm } from 'formik';

import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import Collapse from '@material-ui/core/Collapse';
import Typography from '@material-ui/core/Typography';
import MaterialIcon from 'material-icons-react';

import Input from '../../../../components/Input';
import Button from '../../../../components/Button';
import Select from '../../../../components/Select';

import { handleMultipleSelection } from '../../../../helpers/common';
import { convertToDB, convertToMinutes } from '../../../../helpers/converters';
import { formatTimeRanges } from '../../../../helpers/formatters';

import { upsertTable, upsertSpecial } from '../../store/thunk';

import {
  clearPriceTable,
  registerFieldChange,
  addTimeRange,
  removeTimeRange,
  addSpecificTimeRange,
  removeSpecificTimeRange
} from '../../store/actions';

import {
  validations,
  specialValidations,
  validateTimeRanges,
  validateSpecificTimeRanges
} from './validations';

import { days } from './resources';

import { useStyles } from '../styles';

export default function Form() {
  const dispatch = useDispatch();

  const classes = useStyles();

  const {
    submit,
    linkedTable,
    isUpdating,
    isLoading,
    isSpecial,
    timeRanges,
    specificTimeRanges,
    updateType
  } = useSelector(state => state.priceTable);

  const optionsSelect = [
    { label: '----', value: '00:00' },
    { label: '08:00', value: '08:00' },
    { label: '12:00', value: '12:00' },
    { label: '16:00', value: '16:00' },
    { label: '24:00', value: '24:00' },
    { label: '36:00', value: '36:00' },
    { label: '48:00', value: '48:00' },
    { label: '72:00', value: '72:00' },
    { label: '96:00', value: '96:00' }
  ];

  const specialCondition = isSpecial.length > 0;

  const [originalTypePrice, setOriginalTypePrice] = useState('');

  useEffect(() => {
    setOriginalTypePrice(submit?.typePrice);
  }, [submit]);

  const formatTolerance = tolerance => {
    if(tolerance?.toString()?.match(/[0-9]{2}:[0-9]{2}/g)) {
      return tolerance;
    }

    if(tolerance?.toString()?.length == 1) {
      return `00:0${tolerance}`;
    }

    if(tolerance?.toString()?.length == 2) {
      return `00:${tolerance}`;
    }

    return tolerance;
  }

  const onTextChange = e => {
    dispatch(registerFieldChange(e.target.name, e.target.value));
  }

  const onTextTimeRangesChange = e => {
    if(e.target.value !== 0) {
      dispatch(registerFieldChange(e.target.name, e.target.value));
    }
  }

  const handleTimeRange = () => {
    validateTimeRanges
      .validate(timeRanges)
      .then(() => dispatch(addTimeRange()))
      .catch(error => toast.error(error.message));
  }

  const handleRemoveTimeRange = e => {
    dispatch(removeTimeRange(e.currentTarget.id));
  }

  const handleSpecificTimeRange = () => {
    validateSpecificTimeRanges
      .validate(specificTimeRanges)
      .then(() => dispatch(addSpecificTimeRange()))
      .catch(error => toast.error(error.message));
  }

  const handleRemoveSpecificTimeRange = e => {
    dispatch(removeSpecificTimeRange(e.currentTarget.id));
  }

  const handleSelectDays = (e, formikProps) => {
    const { value } = e.currentTarget;
    const { values, setFieldValue } = formikProps;

    const int = parseInt(value, 10);
    const isSelected = values.weekDays.includes(int);

    const weekDays = handleMultipleSelection(!isSelected, int, values.weekDays);

    setFieldValue('weekDays', weekDays);
  }

  const handlePriceTableRegistration = (values, actions) => {
    const { resetForm } = actions;
    const items = formatTimeRanges(timeRanges, specificTimeRanges);

    const normalizeTypePrice = tp => tp.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

    let params = compose(
      over(lensProp('tolerance'), convertToMinutes),
      over(lensProp('maximumPeriod'), convertToMinutes),
      over(lensProp('maximumValue'), convertToDB),
      over(lensProp('typePrice'), normalizeTypePrice),
      assoc('updateType', updateType),
      set(lensProp('items'), items)
    )(values);

    if(specialCondition) {
      params = omit(['typePrice', 'maximumPeriod', 'major', 'invisible'], params);

      return dispatch(upsertSpecial({ linkedTable, ...params })).then(() => {
        resetForm();
      });
    }

    params = omit(['description', 'compulsory', 'startTime', 'endTime', 'weekDays'], params);

    const formattedItems = params?.items
      ?.map(item => {
        if(item.period == 0) {
          delete item.period;
        }

        if(item.since == 0) {
          delete item.since;
        }

        return {
          ...item
        }
      })
      ?.filter(item => item.period);

    params.items = formattedItems;

    return dispatch(upsertTable({ ...params, originalTypePrice })).then(() => {
      resetForm();
    });
  }

  return(
    <Formik
      enableReinitialize
      initialValues={{
        ...submit,
        tolerance: formatTolerance(submit.tolerance)
      }}
      validationSchema={specialCondition ? specialValidations : validations}
      onSubmit={handlePriceTableRegistration}
    >
      {({ ...formikProps }) => {
        const {
          errors,
          handleChange,
          values: {
            typePrice,
            description,
            startTime,
            endTime,
            maximumPeriod,
            maximumValue,
            tolerance,
            weekDays
          }
        } = formikProps;

        return (
          <FormikForm>
            <Grid item xs={12}>
              <Collapse in={specialCondition}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="h5" color="primary">
                      Tabela vinculada: {linkedTable}
                    </Typography>
                    <Typography variant="body2" color="primary">
                      Configure sua tabela de acordo com o intervalo de horário
                      e dia da semana. Esses intervalos servem para cobrar
                      automaticamente.
                    </Typography>
                  </Grid>
                </Grid>
              </Collapse>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Input
                    autoFocus
                    type="typePrice"
                    name={specialCondition ? 'description' : 'typePrice'}
                    label={specialCondition ? 'Descrição' : 'Nome da tabela de preço'}
                    value={specialCondition ? description : typePrice}
                    error={errors.typePrice || errors.description}
                    helperText={errors.typePrice || errors.description}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
              <Collapse in={specialCondition}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography paragraph variant="subtitle2" color="primary">
                      Marque os dias que a tabela especial tem validade
                    </Typography>
                    {days.map((day, index) => {
                      const id = index + 1;
                      return (
                        <Button
                          key={id}
                          type="button"
                          name="weekDays"
                          color={weekDays.includes(id) ? 'primary' : 'default'}
                          variant={weekDays.includes(id) ? 'contained' : 'outlined'}
                          className={classes.daysButton}
                          value={id}
                          onClick={e => handleSelectDays(e, formikProps)}
                        >
                          {day.label}
                        </Button>
                      );
                    })}
                    <Grid item xs={12}>
                      <Typography variant="caption" color="error">
                        {errors.weekDays}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item md={6} xs={12}>
                    <Input
                      name="startTime"
                      type="timeRangePadStart"
                      label="Hora inicial"
                      placeholder="00:00"
                      value={startTime}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Input
                      name="endTime"
                      type="timeRangePadStart"
                      label="Hora final"
                      placeholder="00:00"
                      value={endTime}
                      onChange={handleChange}
                    />
                  </Grid>
                </Grid>
              </Collapse>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Input
                    name="tolerance"
                    type="timeRangePadStart"
                    label="Tolerância inicial"
                    placeholder="00:00"
                    value={tolerance}
                    onChange={handleChange}
                  />
                  {(tolerance?.toString()?.split(':')[0] >= 40) && (
                    <Typography color='primary' variant='subtitle2'>
                      Para "01 hora", coloque "01:00" no campo ao invés de "60:00".
                    </Typography>
                  )}
                </Grid>
              </Grid>
              <Grid style={{ display: 'flex', flexDirection: 'column-reverse' }}>
                {timeRanges.map((timeRange, index) => (
                  <Grid key={timeRange} container spacing={2} alignItems="center">
                    <Grid item md={5} xs={5}>
                      <Input
                        name={`timeRanges.${index}.period`}
                        type="timeRangePadStart"
                        label="Até"
                        placeholder="00:01"
                        value={timeRange.period}
                        onChange={onTextTimeRangesChange}
                      />
                    </Grid>
                    <Grid item md={5} xs={5}>
                      <Input
                        name={`timeRanges.${index}.price`}
                        type="money"
                        label="Cobrar o valor de"
                        placeholder="R$ 0,00"
                        value={timeRange.price}
                        onChange={onTextChange}
                      />
                    </Grid>
                    {index === 0 ? (
                      <Grid item md={2} xs={2}>
                        <Button
                          fullWidth
                          verticalGutter
                          type="button"
                          color="primary"
                          onClick={handleTimeRange}
                        >
                          <MaterialIcon icon="add" color="white" />
                        </Button>
                      </Grid>
                    ) : (
                      <Grid item md={2} xs={2}>
                        <Button
                          fullWidth
                          verticalGutter
                          type="button"
                          color="error"
                          id={`timeRanges.${index}.remove`}
                          onClick={handleRemoveTimeRange}
                        >
                          <MaterialIcon icon="delete" color="white" />
                        </Button>
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      {(timeRange.period.split(':')[0] >= 40) && (
                        <Typography color='primary' variant='subtitle2'>
                          Para "01 hora", coloque "01:00" no campo ao invés de "60:00".
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                ))}
              </Grid>
              <Grid style={{ display: 'flex', flexDirection: 'column-reverse' }}>
                {specificTimeRanges.map((timeRange, index) => (
                  <Grid container alignItems="center" spacing={2} key={timeRange}>
                    <Grid item md={3} xs={3}>
                      <Tooltip
                        title="Período precisa ser igual ao último valor do campo Até"
                        placement="top"
                      >
                        <Input
                          name={`specificTimeRanges.${index}.since`}
                          type="timeRangePadStart"
                          label="A partir de"
                          placeholder="00:00"
                          value={timeRange.since}
                          onChange={onTextChange}
                        />
                      </Tooltip>
                    </Grid>
                    <Grid item md={3} xs={3}>
                      <Input
                        name={`specificTimeRanges.${index}.period`}
                        id="specificTimeRanges"
                        type="timeRangePadStart"
                        label="A cada"
                        placeholder="00:00"
                        value={timeRange.period}
                        onChange={onTextChange}
                      />
                    </Grid>
                    <Grid item md={4} xs={4}>
                      <Input
                        name={`specificTimeRanges.${index}.price`}
                        type="money"
                        label="Somar o valor"
                        placeholder="R$ 0,00"
                        value={timeRange.price}
                        onChange={onTextChange}
                      />
                    </Grid>
                    {index === 0 ? (
                      <Grid item md={2} xs={2}>
                        <Button
                          fullWidth
                          type="button"
                          color="primary"
                          onClick={handleSpecificTimeRange}
                        >
                          <MaterialIcon icon="add" color="white" />
                        </Button>
                      </Grid>
                    ) : (
                      <Grid item md={2} xs={2}>
                        <Button
                          fullWidth
                          type="button"
                          color="error"
                          id={`specificTimeRanges.${index}.remove`}
                          onClick={handleRemoveSpecificTimeRange}
                        >
                          <MaterialIcon icon="delete" color="white" />
                        </Button>
                      </Grid>
                    )}
                    <Grid item xs={12} style={{ paddingTop: -10 }}>
                      {(timeRange.since.split(':')[0] >= 40) && (
                        <Typography color='primary' variant='subtitle2'>
                          Para "01 hora", coloque "01:00" no campo ao invés de "60:00".
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                ))}
              </Grid>
              <Grid container spacing={2}>
                <Grid item sm={6} xs={6}>
                  <Collapse in={!specialCondition}>
                    <Select
                      id="maximumPeriod"
                      name="maximumPeriod"
                      label="Durante o período de"
                      value={maximumPeriod}
                      options={optionsSelect}
                      error={errors.maximumPeriod}
                      helperText={errors.maximumPeriod}
                      onChange={handleChange}
                    />
                  </Collapse>
                </Grid>
                <Grid item sm={specialCondition ? 12 : 6} xs={6}>
                  <Input
                    name="maximumValue"
                    type="money"
                    label="Cobrar valor máximo de"
                    placeholder="R$ 0,00"
                    value={maximumValue}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item sm={6} xs={12}>
                  <Collapse in={isUpdating}>
                    <Button
                      fullWidth
                      type="button"
                      color="error"
                      loading={isLoading}
                      onClick={() => dispatch(clearPriceTable())}
                    >
                      Cancelar
                    </Button>
                  </Collapse>
                </Grid>
                <Grid item sm={isUpdating ? 6 : 12} xs={12}>
                  <Button fullWidth color="primary" loading={isLoading}>
                    {isUpdating ? 'Editar' : 'Cadastrar'}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </FormikForm>
        );
      }}
    </Formik>
  );
}