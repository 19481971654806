import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ spacing, palette }) => ({
  container: {
    marginBottom: spacing(3),
  },
  title: {
    color: palette.primary.main,
    fontWeigth: 'regular',
  },
}));