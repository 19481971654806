import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { Grid, Typography } from '@material-ui/core';
import { Row } from '../../Resources';

import { convertToReal } from 'helpers/converters';

export default function PayrollSheetUndoPayment({ lastRegister, activityData }) {
  return(
    <Grid container>
      <Grid xs={12} style={{ border: '1px solid #1e5168', borderRadius: 8, padding: 8, marginTop: 10 }}>
        <Row>
          <Typography>Data de pagamento</Typography>
          <Typography>{moment(lastRegister?.paymentDateTime).format('DD/MM/YYYY HH:mm:ss')}</Typography>
        </Row>
        <Row>
          <Typography>Motivo</Typography>
          <Typography>{lastRegister?.description || activityData?.description}</Typography>
        </Row>
        <Row>
          <Typography>Valor</Typography>
          <Typography>{convertToReal(lastRegister?.amount)}</Typography>
        </Row>
      </Grid>
    </Grid>
  );
}

PayrollSheetUndoPayment.propTypes = {
  lastRegister: PropTypes.object.isRequired,
  activityData: PropTypes.object.isRequired
};