import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Route, withRouter, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { toast } from 'react-toastify';

import Layout from '../../Layout';

import { changeDate } from '../store/actions';
import { handleLogout, setSingleEstablishment, setInitialEstablishments, setAppPermissionEstablishments } from '../../../pages/Login/store/actions';
import { setEstablishmentId } from '../../../pages/BusinessInfo/store/actions';
import { fetchUsersSituation } from 'components/Filter/store/services';
import { setBaseAPI, setToken } from '../../../services';
import { formatEstablishments } from '../../../helpers/formatters';

import { SITE_RESTRICTIONS_TO_PAGES } from 'helpers/restrictions';

const currentDate = moment().format('YYYY-MM-DD');
const isBeforeCurrentDate = date => moment(currentDate).isAfter(date, 'day');

const LayoutRoute = ({ component: Component, singleEstablishment, path, ...rest }) => {
  const dispatch = useDispatch();

  const history = useHistory();

  const { userId, userRestrictions, submit: { profileId } } = useSelector(state => state.profile);
  const { establishmentId } = useSelector(state => state.businessInfo);
  const { endDate } = useSelector(state => state.dateFilter);
  const { token, loggedIn, tokenExpirationTime, establishments } = useSelector(state => state.login);

  const [isSetupComplete, setIsSetupComplete] = useState(false);
  const [isLoginFromNoAccessCode] = useState(!!history.location.state?.data?.noAccessCode);

  const filterEstablishments = formatEstablishments(establishments, profileId === 1);

  const availableEstablishmentsId = establishments
    ?.map(establishment => establishment?.establishmentId);

  const blockedRoutes = SITE_RESTRICTIONS_TO_PAGES
    ?.filter(restriction => userRestrictions?.includes(restriction?.restriction))
    ?.map(restriction => restriction?.page);

  const verifyTokenExpired = () => {
    return !!(tokenExpirationTime && moment().isAfter(tokenExpirationTime));
  }

  const redirectToLogin = () => {
    dispatch(handleLogout());
    return () => history.push('/');
  }

  useEffect(() => {
    (async () => {
      if(+profileId != 1) {
        if(blockedRoutes?.includes(history.location.pathname)) {
          toast.error('Você não tem autorização para acessar esta área.');
          return history.push('/visao-geral');
        }

        const premiumPackageRoutes = ['/funcionarios', '/comissionamento', '/comissoes', '/produtos', '/historico-de-venda'];

        const { data } = await fetchUsersSituation(userId, token);
        let establishments = data?.establishments;

        if(premiumPackageRoutes?.includes(history.location.pathname)) {
          establishments = data?.establishments?.filter(establishment => +establishment?.premiumPackage == 1);
        }

        dispatch(setAppPermissionEstablishments(establishments));
      }
    })();
  }, [history.location.pathname]);

  useEffect(() => {
    const availableEstablishmentsIdWithEmptyEstablishment = availableEstablishmentsId?.includes('')
      ? availableEstablishmentsId
      : [...availableEstablishmentsId, ''];

    if(!availableEstablishmentsIdWithEmptyEstablishment?.includes(establishmentId) && !singleEstablishment) {
      dispatch(setEstablishmentId({ establishmentId: '' }));
    }

    if(!availableEstablishmentsIdWithEmptyEstablishment?.includes(establishmentId) && singleEstablishment) {
      dispatch(setEstablishmentId({ establishmentId: availableEstablishmentsId[0] }));
    }
  }, [availableEstablishmentsId]);

  useEffect(() => {
    if(isLoginFromNoAccessCode) {
      setIsSetupComplete(true);
    }
  }, [isLoginFromNoAccessCode]);

  useEffect(() => {
    if(history.location.pathname != '/lista/clientes') {
      if(isLoginFromNoAccessCode) {
        dispatch(handleLogout());
        toast.error('Ação não autorizada.');
        return history.push('/');
      }
    }
  }, [isLoginFromNoAccessCode, history.location.pathname]);

  useEffect(() => {
    if(!isLoginFromNoAccessCode) {
      if(isBeforeCurrentDate(endDate)) {
        dispatch(changeDate(currentDate, currentDate));
      }

      if(
        filterEstablishments.length === 0 ||
        (filterEstablishments.length === 1 && filterEstablishments[0].establishmentName === 'Todos os estabelecimentos')
      ) {
        history.push('/');
      }
    }
  }, []);

  useEffect(() => {
    if(!isLoginFromNoAccessCode) {
      if(!loggedIn) {
        return redirectToLogin();
      }

      if(verifyTokenExpired()) {
        return redirectToLogin();
      }

      if(token) {
        setToken(token);
      }

      setIsSetupComplete(true);
      setBaseAPI(userId, establishmentId);
    }
  }, [loggedIn, verifyTokenExpired()]);

  useEffect(() => {
    if(!isLoginFromNoAccessCode) {
      const filterAllEstablishmentsOption = filterEstablishments.filter(e => !e.value);
      const filterRestEstablishments = filterEstablishments.filter(e => e.value);

      if(
        singleEstablishment &&
        !establishmentId &&
        filterRestEstablishments.length > 0 &&
        filterAllEstablishmentsOption.length === 0
      ) {
        dispatch(setSingleEstablishment(filterRestEstablishments));

        dispatch(
          setEstablishmentId({
            establishmentId: filterRestEstablishments[0].value,
          })
        );

        setBaseAPI(userId, filterRestEstablishments[0].value);
      }

      if(singleEstablishment && filterAllEstablishmentsOption.length > 0) {
        const firstEstablishmentId = establishmentId || filterEstablishments[1]?.value;

        dispatch(setSingleEstablishment(filterEstablishments));
        dispatch(setEstablishmentId({ establishmentId: firstEstablishmentId }));

        setBaseAPI(userId, firstEstablishmentId);
      }

      if(
        !singleEstablishment &&
        filterEstablishments.length > 1 &&
        filterAllEstablishmentsOption.length === 0
      ) {
        dispatch(setInitialEstablishments(filterEstablishments));
      }
    }
  }, [filterEstablishments, establishmentId, singleEstablishment]);

  return(
    <Layout filterEstablishments={filterEstablishments} {...rest}>
      {isSetupComplete && (
        <Route
          path={path}
          render={matchProps => <Component {...matchProps} />}
        />
      )}
    </Layout>
  );
}

LayoutRoute.defaultProps = {
  singleEstablishment: false
};

LayoutRoute.propTypes = {
  singleEstablishment: PropTypes.bool,
  component: PropTypes.element.isRequired,
  path: PropTypes.string.isRequired
};

export default withRouter(LayoutRoute);