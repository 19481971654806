import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { Grid, Typography } from '@material-ui/core';
import { Row } from '../../Resources';

import { convertToReal } from 'helpers/converters';

export default function InvoiceUndoPayment({ lastRegister, activityData }) {
  return(
    <Grid container>
      <Grid xs={12}>
        <Grid xs={12} style={{ border: '1px solid #1e5168', borderRadius: 8, padding: 8, marginTop: 10 }}>
          <Row>
            <Typography>{activityData?.created_at}</Typography>
          </Row>
          <Row>
            <Typography>Responsável</Typography>
            <Typography>{activityData?.userName}</Typography>
          </Row>
          <Row>
            <Typography>Motivo</Typography>
            <Typography>{activityData?.description}</Typography>
          </Row>
          <Row>
            <Typography>Cliente</Typography>
            <Typography>{lastRegister?.invoice?.clientName}</Typography>
          </Row>
          <Row>
            <Typography>Vencimento</Typography>
            <Typography>{moment(lastRegister?.invoice?.dueDateTime).format('DD/MM/YYYY')}</Typography>
          </Row>
          <Row>
            <Typography>Valor</Typography>
            <Typography>{convertToReal(lastRegister?.invoice?.amountReceived)}</Typography>
          </Row>
        </Grid>
      </Grid>
    </Grid>
  );
}

InvoiceUndoPayment.propTypes = {
  lastRegister: PropTypes.object.isRequired,
  activityData: PropTypes.object.isRequired
};