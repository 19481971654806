import React from 'react';
import PropTypes from 'prop-types';
import Dropdown from '../Dropdown';
import ExportCSV from '../ExportCSV';
import ExportPDF from '../ExportPDF';

const ExportButtons = ({
  handlePageRequest,
  formatComponentData,
  exportHeaders,
  fileName,
  exportType,
  isDisabledXlsExport,
  isDisabledPdfExport,
  fullWidth = false,
  color = "success"
}) => {
  return(
    <Dropdown label="Exportar" fullWidth={fullWidth} color={color}>
      {!isDisabledXlsExport && (
        <ExportCSV
          requestData={(e, type) => handlePageRequest(e, type)}
          formatComponentData={formatComponentData}
          exportHeaders={exportHeaders}
          fileName={fileName}
          exportType={exportType}
        />
      )}
      {!isDisabledPdfExport && (
        <ExportPDF
          color="inherit"
          requestData={(e, type) => handlePageRequest(e, type)}
          formatComponentData={formatComponentData}
          exportHeaders={exportHeaders}
          fileName={fileName}
          exportType={exportType}
          fullWidth={fullWidth}
        >
          PDF
        </ExportPDF>
      )}
    </Dropdown>
  );
}

ExportButtons.propTypes = {
  handlePageRequest: PropTypes.func.isRequired,
  formatComponentData: PropTypes.func.isRequired,
  exportHeaders: PropTypes.arrayOf().isRequired,
  fileName: PropTypes.string.isRequired,
  exportType: PropTypes.string.isRequired
};

export default ExportButtons;