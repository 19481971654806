import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';

import { StyledButton } from './styles';

const Button = ({
  children,
  variant,
  color,
  type,
  disabled,
  loading,
  ...props
}) => (
  <StyledButton
    variant={variant}
    color={color}
    type={type}
    disabled={disabled || loading}
    {...props}
  >
    {loading ? <CircularProgress color="white" size={24} /> : children}
  </StyledButton>
);

Button.defaultProps = {
  loading: false,
  disabled: false,
  variant: 'contained',
  color: 'primary',
  type: 'submit',
};

Button.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.element.isRequired,
  ]).isRequired,
  disabled: PropTypes.bool,
  variant: PropTypes.bool,
  loading: PropTypes.bool,
  color: PropTypes.string,
  type: PropTypes.string,
};

export default Button;
