import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { Formik, Form } from 'formik';
import Grid from '@material-ui/core/Grid';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Modal from '../../../../components/Modal';
import Loader from '../../../../components/Loader';
import Input from '../../../../components/Input';
import Button from '../../../../components/Button';
import Status from '../../../../components/Status';
import Select from '../../../../components/Select';

import { upsertCategory } from '../../store/thunk';
import { handleRegistration, clearRegistration } from '../../store/actions';

import { convertToOptions } from '../../../../helpers/converters';
import { validations } from './validations';

export default function CategoriesRegistration({ isOpen }) {
  const dispatch = useDispatch();

  const { userId } = useSelector(state => state.profile);
  const { establishmentId } = useSelector(state => state.businessInfo);
  const page = useSelector(state => state.pagination.activePage);
  const pageSize = useSelector(state => state.pagination.size);
  const { selects, qp } = useSelector(state => state.filters);

  const availableTransactionTypes = convertToOptions(['transactionType'], selects);
  const availableCostCenters = convertToOptions(['costCenter'], selects);

  const {
    isLoading,
    categoryId,
    categoryName,
    transactionTypeId,
    costCenterId,
    status,
    mainCategory
  } = useSelector(state => state.categories);

  const initialState = {
    categoryId,
    categoryName,
    transactionTypeId,
    costCenterId,
    status,
    mainCategory
  }

  const handleModal = () => {
    dispatch(handleRegistration(false));
    dispatch(clearRegistration());
  }

  const handleRegister = async (values, actions) => {
    const properties = { page, pageSize, qp };
    const { resetForm } = actions;
    const params = values;

    await dispatch(upsertCategory(userId, establishmentId, params, properties, categoryId));
    await resetForm();
  }

  return(
    <Formik
      enableReinitialize
      initialValues={initialState}
      validationSchema={validations}
      onSubmit={handleRegister}
    >
      {({ ...formikProps }) => (
        <Modal
          id="categories"
          title="Cadastro de categoria"
          maxWidth="sm"
          open={isOpen}
          onClose={handleModal}
        >
          <Form>
            <Loader isLoading={isLoading}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Input
                    autoFocus
                    id="categoryName"
                    name="categoryName"
                    label="Descrição"
                    value={formikProps.values.categoryName}
                    error={formikProps.errors.categoryName}
                    helperText={formikProps.errors.categoryName}
                    onChange={formikProps.handleChange}
                    disabled={mainCategory == 1}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Status
                    value={formikProps.values.status}
                    disabled={mainCategory == 1}
                    onChange={formikProps.handleChange}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Select
                    name="transactionTypeId"
                    label="Tipo de lançamento"
                    options={availableTransactionTypes}
                    value={formikProps.values.transactionTypeId}
                    error={formikProps.errors.transactionTypeId}
                    helperText={formikProps.errors.transactionTypeId}
                    onChange={formikProps.handleChange}
                    disabled={mainCategory == 1}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Select
                    name="costCenterId"
                    label="Centro de custo"
                    options={availableCostCenters}
                    value={formikProps.values.costCenterId}
                    error={formikProps.errors.costCenterId}
                    helperText={formikProps.errors.costCenterId}
                    onChange={formikProps.handleChange}
                  />
                </Grid>
              </Grid>
            </Loader>
            <MuiDialogActions>
              <Button color="default" loading={isLoading} onClick={handleModal}>
                Cancelar
              </Button>
              <Button color="primary" loading={isLoading}>
                {categoryId ? 'Salvar' : 'Adicionar'}
              </Button>
            </MuiDialogActions>
          </Form>
        </Modal>
      )}
    </Formik>
  );
}

CategoriesRegistration.propTypes = {
  isOpen: PropTypes.bool.isRequired
};