import qs from 'qs';
import { newAPI } from 'services';

export const fetchProduct = async (userId, establishmentId, storageProductUuid) => {
  const response = await newAPI.get(`${userId}/establishment/${establishmentId}/storage/products/${storageProductUuid}`);
  return response.data;
}

export const createProduct = async (userId, establishmentId, params) => {
  const response = await newAPI.post(`${userId}/establishment/${establishmentId}/storage/products`, qs.stringify(params));
  return response.data;
}

export const updateProduct = async (userId, establishmentId, storageProductUuid, params) => {
  const response = await newAPI.patch(`${userId}/establishment/${establishmentId}/storage/products/${storageProductUuid}`, qs.stringify(params));
  return response.data;
}

export const fetchServiceProduct = async (userId, establishmentId, storageProductUuid) => {
  const response = await newAPI.get(`${userId}/establishment/${establishmentId}/services/products/${storageProductUuid}`);
  return response.data;
}

export const createServiceProduct = async (userId, establishmentId, params) => {
  const response = await newAPI.post(`${userId}/establishment/${establishmentId}/services/products`, qs.stringify(params));
  return response.data;
}

export const removeServiceProduct = async (userId, establishmentId, serviceProductId) => {
  const response = await newAPI.delete(`${userId}/establishment/${establishmentId}/services/products/${serviceProductId}`);
  return response.data;
}