import React from 'react';
import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';
import { useStyles } from '../../styles';

export default function Title({ children, ...props }) {
  const classes = useStyles();

  return (
    <Typography
      gutterBottom
      variant="subtitle1"
      className={classes.title}
      {...props}
    >
      {children}
    </Typography>
  );
}

Title.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired
};