import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'formik';

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

import { convertToOptions } from '../../../../../../helpers/converters';
import Select from '../../../../../../components/Select';

import { loadClient } from '../../BasicInfo/store/thunk';

import { useStyles } from './styles';

export default function LinkTable({ formikInjectProps }) {
  const dispatch = useDispatch();

  const classes = useStyles();

  const userId = useSelector(state => state.profile.userId);
  const establishmentId = useSelector(state => state.businessInfo.establishmentId);
  const { clientId } = useSelector(state => state.clientRegistration.basicInfo);
  const filters = useSelector(state => state.filters.selects);
  const availableTypePrices = convertToOptions(['typePrice'], filters);

  useEffect(() => {
    (async () => {
      const params = {
        userId,
        establishmentId,
        clientId
      };

      const { typePricesLink } = await dispatch(loadClient(params));

      formikInjectProps.setFieldValue('typePriceLink', Array.isArray(typePricesLink) ? typePricesLink : [typePricesLink]);
    })();
  }, []);

  return (
    <Grid item md={12} xs={12}>
      <Card>
        <CardHeader
          style={{ backgroundColor: '#1E5168', padding: '8px' }}
          title={
            <Typography gutterBottom variant="h6" className={classes.root}>
              Vincular tabela
            </Typography>
          }
        />
        <CardContent>
          <Form>
            <Grid container spacing={3} direction="row" alignItems="stretch">
              <Grid item md={8} xs={12}>
                <Select
                  multiple
                  name="typePriceLink"
                  label="Vincular tabela(s)"
                  options={availableTypePrices}
                  value={formikInjectProps.values.typePriceLink}
                  error={formikInjectProps.errors.typePriceLink}
                  helperText={formikInjectProps.errors.typePriceLink}
                  onChange={e => formikInjectProps.setFieldValue('typePriceLink', e.target.value)}
                />
              </Grid>
            </Grid>
          </Form>
        </CardContent>
      </Card>
    </Grid>
  );
}

LinkTable.defaultProps = {
  formikInjectProps: {
    handleChange: () => {},
    setFieldValue: () => {},
    values: {
      name: '',
      email: '',
      typePriceLink: ''
    },
    errors: {
      name: false,
      email: false,
      typePriceLink: ''
    }
  }
};

LinkTable.propTypes = {
  formikInjectProps: PropTypes.shape()
};