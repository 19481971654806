import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ palette, spacing }) => ({
  container: {
    minHeight: '100vh',
    backgroundColor: palette.secondary.main,
    padding: spacing(1),
  },
  paper: {
    textAlign: 'center',
    padding: spacing(4),
  },
  title: {
    fontWeight: 600,
  },
  divider: {
    marginTop: spacing(2),
    marginBottom: spacing(2),
  },
  linkContainer: {
    marginTop: spacing(1),
    marginBottom: spacing(2),
  },
  link: {
    textDecoration: 'none',
  },
  version: {
    color: 'white',
    textAlign: 'center',
    fontWeight: 500,
    marginTop: spacing(2),
  },
}));
