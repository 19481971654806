import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ spacing }) => ({
  paper: {
    paddingLeft: spacing(2),
    paddingRight: spacing(2),
  },
  title: {
    paddingTop: spacing(1),
    paddingBottom: spacing(1),
  },
  item: {
    paddingBottom: spacing(1),
  },
  value: {
    fontWeight: 500,
  },
}));
