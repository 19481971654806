export const SET_PRODUCT = 'PRODUCTS_REGISTRATION/SET_PRODUCT';
export const SET_PRODUCT_ID = 'PRODUCTS_REGISTRATION/SET_PRODUCT_ID';
export const SET_PRODUCT_UUID = 'PRODUCTS_REGISTRATION/SET_PRODUCT_UUID';
export const SET_PRODUCT_BASIC = 'PRODUCTS_REGISTRATION/SET_PRODUCT_BASIC';
export const SET_PRODUCT_STORAGE = 'PRODUCTS_REGISTRATION/SET_PRODUCT_STORAGE';
export const SET_PRODUCT_BASIC_AND_STORAGE_RESPONSE = 'PRODUCTS_REGISTRATION/SET_PRODUCT_BASIC_AND_STORAGE_RESPONSE';
export const SET_PRODUCT_CONSUMPTION = 'PRODUCTS_REGISTRATION/SET_PRODUCT_CONSUMPTION';
export const CLEAR_PRODUCT_CONSUMPTION = 'PRODUCTS_REGISTRATION/CLEAR_PRODUCT_CONSUMPTION';
export const SET_PRODUCT_BASIC_AND_STORAGE_RESPONSE_ERRORS = 'PRODUCTS_REGISTRATION/SET_PRODUCT_BASIC_AND_STORAGE_RESPONSE_ERRORS';
export const CLEAR_PRODUCT_BASIC_AND_STORAGE_RESPONSE_ERRORS = 'PRODUCTS_REGISTRATION/CLEAR_PRODUCT_BASIC_AND_STORAGE_RESPONSE_ERRORS';
export const SET_PRODUCT_CONSUMPTION_RESPONSE_ERRORS = 'PRODUCTS_REGISTRATION/SET_PRODUCT_CONSUMPTION_ERRORS';
export const CLEAR_PRODUCT_CONSUMPTION_RESPONSE_ERRORS = 'PRODUCTS_REGISTRATION/CLEAR_PRODUCT_CONSUMPTION_ERRORS';
export const SET_PRODUCT_LOADING = 'PRODUCTS_REGISTRATION/SET_PRODUCT_LOADING';
export const CLEAR_PRODUCT = 'PRODUCTS_REGISTRATION/CLEAR_PRODUCT';
export const SET_MEASUREMENT_UNIT_GROUP_ID = 'PRODUCTS_REGISTRATION/SET_MEASUREMENT_UNIT_GROUP_ID';