import React from 'react';
import PropTypes from 'prop-types';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import Loader from '../../../../components/Loader';

import { convertFormattedMoneyToNumber, convertToBrl } from '../../../../helpers/converters';
import { useStyles } from '../styles';

function ResumeValues({ value }) {
  return (
    <Grid container justify="center">
      <Grid item>
        <Typography variant="subtitle1" color="primary">
          R$
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="h4" color="primary">
          {value}
        </Typography>
      </Grid>
    </Grid>
  );
}

const Resume = ({ resume, loading }) => {
  const classes = useStyles();
  const { confirmed, pendent } = resume;

  return (
    <Container>
      <Grid item xs={12}>
        <Typography paragraph variant="h5" color="primary">
          Resumo do Fluxo de Caixa
        </Typography>
      </Grid>
      <Loader isLoading={loading}>
        <Grid container justify="space-between" spacing={6}>
          <Grid item sm={6} xs={12}>
            <Typography paragraph variant="h6" color="primary">
              Realizado
            </Typography>
            <Grid container justify="space-between">
              <Grid item>
                <Typography paragraph variant="body1" color="primary">
                  Total de despesas
                </Typography>
                <Typography paragraph variant="body1" color="primary">
                  Total de receitas
                </Typography>
                <Typography paragraph variant="body1" color="primary">
                  Saldo realizado
                </Typography>
              </Grid>
              <Grid item>
                <Typography paragraph variant="body1" className={classes.error}>
                  {convertToBrl(convertFormattedMoneyToNumber(confirmed.outbound?.toString()))}
                </Typography>
                <Typography
                  paragraph
                  variant="body1"
                  className={classes.success}
                >
                  {convertToBrl(convertFormattedMoneyToNumber(confirmed.inbound?.toString()))}
                </Typography>
                <Typography paragraph variant="body1" color="primary">
                  {convertToBrl(convertFormattedMoneyToNumber(confirmed.balance?.toString()))}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={6} xs={12}>
            <Typography paragraph variant="h6" color="primary">
              Previsto
            </Typography>
            <Grid container justify="space-between">
              <Grid item>
                <Typography paragraph variant="body1" color="primary">
                  Total de despesas
                </Typography>
                <Typography paragraph variant="body1" color="primary">
                  Total de receitas
                </Typography>
                <Typography paragraph variant="body1" color="primary">
                  Saldo realizado
                </Typography>
              </Grid>
              <Grid item>
                <Typography paragraph variant="body1" color="primary">
                  {convertToBrl(convertFormattedMoneyToNumber(pendent.outbound?.toString()))}
                </Typography>
                <Typography paragraph variant="body1" color="primary">
                  {convertToBrl(convertFormattedMoneyToNumber(pendent.inbound?.toString()))}
                </Typography>
                <Typography paragraph variant="body1" color="primary">
                  {convertToBrl(convertFormattedMoneyToNumber(pendent.balance?.toString()))}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Loader>
    </Container>
  );
}

ResumeValues.propTypes = {
  value: PropTypes.string.isRequired
};

Resume.defaultProps = {
  resume: {
    confirmed: {
      inbound: 0,
      outbound: 0,
      balance: 0
    },
    pendent: {
      inbound: 0,
      outbound: 0,
      balance: 0
    }
  }
};

Resume.propTypes = {
  resume: PropTypes.shape({
    confirmed: PropTypes.shape({
      inbound: PropTypes.number,
      outbound: PropTypes.number,
      balance: PropTypes.number
    }),
    pendent: PropTypes.shape({
      inbound: PropTypes.number,
      outbound: PropTypes.number,
      balance: PropTypes.number
    })
  }),
  loading: PropTypes.bool.isRequired
};

export default Resume;