import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import MuiDialogActions from '@material-ui/core/DialogActions';

import { Formik, Form } from 'formik';

import { omit } from 'ramda';

import Modal from '../../../../components/Modal';
import Loader from '../../../../components/Loader';
import Input from '../../../../components/Input';
import Select from '../../../../components/Select';
import Button from '../../../../components/Button';
import Status from '../../../../components/Status';

import { loadAccounts } from 'pages/Accounts/store/thunk';
import { upsertPaymentMethod, getPrimitivePayment } from '../../store/thunk';
import { handleRegistration, clearRegistration } from '../../store/actions';
import { convertToOptions, convertPercentageToDB } from '../../../../helpers/converters';

import { validations } from './validations';

export default function PaymentMethodsRegistration({ isOpen }) {
  const dispatch = useDispatch();

  const establishmentId = useSelector(state => state.businessInfo.establishmentId);
  const { userId } = useSelector(state => state.profile);
  const page = useSelector(state => state.pagination.activePage);
  const pageSize = useSelector(state => state.pagination.size);
  const { selects, qp } = useSelector(state => state.filters);

  const allAvailableAccounts = useSelector(state => state.accounts.list)
    ?.filter(account => +account.accountTypeId != 1)
    ?.map(account => {
      return {
        label: account?.accountName,
        value: account?.accountId
      }
    });

  const availablePrimitives = convertToOptions(
    ['primitivePaymentMethod'],
    selects
  );

  const {
    isLoading,
    paymentMethodName,
    paymentMethodId,
    primitivePaymentMethodId,
    receivingFee,
    accountId,
    status
  } = useSelector(state => state.paymentMethods);

  const updatedForm = useSelector(state => state.paymentMethods.form);

  const INITIAL_VALUES = {
    paymentMethodName,
    paymentMethodId,
    primitivePaymentMethodId,
    receivingFee,
    accountId,
    status
  }

  const [initialValues, setInitialValues] = useState(INITIAL_VALUES);
  const [type, setPaymentType] = useState(null);

  const fetchPaymentMethodType = id => {
    dispatch(getPrimitivePayment(userId, establishmentId, id)).then(form => {
      if (form) {
        setPaymentType(form);
        form.forEach(val => {
          const { name } = val;

          return setInitialValues(
            ({ primitivePaymentMethodId, ...prevState }) => ({
              [name]: '',
              primitivePaymentMethodId: id,
              ...prevState,
            })
          );
        });
      } else {
        const formatInitialValues = omit(
          ['primitivePaymentMethodId'],
          INITIAL_VALUES
        );
        setPaymentType([]);
        setInitialValues({
          primitivePaymentMethodId: id,
          ...formatInitialValues,
        });
      }
    });
  };

  useEffect(() => {
    dispatch(loadAccounts({ page: 1, pageSize: '', qp: '', userId, establishmentId }));
  }, []);

  useEffect(() => {
    async function fetchMyAPI() {
      await fetchPaymentMethodType(primitivePaymentMethodId);
      await setPaymentType(updatedForm);
      await updatedForm.forEach(val => {
        const { name, value } = val;

        return setInitialValues(
          ({ primitivePaymentMethodId, ...prevState }) => ({
            [name]: value,
            ...prevState
          })
        );
      });
    }

    if (updatedForm) {
      fetchMyAPI();
    } else {
      setPaymentType([]);
    }
  }, []);

  const handleModal = () => {
    dispatch(handleRegistration(false));
    dispatch(clearRegistration());
  }

  const handlePrimitivePaymentMethod = async (e, setFieldValue) => {
    const { value } = e.target;
    await setFieldValue(e.target.name, e.target.value);

    if (e.target.value === '') {
      setInitialValues(INITIAL_VALUES);
      return setPaymentType(null);
    }

    await fetchPaymentMethodType(value);
  }

  const handleRegister = async (values, actions) => {
    const properties = { page, pageSize, qp };
    const { resetForm } = actions;

    const validatedParams = {
      ...values,
      receivingFee: convertPercentageToDB(values.receivingFee) || 0
    }

    if(+validatedParams?.primitivePaymentMethodId == 8) {
      delete validatedParams.hash;
    }

    await dispatch(upsertPaymentMethod(userId, establishmentId, validatedParams, properties));
    await resetForm();
  }

  return(
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validations}
      onSubmit={handleRegister}
    >
      {({ ...formikProps }) => (
        <Modal
          id="paymentMethods"
          title="Cadastro de meio de pagamento"
          maxWidth="sm"
          scroll="body"
          open={isOpen}
          onClose={handleModal}
        >
          <Form>
            <Loader isLoading={isLoading}>
              <Grid container spacing={2}>
                <Select
                  name="primitivePaymentMethodId"
                  label="Tipo de pagamento"
                  className="select"
                  options={availablePrimitives}
                  error={formikProps.errors.primitivePaymentMethodId}
                  helperText={formikProps.errors.primitivePaymentMethodId}
                  value={formikProps.values.primitivePaymentMethodId}
                  onChange={e => handlePrimitivePaymentMethod(e, formikProps.setFieldValue)}
                />
                {type ? (
                  <>
                    <Grid container spacing={2}>
                      <Grid item md={6} xs={12}>
                        <Input
                          autoFocus
                          id="paymentMethodName"
                          name="paymentMethodName"
                          label="Nome"
                          value={formikProps.values.paymentMethodName}
                          error={formikProps.errors.paymentMethodName}
                          helperText={formikProps.errors.paymentMethodName}
                          onChange={formikProps.handleChange}
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <Status
                          value={formikProps.values.status}
                          onChange={formikProps.handleChange}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item md={6} xs={12}>
                        <Input
                          id="receivingFee"
                          type="percentage"
                          label="Taxa"
                          placeholder="0"
                          value={formikProps.values.receivingFee}
                          onChange={formikProps.handleChange}
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <Select
                          name="accountId"
                          label="Conta destino"
                          options={allAvailableAccounts}
                          value={formikProps.values.accountId}
                          error={formikProps.errors.accountId}
                          helperText={formikProps.errors.accountId}
                          onChange={formikProps.handleChange}
                        />
                      </Grid>
                    </Grid>
                  </>
                ) : null}
                {type && type.length > 0 ? (
                  <>
                    {type.map(input => {
                      const { name, type, title } = input;

                      return (
                        <Grid container spacing={2}>
                          <Grid item xs={12} key={input.name}>
                            <Input
                              id={name}
                              type={type}
                              label={title}
                              value={formikProps.values[name]}
                              onChange={formikProps.handleChange}
                            />
                          </Grid>
                        </Grid>
                      );
                    })}
                  </>
                ) : null}
              </Grid>
            </Loader>
            <MuiDialogActions>
              <Button color="default" loading={isLoading} onClick={handleModal}>
                Cancelar
              </Button>
              <Button color="primary" loading={isLoading}>
                {paymentMethodId ? 'Salvar' : 'Adicionar'}
              </Button>
            </MuiDialogActions>
          </Form>
        </Modal>
      )}
    </Formik>
  );
}

PaymentMethodsRegistration.propTypes = {
  isOpen: PropTypes.bool.isRequired
};