import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { numericFormatter } from 'react-number-format';

import { Paper, Grid } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import Filter from 'components/Filter/components';
import FilterMobile from 'components/FilterMobile/components';
import MTable from 'components/MTable';

import StorageMovement from 'components/StorageMovement';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSlidersH, faFile, faPen } from '@fortawesome/free-solid-svg-icons';

import { setProductId, setProductUuid, clearProduct, clearProductConsumption } from '../../ProductsRegistration/store/actions';
import { setProductSellUuid, clearProductSell } from '../../ProductsSellRegistration/store/actions';
import { setProductConsumeAndSellUuid, clearProductConsumeAndSell } from '../../ProductsUseAndSellRegistration/store/actions';
import { loadProductsFilters, loadProductsList, loadProductsExport } from '../store/thunk';
import { loadFilters } from 'components/Filter/store/thunk';

import { inputs } from './resources';

import useDidMount from 'helpers/hooks/useDidMount';

const headers = [
  { title: 'Nome', field: 'name' },
  { title: 'Categoria', field: 'productTypeName' },
  { title: 'Marca', field: 'brandName' },
  { title: 'Estoque Mínimo (Consumo)', field: 'minQuantityToUse' },
  { title: 'Estoque Atual (Consumo)', field: 'quantityToUse' },
  { title: 'Estoque Mínimo (Venda)', field: 'minQuantityToSell' },
  { title: 'Estoque Atual (Venda)', field: 'quantityToSell' }
];

export default function Products() {
  const dispatch = useDispatch();

  const history = useHistory();

  const didComponentMount = useDidMount();

  const width = window.innerWidth;
  const isMobile = width < 1024;

  const { userId } = useSelector(state => state.profile);
  const { establishmentId } = useSelector(state => state.businessInfo);
  const { establishments } = useSelector(state => state.login);
  const { qp } = useSelector(state => state.filters);

  const {
    isLoading,
    filters,
    list: {
      content,
      total
    }
  } = useSelector(state => state.products);

  const firstPremiumPackageEstablishment = establishments
    ?.filter(establishment => +establishment?.premiumPackage == 1)[0]
    ?.establishmentId;

  const productTypesParentsId = filters?.productTypes
    ?.map(product => product.parentProductTypeId)
    ?.filter((product, index, array) => array?.indexOf(product) == index);

  const productTypesParents = filters?.productTypes
    ?.filter(product => productTypesParentsId?.includes(product?.id));

  const formattedProductType = filters?.productTypes
    ?.map(type => {
      return {
        parent: productTypesParents?.find(product => +product?.id == +type?.parentProductTypeId)?.description,
        value: type?.id,
        label: type?.description
      }
    })
    ?.filter(type => !!type.parent);

  const formattedFilters = inputs
    ?.concat({
      advanced: false,
      type: 'autocomplete',
      id: 'brandId',
      label: 'Marca',
      value: '',
      options: [
        {
          label: '--',
          value: ''
        },
        ...!!filters?.productBrands && filters?.productBrands?.map(brand => {
          return {
            label: brand?.name,
            value: brand?.id
          }
        })
      ]
    })
    ?.concat({
      advanced: false,
      type: 'autocomplete',
      id: 'productTypeId',
      label: 'Categoria',
      value: '',
      options: [
        {
          label: '--',
          value: ''
        },
        ...formattedProductType
      ]
    });

  const [productsPage, setProductsPage] = useState(1);
  const productsPageSize = 10;
  const productsPageCount = Math.ceil(total / productsPageSize);

  const [isStorageMovementModalOpen, setIsStorageMovementModalOpen] = useState(false);
  const [isStorageMovementSell, setIsStorageMovementSell] = useState(false);
  const [isStorageMovementConsume, setIsStorageMovementConsume] = useState(false);
  const [storageMovementModalData, setStorageMovementModalData] = useState({});

  const handleLoadProductsExport = () => {
    return dispatch(loadProductsExport({ userId, establishmentId, qp }));
  }

  const requestProductsList = ({ establishmentId, page, qp }) => {
    dispatch(loadProductsList({ userId, establishmentId, page, qp }));
  }

  useEffect(() => {
    if(!establishmentId || (+firstPremiumPackageEstablishment == +establishmentId)) {
      requestProductsList({ establishmentId: firstPremiumPackageEstablishment, page: 1, qp });
      dispatch(loadProductsFilters(userId, firstPremiumPackageEstablishment));
      return;
    }

    if(+firstPremiumPackageEstablishment != +establishmentId) {
      requestProductsList({ establishmentId, page: 1, qp });
      dispatch(loadProductsFilters(userId, establishmentId));
      return;
    }
  }, [firstPremiumPackageEstablishment]);

  useEffect(() => {
    if(establishmentId && !didComponentMount) {
      dispatch(loadFilters(userId, establishmentId));
      dispatch(loadProductsFilters(userId, establishmentId));
      requestProductsList({ establishmentId, page: productsPage, qp });
    }
  }, [establishmentId]);

  useEffect(() => {
    if(!didComponentMount) {
      requestProductsList({ establishmentId, page: productsPage, qp });
    }
  }, [productsPage]);

  return(
    <Paper>
      {isStorageMovementModalOpen && (
        <StorageMovement
          isStorageMovementModalOpen={isStorageMovementModalOpen}
          setIsStorageMovementModalOpen={setIsStorageMovementModalOpen}
          productName={storageMovementModalData?.name}
          storageProductUuid={storageMovementModalData?.storageProductUuid}
          isStorageMovementSell={isStorageMovementSell}
          isStorageMovementConsume={isStorageMovementConsume}
          pageOrigin='/produtos'
          productListCurrentPage={productsPage}
        />
      )}
      {isMobile ? (
        <FilterMobile
          filter
          filters={formattedFilters}
          isDisabledPdfExport
          alternativeExport
          functionAlternativeExport={handleLoadProductsExport}
          exportType='products'
          insertProductsButtons
          handleProductsButtons={() => {
            dispatch(clearProduct());
            dispatch(clearProductConsumption());
          }}
          handlePageRequest={({ qp }) => {
            setProductsPage(1);
            return requestProductsList({ establishmentId, page: 1, qp });
          }}
        />
      ) : (
        <Filter
          filter
          filters={formattedFilters}
          isDisabledPdfExport
          alternativeExport
          functionAlternativeExport={handleLoadProductsExport}
          exportType='products'
          insertProductsButtons
          handleProductsButtons={() => {
            dispatch(clearProduct());
            dispatch(clearProductConsumption());
          }}
          handlePageRequest={({ qp }) => {
            setProductsPage(1);
            return requestProductsList({ establishmentId, page: 1, qp });
          }}
        />
      )}
      <MTable
        loading={isLoading}
        headers={headers}
        data={content?.map(item => {
          return {
            ...item,
            quantityToUse: !!item?.quantityToUse ? numericFormatter(item?.quantityToUse?.toString(), { thousandSeparator: '.', decimalSeparator: ',', decimalScale: 2, fixedDecimalScale: 2 }) : null,
            quantityToSell: Number.isInteger(item?.quantityToSell) ? parseInt(item?.quantityToSell) : null
          }
        })}
        actions={[
          rowData => ({
            icon: () => (
              <FontAwesomeIcon
                icon={faSlidersH}
                color="#2CA9D6"
                size="xs"
              />
            ),
            tooltip: 'Movimentar Estoque',
            onClick: (_, rowData) => {
              const isSell = rowData?.quantityToSell != null && Number.isInteger(rowData?.quantityToSell);
              const isConsume = rowData?.quantityToUse != null && !!rowData?.quantityToUse;

              setIsStorageMovementSell(isSell);
              setIsStorageMovementConsume(isConsume);
              setStorageMovementModalData(rowData);
              setIsStorageMovementModalOpen(true);
            }
          }),
          rowData => ({
            icon: () => (
              <FontAwesomeIcon
                icon={faFile}
                color="#2CA9D6"
                size="xs"
              />
            ),
            tooltip: 'Histórico de Movimentação de Estoque',
            onClick: (_, rowData) => {
              const isSell = rowData?.quantityToSell != null && Number.isInteger(rowData?.quantityToSell);
              const isConsume = rowData?.quantityToUse != null && !!rowData?.quantityToUse;
              const isConsumeAndIsSell = isSell && isConsume;

              return history.push({
                pathname: '/movimentacao-de-estoque',
                state: {
                  data: {
                    product: rowData,
                    isSell,
                    isConsume,
                    isConsumeAndIsSell
                  }
                }
              });
            }
          }),
          rowData => ({
            icon: () => (
              <FontAwesomeIcon
                icon={faPen}
                color="#2CA9D6"
                size="xs"
              />
            ),
            tooltip: 'Editar Produto',
            onClick: (_, rowData) => {
              const isSell = rowData?.quantityToSell != null && Number.isInteger(rowData?.quantityToSell);
              const isConsume = rowData?.quantityToUse != null && !!rowData?.quantityToUse;
              const isConsumeAndIsSell = isSell && isConsume;

              if(isConsumeAndIsSell) {
                dispatch(clearProductConsumeAndSell());
                dispatch(setProductConsumeAndSellUuid(rowData?.storageProductUuid));

                return history.push('/editar-produto-consumo-e-venda');
              }

              if(isSell) {
                dispatch(clearProductSell());
                dispatch(setProductSellUuid(rowData?.storageProductUuid));

                return history.push('/editar-produto-venda');
              }

              if(isConsume) {
                dispatch(clearProduct());
                dispatch(setProductId(rowData?.id));
                dispatch(setProductUuid(rowData?.storageProductUuid));

                return history.push('/editar-produto');
              }
            }
          })
        ]}
      />
      <Grid
        container
        xs={12}
        style={{ padding: 10, display: 'flex', justifyContent: 'flex-end' }}
      >
        <Pagination
          color="primary"
          variant="outlined"
          shape="rounded"
          count={productsPageCount}
          page={productsPage}
          onChange={(_, page) => setProductsPage(page)}
        />
      </Grid>
    </Paper>
  );
}