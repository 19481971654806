import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { numericFormatter } from 'react-number-format';

import { Paper, Grid, Box, Typography } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import Filter from 'components/Filter/components';
import FilterMobile from 'components/FilterMobile/components';
import MTable from 'components/MTable';
import SellOrder from 'components/SellOrder/components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';

import { loadFilters } from 'components/Filter/store/thunk';
import { loadSellOrdersList, loadSellOrdersResume, loadSellOrdersExport, loadSellOrder } from '../store/thunk';

import { nameInput, documentInput, orderInput, productInput } from './resources';

import useDidMount from 'helpers/hooks/useDidMount';

const headers = [
  { title: 'Código', field: 'orderCode' },
  { title: 'Criação', field: 'registrationDateTime' },
  { title: 'Cliente', field: 'client' },
  { title: 'Meio de Pagamento', field: 'paymentMethodName' },
  { title: 'Valor Total', field: 'totalAmount' },
  { title: 'Situação', field: 'situationDescription' }
];

export default function SellOrders() {
  const dispatch = useDispatch();

  const didComponentMount = useDidMount();

  const width = window.innerWidth;
  const isMobile = width < 1024;

  const { userId } = useSelector(state => state.profile);
  const { establishmentId } = useSelector(state => state.businessInfo);
  const { startDate, endDate } = useSelector(state => state.dateFilter);
  const { qp, selects } = useSelector(state => state.filters);
  const { isLoading, total, list, resume } = useSelector(state => state.sellOrders);

  const [isSellOrderModalOpen, setIsSellOrderModalOpen] = useState(false);

  const [sellOrdersPage, setSellOrdersPage] = useState(1);
  const sellOrdersPageSize = 10;
  const sellOrdersPageCount = Math.ceil(total / sellOrdersPageSize);

  const handleLoadSellOrdersExport = () => {
    const extraProps = {
      startDate,
      endDate,
      qp
    }

    return dispatch(loadSellOrdersExport(userId, establishmentId, extraProps));
  }

  const handleLoadSellOrdersList = ({ page, qp }) => {
    const extraProps = {
      startDate,
      endDate,
      qp,
      page,
      limit: 10
    }

    dispatch(loadSellOrdersList(userId, establishmentId, extraProps));
  }

  const handleLoadSellOrdersResume = qp => {
    const extraProps = {
      startDate,
      endDate,
      qp
    }

    dispatch(loadSellOrdersResume(userId, establishmentId, extraProps));
  }

  useEffect(() => {
    if(establishmentId) {
      dispatch(loadFilters(userId, establishmentId));
      handleLoadSellOrdersResume(qp);
      handleLoadSellOrdersList({ page: 1, qp });
    }
  }, [establishmentId]);

  useEffect(() => {
    if(!didComponentMount) {
      handleLoadSellOrdersResume(qp);
      handleLoadSellOrdersList({ page: sellOrdersPage, qp });
    }
  }, [sellOrdersPage]);

  const formattedFilters = [
    nameInput,
    documentInput,
    {
      advanced: false,
      type: 'select',
      id: 'situationId',
      label: 'Situação',
      value: 0,
      options: selects
        ?.find(select => select?.id == 'orderSituation')?.options
        ?.map(option => {
          return {
            value: option?.id,
            label: option?.label
          }
        })
    },
    {
      advanced: false,
      type: 'select',
      id: 'registrationUserId',
      label: 'Usuário',
      value: 0,
      options: selects?.find(select => select?.id == 'user')?.options || []
    },
    orderInput,
    productInput,
    {
      advanced: false,
      type: 'select',
      id: 'paymentMethodId',
      label: 'Formas de Pagamento',
      value: 0,
      options: selects?.find(select => select?.id == 'paymentMethod')?.options || []
    },
  ];

  return(
    <Paper>
      {isSellOrderModalOpen && (
        <SellOrder
          isSellOrderModalOpen={isSellOrderModalOpen}
          setIsSellOrderModalOpen={setIsSellOrderModalOpen}
          sellOrdersPageSize={sellOrdersPageSize}
          sellOrdersPage={sellOrdersPage}
          pageOrigin='/historico-de-venda'
        />
      )}
      <Grid container>
        <Grid xs={12} md={6} style={{ padding: 15 }}>
          <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography color='primary'>Total de faturamento</Typography>
            <Typography color='textPrimary'>
              <b>R$ {numericFormatter(resume?.totalAmount?.toString(), { thousandSeparator: '.', decimalSeparator: ',', decimalScale: 2, fixedDecimalScale: 2 })}</b>
            </Typography>
          </Box>
          <Box style={{ display: 'flex', justifyContent: 'space-between', marginTop: 10 }}>
            <Typography color='primary'>Pedidos pagos</Typography>
            <Typography style={{ color: '#F5B914' }}>
              <b>{resume?.countPaid}</b>
            </Typography>
          </Box>
        </Grid>
        <Grid xs={12} md={6} style={{ padding: 15 }}>
          <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography color='primary'>Total de pedidos</Typography>
            <Typography color='primary'>
              <b>{resume?.count}</b>
            </Typography>
          </Box>
          <Box style={{ display: 'flex', justifyContent: 'space-between', marginTop: 10 }}>
            <Typography color='primary'>Ticket médio</Typography>
            <Typography color='primary'>
              <b>R$ {numericFormatter(resume?.mediumAmount?.toString(), { thousandSeparator: '.', decimalSeparator: ',', decimalScale: 2, fixedDecimalScale: 2 })}</b>
            </Typography>
          </Box>
        </Grid>
      </Grid>
      {isMobile ? (
        <FilterMobile
        filter
        filters={formattedFilters}
        isDisabledPdfExport
        alternativeExport
        functionAlternativeExport={handleLoadSellOrdersExport}
        exportType='sellOrders'
        fileName='Histórico de Venda'
        handlePageRequest={({ page, qp }) => {
          handleLoadSellOrdersResume(qp);
          handleLoadSellOrdersList({ page, qp });
        }}
        />
      ) : (
        <Filter
          filter
          filters={formattedFilters}
          isDisabledPdfExport
          alternativeExport
          functionAlternativeExport={handleLoadSellOrdersExport}
          exportType='sellOrders'
          fileName='Histórico de Venda'
          handlePageRequest={({ page, qp }) => {
            handleLoadSellOrdersResume(qp);
            handleLoadSellOrdersList({ page, qp });
          }}
        />
      )}
      <MTable
        loading={isLoading}
        headers={headers}
        data={list}
        lineBreak
        actions={[
          rowData => ({
            icon: () => (
              <FontAwesomeIcon
                icon={faEye}
                color="#2CA9D6"
                size="xs"
              />
            ),
            tooltip: 'Visualizar ordem de venda',
            onClick: () => {
              setIsSellOrderModalOpen(true);
              dispatch(loadSellOrder(userId, establishmentId, rowData?.orderId));
            }
          })
        ]}
      />
      <Grid
        container
        xs={12}
        style={{ padding: 10, display: 'flex', justifyContent: 'flex-end' }}
      >
        <Pagination
          color="primary"
          variant="outlined"
          shape="rounded"
          count={sellOrdersPageCount}
          page={sellOrdersPage}
          onChange={(_, page) => setSellOrdersPage(page)}
        />
      </Grid>
    </Paper>
  );
}