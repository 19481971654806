import React, { useState } from 'react';

import { Grid, Typography } from '@material-ui/core';
import Button from 'components/Button/Button';
import Modal from 'components/Modal';

export default function ButtonConfirmation({
  title,
  color,
  fullWidth,
  type = 'button',
  loading,
  disabled,
  maxWidth = 'sm',
  modalTitle,
  modalText,
  modalButtons
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return(
    <>
      {isModalOpen && (
        <Modal
          id="button-confirmation-modal"
          title={modalTitle}
          scroll="body"
          maxWidth={maxWidth}
          open={isModalOpen}
          onClose={() => setIsModalOpen(false)}
        >
          <Typography>{modalText}</Typography>
          <Grid container>
            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end', gap: 10 }}>
              <Button
                color="error"
                onClick={() => setIsModalOpen(false)}
              >
                Cancelar
              </Button>
              {modalButtons?.map(button => {
                return(
                  <Button
                    color={button?.color}
                    onClick={() => {
                      return(
                        setIsModalOpen(false),
                        button?.onClick()
                      );
                    }}
                  >
                    {button?.title}
                  </Button>
                );
              })}
            </Grid>
          </Grid>
        </Modal>
      )}
      <Button
        loading={loading}
        type={type}
        disabled={disabled}
        fullWidth={fullWidth}
        color={color}
        onClick={() => setIsModalOpen(true)}
      >
        {title}
      </Button>
    </>
  );
}