import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { pick } from 'ramda';
import { connect } from 'react-redux';

import { Form, Field } from 'formik';
import Grid from '@material-ui/core/Grid';
import MuiDialogActions from '@material-ui/core/DialogActions';

import Validator from '../../../../components/Validator';
import Modal from '../../../../components/Modal';
import Loader from '../../../../components/Loader';
import Input from '../../../../components/Input';
import Select from '../../../../components/Select';
import Button from '../../../../components/Button';
import Status from '../../../../components/Status';

import { upsertAccount } from '../../store/thunk';
import { registerFieldChange, openRegistrationModal, clearRegistration } from '../../store/actions';

import { formatAvailableEstablishmentsToUuid } from '../../../../helpers/formatters';
import { onTextChange, onSelectChange } from '../../../../helpers/listeners';

import { validations } from './validations';

class AccountRegistration extends Component {
  handleModal = () => {
    const { openRegistrationModal, clearRegistration } = this.props;
    openRegistrationModal(false);
    clearRegistration();
  }

  onSave = (values, actions) => {
    const { resetForm } = actions;

    const {
      upsertAccount,
      accountId,
      accountName,
      bankName,
      bankAgency,
      bankAccount,
      status,
      mainAccount,
      userId,
      establishmentId,
      establishmentsUuid
    } = this.props;

    const params = {
      accountId,
      accountName,
      bankName,
      bankAgency,
      bankAccount,
      status,
      mainAccount,
      userId,
      establishmentId,
      establishments: establishmentsUuid
    }

    const properties = pick(['page', 'pageSize'], this.props);

    upsertAccount(params, properties).then(error => {
      if(!error) {
        resetForm({ values: this.props });
      }
    });
  }

  render() {
    const {
      isOpen,
      isLoading,
      accountId,
      mainAccount,
      accountTypeId,
      accountName,
      bankName,
      bankAgency,
      bankAccount,
      status,
      availableEstablishments,
      establishmentsUuid,
      fieldChange
    } = this.props;

    const businessAccount = accountTypeId === 2 && mainAccount === 1;

    return(
      <Validator
        initialValues={this.props}
        validations={validations}
        onSubmit={this.onSave}
      >
        {({ ...props }) => (
          <Loader isLoading={isLoading}>
            <Modal
              id="accounts"
              title="Cadastro de conta"
              maxWidth="sm"
              open={isOpen}
              onClose={this.handleModal}
            >
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Field
                      autoFocus
                      id="accountName"
                      name="accountName"
                      label="Descrição"
                      component={Input}
                      value={accountName}
                      error={props.errors.accountName}
                      helperText={props.errors.accountName}
                      onChange={onTextChange(fieldChange, props.handleChange)}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item sm={businessAccount ? 12 : 6} xs={12}>
                    <Field
                      id="bankName"
                      name="bankName"
                      label="Banco"
                      component={Input}
                      value={bankName}
                      error={props.errors.bankName}
                      helperText={props.errors.bankName}
                      onChange={onTextChange(fieldChange, props.handleChange)}
                    />
                  </Grid>
                  {!businessAccount && (
                    <Grid item sm={6} xs={12}>
                      <Status
                        value={status}
                        isDisabled={businessAccount}
                        onChange={onSelectChange(fieldChange, props.setFieldValue)}
                      />
                    </Grid>
                  )}
                </Grid>
                <Grid container spacing={2}>
                  <Grid item sm={6} xs={12}>
                    <Field
                      id="bankAgency"
                      type="number"
                      label="Agência"
                      placeholder="0000"
                      component={Input}
                      value={bankAgency}
                      error={props.errors.bankAgency}
                      helperText={props.errors.bankAgency}
                      onChange={onTextChange(fieldChange, props.handleChange)}
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <Field
                      id="bankAccount"
                      type="number"
                      label="Conta"
                      placeholder="00000-0"
                      component={Input}
                      value={bankAccount}
                      error={props.errors.bankAccount}
                      helperText={props.errors.bankAccount}
                      onChange={onTextChange(fieldChange, props.handleChange)}
                    />
                  </Grid>
                </Grid>
                {!businessAccount && (
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Select
                        multiple
                        name="establishmentsUuid"
                        label="Selecione o estabelecimento"
                        options={availableEstablishments}
                        value={establishmentsUuid}
                        error={props.errors.establishmentsUuid}
                        helperText={props.errors.establishmentsUuid}
                        onChange={onSelectChange(fieldChange, props.setFieldValue)}
                      />
                    </Grid>
                  </Grid>
                )}
                <MuiDialogActions>
                  <Button
                    color="default"
                    loading={isLoading}
                    onClick={this.handleModal}
                  >
                    Cancelar
                  </Button>
                  <Button type="submit" color="primary" loading={isLoading}>
                    {accountId ? 'Salvar' : 'Adicionar'}
                  </Button>
                </MuiDialogActions>
              </Form>
            </Modal>
          </Loader>
        )}
      </Validator>
    );
  }
}

AccountRegistration.propTypes = {
  accountId: PropTypes.number.isRequired,
  mainAccount: PropTypes.number.isRequired,
  accountName: PropTypes.string.isRequired,
  accountTypeId: PropTypes.number.isRequired,
  bankName: PropTypes.string.isRequired,
  bankAgency: PropTypes.string.isRequired,
  bankAccount: PropTypes.string.isRequired,
  status: PropTypes.bool.isRequired,
  establishmentsUuid: PropTypes.arrayOf.isRequired,
  availableEstablishments: PropTypes.arrayOf.isRequired,
  fieldChange: PropTypes.func.isRequired,
  openRegistrationModal: PropTypes.func.isRequired,
  clearRegistration: PropTypes.func.isRequired,
  upsertAccount: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
  ...state.accounts,
  page: state.pagination.activePage,
  pageSize: state.pagination.size,
  availableEstablishments: formatAvailableEstablishmentsToUuid(state.login.establishments),
  userId: state.profile.userId,
  establishmentId: state.businessInfo.establishmentId
});

const mapDispatchToProps = {
  upsertAccount,
  fieldChange: registerFieldChange,
  openRegistrationModal,
  clearRegistration
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountRegistration);