import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Grid, Dialog, Typography } from '@material-ui/core';
import Button from 'components/Button';
import LoaderLabel from 'components/LoaderLabel';

import { setIsInitialOnboardingOpen } from '../store/actions';

import { relativePercentage } from '../../../helpers/common';

export default function InitialOnboarding() {
  const dispatch = useDispatch();

  const { isInitialOnboardingOpen } = useSelector(state => state.onboarding);

  const COUNTDOWN_INITIAL_VALUE = 35;
  const [countdown, setCountdown] = useState(COUNTDOWN_INITIAL_VALUE);

  const isJumpPark =
    window.location.hostname == 'localhost' ||
    window.location.hostname == 'teste-admin.jumppark.com.br' ||
    window.location.hostname == 'admin.jumppark.com.br';

  useEffect(() => {
    if(countdown > 0) {
      setTimeout(() => {
        setCountdown(countdown - 1);
      }, 1000);
    }
  }, [countdown]);

  return(
    <Dialog
      fullWidth
      open={isInitialOnboardingOpen}
      maxWidth='md'
      scroll='body'
    >
      <Grid container style={{ padding: 15 }}>
        <Grid
          xs={12}
          style={{ display: 'flex', justifyContent: 'center' }}
        >
          <Typography
            variant='h5'
            color='primary'
            style={{ padding: '10px 0px 15px 0px' }}
          >
            É necessário assistir este vídeo para prosseguir
          </Typography>
        </Grid>
        <Grid xs={12}>
          <iframe
            width='100%'
            height={(window.innerHeight / 2)}
            src={
              isJumpPark
                ? 'https://www.youtube.com/embed/0Wb_-AOvf1U?autoplay=1&mute=0'
                : 'https://www.youtube.com/embed/OZTvMAHxCr4?autoplay=1&mute=0'
            }
            frameBorder='0'
            allow='autoplay; encrypted-media'
            allowFullScreen
          />
        </Grid>
        <Grid container style={{ marginTop: 15 }}>
          <Grid xs={6}>
            <Grid container justify='flex-start'>
              {(countdown > 0) && (
                <LoaderLabel value={relativePercentage(countdown, COUNTDOWN_INITIAL_VALUE)}>
                  {countdown}
                </LoaderLabel>
              )}
            </Grid>
          </Grid>
          <Grid xs={6}>
            <Grid container justify='flex-end'>
              <Button
                color="success"
                disabled={countdown > 0}
                onClick={() => dispatch(setIsInitialOnboardingOpen(false))}
              >
                Iniciar cadastro
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
}