import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import moment from 'moment';
import QRCode from 'qrcode';

import Modal from 'components/Modal';
import Loader from 'components/Loader';
import Button from 'components/Button';
import { Paper, Typography, Table, TableBody, TableCell, TableHead, TableRow, IconButton } from '@material-ui/core';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faQrcode, faBarcode } from '@fortawesome/free-solid-svg-icons';

import {
  fetchUsersSituation,
  fetchSuperLogicaInvoices,
  removeSuperLogicaDebitWarning
} from '../../../components/Filter/store/services';

import { generateToken } from '../../../components/Filter/store/services';
import { convertToReal } from 'helpers/converters';

import { useStyles } from './styles';

const clientAreaIcon = require('../../../assets/img/icon_client_area.svg');

const headers = [
  { title: 'Status', field: 'status', sortable: false },
  { title: 'Empresa', field: 'name', sortable: false },
  { title: 'CNPJ', field: 'documentId', sortable: false },
  { title: 'Ações' }
];

const superLogicaTableHeadersDesktop = [
  { title: 'Vencimento', field: 'dueDate' },
  { title: 'Valor', field: 'amount' },
  { title: 'Ações' },
  { title: '', field: '' }
];

const superLogicaTableHeadersMobile = [
  { title: 'Ações' },
  { title: 'Vencimento', field: 'dueDate' },
  { title: 'Valor', field: 'amount' },
  { title: '', field: '' }
];

const maskCNPJ = v => {
  v = v?.replace(/\D/g, '');

  let len = v?.length;

  if (len < 12) {
    v = v
      ?.replace(/(\d{3})(\d)/, '$1.$2')
      ?.replace(/(\d{3})(\d)/, '$1.$2')
      ?.replace(/(\d{3})(\d{1,2})$/, '$1-$2');
  } else {
    v = v
      ?.replace(/^(\d{2})(\d)/, '$1.$2')
      ?.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3')
      ?.replace(/\.(\d{3})(\d)/, '.$1/$2')
      ?.replace(/(\d{4})(\d)/, '$1-$2');
  }

  return v?.substring(0, 18);
}

export default function ListClients() {
  const classes = useStyles();

  const history = useHistory();

  const width = window.innerWidth;
  const isMobile = width < 728;
  const isDesktop = width >= 728;

  const [situationEstablishments, setSituationEstablishments] = useState([]);
  const [superLogica, setSuperLogica] = useState([]);
  const [superLogicaInvoices, setSuperLogicaInvoices] = useState([]);
  const [isClientMessageVisible, setIsClientMessageVisible] = useState(false);

  const [mainUser, setMainUser] = useState({ mainUserId: '', mainUserToken: '' });
  const [pixPaymentModalData, setPixPaymentModalData] = useState({});
  const [isPixPaymentModalOpen, setIsPixPaymentModalOpen] = useState(false);
  const [isRemoveDebitWarningModalOpen, setIsRemoveDebitWarningModalOpen] = useState(false);
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(true);

  const [loading, setLoading] = useState(false);

  const { token } = useSelector(state => state.login);
  const { userId } = useSelector(state => state.profile);
  const { establishmentId } = useSelector(state => state.businessInfo);
  const { profileId } = useSelector(state => state.profile.submit);

  const isUserAdmin = +profileId == 1;
  const isDebitWarning = situationEstablishments?.some(establishment => +establishment?.debitWarning == 1);

  const renderStatus = status => {
    if(+status == 1) {
      return <p style={{ color: 'blue' }}>Em dia</p>;
    }

    return <p style={{ color: 'red' }}>Atrasado</p>;
  }

  useEffect(() => {
    (async () => {
      setIsClientMessageVisible(false);

      if(isUserAdmin && !establishmentId) {
        setLoading(false);
        setIsClientMessageVisible(true);
        return toast.error('Selecione um estabelecimento para acessar essa área.');
      }

      setLoading(true);

      const { data } = await fetchSuperLogicaInvoices(isUserAdmin, userId, establishmentId)
        .finally(() => setLoading(false));

      const formattedClients = data?.clients?.map(client => {
        return {
          ...client,
          isOpen: true
        }
      });

      setMainUser({ mainUserId: data?.mainUserId, mainUserToken: data?.mainUserToken });
      setSuperLogica(formattedClients || []);
      setSuperLogicaInvoices(data?.invoices || []);

      if(data?.clients?.length == 0) {
        setIsClientMessageVisible(true);
      }
    })();
  }, [establishmentId]);

  useEffect(() => {
    if(isPixPaymentModalOpen) {
      getPixQrCode();
    }
  }, [isPixPaymentModalOpen]);

  const getData = async () => {
    if(isUserAdmin) {
      return setLoading(false);
    }

    setLoading(true);

    const { data } = await fetchUsersSituation(userId, token)
      .finally(() => setLoading(false));

    setSituationEstablishments(data?.establishments || []);
  }

  useEffect(() => {
    if(userId) {
      getData();
    }
  }, []);

  const closeSuperLogicaTable = (event, row) => {
    event.stopPropagation();

    const { superlogicaId, isOpen } = row;

    const doesClientHaveInvoice = superLogicaInvoices?.filter(invoice => +invoice?.superlogicaId == +superlogicaId).length > 0;

    if(!doesClientHaveInvoice) {
      return toast.warn('Nenhuma fatura encontrada para este CNPJ.');
    }

    const newSuperLogica = [...superLogica];

    const selectedTable = superLogica?.find(superlogica => +superlogica.superlogicaId == superlogicaId);
    const selectedTableIndex = superLogica.indexOf(selectedTable);

    const updatedSuperLogicaTable = {
      ...selectedTable,
      isOpen: !isOpen
    }

    newSuperLogica[selectedTableIndex] = updatedSuperLogicaTable;

    return setSuperLogica(newSuperLogica);
  }

  const openSuperLogicaExternal = async (event, row) => {
    event.stopPropagation();

    const { superlogicaId, email } = row;

    const { data } = await generateToken(userId, token, { superlogicaId, email });

    if(!data) {
      return toast.error('Houve um problema ao acessar a área do cliente.');
    }

    const url = `https://jumptecnologia.superlogica.net/clients/areadocliente?token=${data}`;
    window.open(url, 'targetblank');
  }

  const handleAccessPayment = row => {
    setPixPaymentModalData(row);
    setIsPixPaymentModalOpen(true);
  }

  const handlePayInvoice = row => {
    const { invoiceLink } = row;
    return window.open(invoiceLink, '_blank');
  }

  const getPixQrCode = async () => {
    const qrCode = await QRCode.toDataURL(pixPaymentModalData?.pixCode);

    setPixPaymentModalData({
      ...pixPaymentModalData,
      pixQrCode: qrCode
    });
  }

  const removeDebitWarning = () => {
    return removeSuperLogicaDebitWarning(mainUser.mainUserId, mainUser.mainUserToken)
      .then(() => {
        setIsRemoveDebitWarningModalOpen(true);
        setIsPixPaymentModalOpen(false);
        getData();
      })
      .catch(() => toast.error('Houve um problama ao remover o lembrete de débito.'));
  }

  return(
    <div>
      {(!!history.location.state?.data?.noAccessCode && isPaymentModalOpen) && (
        <Modal
          scroll="body"
          title="Pagamento em atraso"
          open={!!history.location.state?.data?.noAccessCode && isPaymentModalOpen}
          onClose={() => setIsPaymentModalOpen(false)}
        >
          <Typography>Boleto com vencimento atrasado. Por favor pague e envie o comprovante para o financeiro para continuarmos com a parceria.</Typography>
        </Modal>
      )}
      {isRemoveDebitWarningModalOpen && (
        <Modal
          scroll="body"
          title="Lembrete de débito"
          open={isRemoveDebitWarningModalOpen}
          onClose={() => setIsRemoveDebitWarningModalOpen(false)}
        >
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
            <Typography variant='h5' color='primary'>Lembrete de débito retirado!</Typography>
            <Typography variant='h6' color='primary' style={{ margin: '20px 0 20px 0' }}>Se não identificarmos o pagamento em até 3 dias úteis, o sistema será desativado.</Typography>
            <Typography variant='h6' color='primary' style={{ margin: '0 0 20px 0' }}>Não se preocupe em enviar o comprovante, o sistema acusa automaticamente o seu pagamento.</Typography>
            <Typography variant='h5' color='primary'>Obrigado pela parceria!</Typography>
            <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%', marginTop: 20 }}>
              <Button
                color='success'
                onClick={() => setIsRemoveDebitWarningModalOpen(false)}
              >
                Ok, obrigado
              </Button>
            </div>
          </div>
        </Modal>
      )}
      {isPixPaymentModalOpen && (
        <Modal
          scroll="body"
          title="Pagamento"
          open={isPixPaymentModalOpen}
          onClose={() => setIsPixPaymentModalOpen(false)}
        >
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
            <Typography variant='h6' color='primary'>Pague a fatura referente ao CPF/CNPJ</Typography>
            <Typography variant='h6' color='primary'>{maskCNPJ(pixPaymentModalData?.documentId)}</Typography>
            <Typography variant='h6' color='primary' style={{ margin: '20px 0 20px 0' }}>Leia o QR Code ou copie e cole o código PIX</Typography>
            <Typography variant='h6' color='primary'>Valor: {convertToReal(pixPaymentModalData?.amount)}</Typography>
            {(!!pixPaymentModalData?.pixCode && !!pixPaymentModalData?.pixQrCode) && (
              <div>
                <img
                  width='300px'
                  height='300px'
                  src={pixPaymentModalData?.pixQrCode}
                />
              </div>
            )}
            <div style={{ display: 'flex', alignItems: 'center', gap: '20px', width: '100%', marginTop: 20 }}>
              <div style={{ display: 'flex', width: '80%' }}>
                <Typography style={{ wordBreak: 'break-all' }}>{pixPaymentModalData?.pixCode}</Typography>
              </div>
              <div style={{ display: 'flex', width: '20%' }}>
                <div
                  style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', cursor: 'pointer' }}
                  onClick={() => {
                    navigator.clipboard.writeText(pixPaymentModalData?.pixCode);
                    return toast.success('Código PIX copiado!');
                  }}
                >
                  <FontAwesomeIcon
                    icon={faCopy}
                    color="#7a7a7a"
                    size="lg"
                  />
                  <Typography color="secondary">Copiar PIX</Typography>
                </div>
              </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', width: '100%', marginTop: 20 }}>
              <Typography><b>O pagamento demora até 2 dias úteis para ser identificado.</b></Typography>
            </div>
            {(isDebitWarning || isUserAdmin) && (
              <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%', marginTop: 20 }}>
                <Button color='success' onClick={removeDebitWarning}>
                  Paguei
                </Button>
              </div>
            )}
          </div>
        </Modal>
      )}
      {isClientMessageVisible && (
        <Paper
          style={{
            width: '400px',
            padding: '35px',
            margin: '0 auto',
            fontSize: '25px'
          }}
        >
          <p>Aqui é a sua área !</p>
          <p>
            Assim que firmarmos nossa parceria, este será o lugar onde voce verá
            suas faturas e as respectivas notas fiscais.
          </p>
        </Paper>
      )}
      <Paper>
        <Loader isLoading={loading}>
          {(superLogica.length > 0) && (
            <div className={classes.table}>
              <Table>
                <TableHead className={classes.tableHead}>
                  <TableRow>
                    {headers?.map(item => {
                      return <TableCell className={classes.tableHeadText}>{item?.title}</TableCell>
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {superLogica?.length > 0 && superLogica?.map(superlogica => {
                    return(
                      <Loader isLoading={loading}>
                        <TableRow onClick={event => closeSuperLogicaTable(event, superlogica)}>
                          <TableCell className={classes.tableRowText}>{renderStatus(superlogica?.status)}</TableCell>
                          <TableCell className={classes.tableRowText}>{superlogica?.name}</TableCell>
                          <TableCell className={classes.tableRowText}>{maskCNPJ(superlogica?.documentId)}</TableCell>
                          <TableCell className={classes.tableRowText}>
                            <IconButton
                              size='small'
                              onClick={event => openSuperLogicaExternal(event, superlogica)}
                            >
                              <img src={clientAreaIcon} />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                        {
                          superlogica?.isOpen &&
                          (superLogicaInvoices?.filter(invoice => +invoice?.superlogicaId == +superlogica?.superlogicaId).length > 0) && (
                          <>
                            {isDesktop && (
                              <TableRow className={classes.tableHeadSuperlogica}>
                                {superLogicaTableHeadersDesktop?.map(item => {
                                  return <TableCell className={classes.tableHeadTextSuperlogica}>{item?.title}</TableCell>
                                })}
                              </TableRow>
                            )}
                            {isMobile && (
                              <TableRow className={classes.tableHeadSuperlogica}>
                                {superLogicaTableHeadersMobile?.map(item => {
                                  return <TableCell className={classes.tableHeadTextSuperlogica}>{item?.title}</TableCell>
                                })}
                              </TableRow>
                            )}
                            {superLogicaInvoices?.filter(invoice => +invoice?.superlogicaId == +superlogica?.superlogicaId)?.map(item => {
                              return(
                                <TableRow className={classes.tableHeadSuperlogica}>
                                  {isMobile && (
                                    <TableCell className={classes.tableHeadTextSuperlogica}>
                                      {!!item?.pixCode && (
                                        <IconButton
                                          style={{ marginRight: 15 }}
                                          size='small'
                                          onClick={() => handleAccessPayment({ ...item, ...superlogica })}
                                        >
                                          <FontAwesomeIcon
                                            icon={faQrcode}
                                            color="#022A5C"
                                            size="md"
                                          />
                                        </IconButton>
                                      )}
                                      <IconButton
                                        size='small'
                                        onClick={() => handlePayInvoice(item)}
                                      >
                                        <FontAwesomeIcon
                                          icon={faBarcode}
                                          color="#022A5C"
                                          size="md"
                                        />
                                      </IconButton>
                                    </TableCell>
                                  )}
                                  <TableCell className={classes.tableHeadTextSuperlogica}>{moment(item?.dueDate).format('DD/MM/YYYY')}</TableCell>
                                  <TableCell className={classes.tableHeadTextSuperlogica}>{convertToReal(item?.amount)}</TableCell>
                                  {isDesktop && (  
                                    <TableCell className={classes.tableHeadTextSuperlogica}>
                                      {!!item?.pixCode && (
                                        <IconButton
                                          style={{ marginRight: 10 }}
                                          size='small'
                                          onClick={() => handleAccessPayment({ ...item, ...superlogica })}
                                        >
                                          <FontAwesomeIcon
                                            icon={faQrcode}
                                            color="#022A5C"
                                            size="md"
                                          />
                                        </IconButton>
                                      )}
                                      <IconButton
                                        size='small'
                                        onClick={() => handlePayInvoice(item)}
                                      >
                                        <FontAwesomeIcon
                                          icon={faBarcode}
                                          color="#022A5C"
                                          size="md"
                                        />
                                      </IconButton>
                                    </TableCell>
                                  )}
                                  <TableCell className={classes.tableHeadTextSuperlogica} />
                                </TableRow>
                              );
                            })}
                          </>
                        )}
                      </Loader>
                    );
                  })}
                </TableBody>
              </Table>
            </div>
          )}
        </Loader>
      </Paper>
    </div>
  );
}