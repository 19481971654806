import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { compose, prop, pick, assoc, over, lensProp } from 'ramda';
import { Formik, Form } from 'formik';
import { toast } from 'react-toastify';

import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Badge from '@material-ui/core/Badge';
import Collapse from '@material-ui/core/Collapse';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MuiDialogActions from '@material-ui/core/DialogActions';

import Modal from '../Modal';
import Loader from '../Loader';
import Select from '../Select';
import Input from '../Input';
import Button from '../Button';
import ButtonConfirmation from '../ButtonConfirmation';
import ExportPDF from '../ExportPDF';
import SingleDatePicker from '../SingleDatePicker';

import { fetchUsersSituation } from 'components/Filter/store/services';
import { sendNfse } from 'pages/ProvisionalReceiptConfig/store/thunk';
import { loadNfseConcept } from 'pages/ProvisionalReceipt/store/thunk';
import { setRpsInfo } from 'pages/ProvisionalReceipt/store/actions';
import { loadPaymentMethods } from 'pages/PaymentMethods/store/thunk';
import { closeInvoice } from '../../pages/ClientList/store/services';
import { handlePaying } from '../../pages/ClientInvoices/store/actions';
import { fetchClientInvoiceServiceOrders } from '../../pages/ClientInvoices/store/services';
import { loadInvoiceDetails, loadReceiptInvoicePrint, upsertInvoice, payInvoice, undoPayment, emailInvoice } from '../../pages/ClientInvoices/store/thunk';
import { convertAmountToDB, convertInvoicePrint, convertToEmptyString, formatCurrency, convertDateToDB, convertDateAndTimeToDB, convertFormattedMoneyToNumber, convertToBrl } from '../../helpers/converters';

import { validations } from './validations';

const StyledBadge = withStyles(() => ({
  badge: {
    right: -50,
    top: 14,
    padding: '14px 10px',
    fontSize: '15px',
    color: '#FFFFFF',
    borderRadius: '5px'
  }
}))(Badge);

const useStyles = makeStyles({
  success: { backgroundColor: '#4caf50' },
  danger: { backgroundColor: '#f44336' },
  warning: { backgroundColor: '#ff9800' },
  textInfo: {
    margin: 0,
    color: '#f44336',
    marginTop: 10
  }
});

export default function InvoicePos({ isOpen, setIsOpenInvoicePos, establishmentId, pageOrigin, transactionsQp, transactionsPage, transactionsPageSize }) {
  const dispatch = useDispatch();

  const classes = useStyles();

  const width = window.innerWidth;
  const isMobile = width < 728;

  const [isOpenConfirmed, setIsOpenConfirmed] = useState(false);
  const [isClientPaying, setIsClientPaying] = useState(false);
  const [isCancelingPayment, setIsCancelingPayment] = useState(false);
  const [toggleRecebido, setToggleRecebido] = useState(false);
  const [closingDateTimeLastDate, setClosingDateTimeLastDate] = useState(null);

  const { userId } = useSelector(state => state.profile);
  const { token } = useSelector(state => state.login);
  const { clientId } = useSelector(state => state.clientRegistration.basicInfo);
  const { activePage: page, size: pageSize } = useSelector(state => state.pagination);
  const { qp } = useSelector(state => state.filters);
  const { startDate, endDate } = useSelector(state => state.dateFilter);
  const { clientName } = useSelector(state => state.clientInvoices.submit);
  const { penalty, penaltyType } = useSelector(state => state.clientRegistration.charges.submit);
  const { isSendNfseLoading } = useSelector(state => state.provisionalReceiptConfig);

  const availablePaymentMethods = [
    {
      label: '--',
      value: ''
    },
    ...useSelector(state => state.paymentMethods.list)?.map(paymentMethod => {
      return {
        label: paymentMethod?.paymentMethodName,
        value: paymentMethod?.establishmentPaymentMethodId
      }
    })
  ];

  const {
    isDetailing,
    isPaying,
    isEmailing,
    isCanceling,
    invoiceDateTime,
    submit,
    submit: {
      situationId,
      invoiceSituation,
      opened,
      paymentDateTime,
      closingDateTime,
      closingUserName,
      dueDateTime,
      userOutputName,
      transactionId
    }
  } = useSelector(state => state.clientInvoices);

  const {
    sourceId,
    isNfInfo,
    nfInfo: {
      rpsSeries,
      rpsNumber,
      nfNumber
    }
  } = useSelector(state => state.provisionalReceipt);

  const [taxSettings, setTaxSettings] = useState(0);

  const handlePayment = (
    {
      amount,
      amountReceived,
      paymentMethod,
      paymentDateTime,
      dueDateTime,
      closingDateTime,
      currentAmount,
      isUpdate
    },
    { resetForm }
  ) => {
    const convertDateToDB = date => `${date} 00:00:01`;

    const handleAmountToSend = amount =>
      amountReceived ? convertAmountToDB(amountReceived) : convertAmountToDB(amount);

    const params = toggleRecebido
      ? {
        amount: handleAmountToSend(handleAmountToSend(amount)),
        paymentDateTime,
        paymentMethod
      }
      : {
        situationId,
        amount: convertAmountToDB(currentAmount),
        dueDateTime: convertDateToDB(dueDateTime)
      }

    const properties = {
      userId,
      establishmentId,
      clientId,
      invoiceDateTime,
      page,
      pageSize,
      startDate,
      endDate,
      pageOrigin,
      qp
    }

    if(isUpdate) {
      const updateParams = {
        closingDateTime: moment(closingDateTime).format('YYYY-MM-DD HH:mm:ss'),
        dueDateTime: convertDateToDB(dueDateTime)
      }

      return dispatch(upsertInvoice(updateParams, properties)).then(error => {
        if(!error) {
          resetForm();
          setIsOpenInvoicePos(false);
        }
      });
    }

    if(toggleRecebido) {
      dispatch(payInvoice(params, properties));
      setIsOpenInvoicePos(false);
    } else {
      dispatch(upsertInvoice(params, properties)).then(error => {
        if(!error) {
          resetForm();
        }
      });
    }
  }

  const updateDueDateTime = (dueDateTime) => {
    const properties = {
      userId,
      establishmentId,
      clientId,
      invoiceDateTime,
      page,
      pageSize,
      startDate,
      endDate,
      pageOrigin,
      qp
    }

    const updateParams = {
      dueDateTime
    }

    return dispatch(upsertInvoice(updateParams, properties)).then(error => {
      if(!error) {
        setIsOpenInvoicePos(false);
      }
    });
  }

  useEffect(() => {
    if(situationId === 2 || opened === 0) {
      setToggleRecebido(true);
    } else {
      setToggleRecebido(false);
    }
  }, [situationId, opened]);

  useEffect(() => {
    (async () => {
      if(submit?.establishmentId) {
        const { data } = await fetchUsersSituation(userId, token);

        const establishmentTaxSettings = data?.establishments
          ?.find(establishment => +establishment?.establishmentId == +submit?.establishmentId)
          ?.taxSettings;

        setTaxSettings(establishmentTaxSettings);
      }
    })();
  }, [submit?.establishmentId]);

  useEffect(() => {
    dispatch(setRpsInfo({
      rpsSeries: '',
      rpsNumber: '',
      nfNumber: '',
      nfStatus: ''
    }));

    const id = `${clientId}${invoiceDateTime}`;

    if(+taxSettings == 1 && !!transactionId && (+situationId == 2 || +situationId == 3)) {
      if(sourceId || id) {
        dispatch(loadNfseConcept(userId, establishmentId, sourceId || id));
      }
    }
  }, [taxSettings, transactionId, situationId]);

  const handleSendInvoice = ({ email }) => {
    dispatch(emailInvoice(userId, establishmentId, clientId, invoiceDateTime, { email }));
  }

  const handleSubmit = (values, actions) => {
    if(!moment(values.paymentDateTime).isValid()) {
      return toast.error('Selecione uma data de pagamento válida.');
    }

    if(isCancelingPayment) {
      return handleCancelInvoice(values, actions);
    }

    if(isEmailing) {
      return dispatch(handleSendInvoice(values, actions));
    }

    const paymentDateTime =
      values.paymentDateTime == '0001-01-01' ||
      values.paymentDateTime == '0001-01-01 00:00:00' ||
      values.paymentDateTime == 'Data inválida' ||
      values.paymentDateTime == 'Invalid date' ||
      moment().isSame(values.paymentDateTime, 'day')
        ? moment().format('YYYY-MM-DD HH:mm:ss')
        : values.paymentDateTime;

    const params = {
      ...values,
      paymentDateTime: convertDateAndTimeToDB(paymentDateTime),
      isUpdate: opened == 1
    }

    return dispatch(handlePayment(params, actions));
  }

  const handleCancelInvoice = ({ reason, userPassword }, { resetForm }) => {
    const params = {
      situationId: 1,
      reason,
      userPassword
    }

    const properties = {
      userId,
      establishmentId,
      clientId,
      invoiceDateTime,
      page: pageOrigin == 'transactions' ? transactionsPage : page,
      pageSize: pageOrigin == 'transactions' ? transactionsPageSize : pageSize,
      startDate,
      endDate,
      pageOrigin,
      qp: pageOrigin == 'transactions' ? transactionsQp : qp
    }

    return dispatch(undoPayment(params, properties)).then(error => {
      if(!error) {
        setIsCancelingPayment(false);
        resetForm();
      }
    });
  }

  const handleLoadInvoiceDetails = () => {
    return dispatch(loadInvoiceDetails(userId, establishmentId, clientId, invoiceDateTime));
  }

  const handlePrintInvoice = () => {
    const params = {
      userId,
      establishmentId,
      clientId,
      invoiceDateTime
    }

    return dispatch(loadReceiptInvoicePrint(params));
  }

  const handleLoadReceiptServiceOrderInvoicePos = () => {
    const params = {
      userId,
      establishmentId,
      clientId,
      invoiceDateTime
    }

    return fetchClientInvoiceServiceOrders(params);
  }

  const togglePaymentOptions = value => {
    if(!value) {
      setIsOpenConfirmed(true);
    } else {
      dispatch(handlePaying());
    }
  }

  useEffect(() => {
    if(opened === 1) {
      togglePaymentOptions(true);
    }

    handleLoadInvoiceDetails();
    dispatch(loadPaymentMethods({ userId, establishmentId }));
  }, []);

  const handleSaveButton = () => {
    if(toggleRecebido) {
      return 'Pagar fatura';
    }

    return 'Salvar';
  }

  const closeInvoiceFunction = (clientId, invoiceDateTime) => {
    closeInvoice(userId, establishmentId, clientId, invoiceDateTime).then(() => {
      setIsOpenConfirmed(false);
      handleLoadInvoiceDetails();
    });
  }

  const getClosingDateTime = (dates) => {
    const validDate = dates?.filter(date => !!date)[0];

    if(validDate) {
      return moment(validDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
    }

    return moment().format('YYYY-MM-DD');
  }

  const badgeColor = () => {
    if(invoiceSituation === 'Cancelada') {
      return classes.danger;
    }

    if(invoiceSituation === 'Paga') {
      return classes.success;
    }

    return classes.warning;
  }

  const labelStatus = () => {
    if(isMobile) {
      return(
        <div>
          <Typography
            variant='h6'
            style={{ marginLeft: 8 }}
          >
            Detalhamento da fatura
          </Typography>
          <StyledBadge
            badgeContent={invoiceSituation}
            classes={{ badge: badgeColor() }}
            style={{ marginBottom: 20 }}
          />
        </div>
      );
    }

    if(!isMobile) {
      return(
        <div>
          <StyledBadge
            badgeContent={invoiceSituation}
            classes={{ badge: badgeColor() }}
          >
            Detalhamento da fatura
          </StyledBadge>
        </div>
      );
    }
  }

  const convertValueFinal = (amount, closingAmount) => {
    const amountSlice = amount.slice(3);
    const amountFinal = amountSlice.replace(',', '.');
    const valueFinal = Number(closingAmount) + Number(amountFinal);
    return formatCurrency(valueFinal);
  }

  const initialValues = compose(
    over(lensProp('paymentMethod'), convertToEmptyString),
    assoc('opened', opened),
    assoc('isCanceling', isCanceling),
    assoc('isCancelingPayment', isCancelingPayment),
    assoc('isEmailing', isEmailing),
    assoc('isPaying', isPaying),
    pick([
      'dueDateTime',
      'closingDateTime',
      'currentDueDateTime',
      'paymentMethod',
      'paymentDateTime',
      'amount',
      'closingAmount',
      'amountReceived',
      'currentAmount',
      'email',
      'reason',
      'userPassword',
      'clientName'
    ]),
    prop('submit')
  );

  const invoicePaid = invoiceSituation === 'Paga' || invoiceSituation === 'Cancelada';
  const isInvoiceClosed = invoiceSituation === 'Paga' || isClientPaying;
  const isInvoiceOpened = opened === 1;
  const isInvoiceClosedAndOpened = opened != 1 && invoiceSituation != 'Paga';

  return(
    <>
      <Modal
        id="accounts"
        title={labelStatus()}
        scroll="body"
        maxWidth="md"
        open={isOpen}
        onClose={() => setIsOpenInvoicePos(false)}
      >
        <Formik
          enableReinitialize
          initialValues={initialValues(useSelector(state => state.clientInvoices))}
          validationSchema={validations}
          onSubmit={handleSubmit}
        >
          {({ ...formikProps }) => {
            return(
              <>
                <Modal
                  title="Tem certeza que deseja fechar esta fatura ?"
                  scroll="body"
                  maxWidth="md"
                  open={isOpenConfirmed}
                  onClose={() => setIsOpenConfirmed(false)}
                >
                  <p>Está ação não pode ser desfeita.</p>
                  <MuiDialogActions>
                    <Button
                      color="error"
                      onClick={() => setIsOpenConfirmed(false)}
                    >
                      Cancelar
                    </Button>
                    <Button
                      color="success"
                      className="mr-2"
                      onClick={() => closeInvoiceFunction(clientId, invoiceDateTime)}
                    >
                      Confirmar
                    </Button>
                  </MuiDialogActions>
                </Modal>
                <Form>
                  <Loader isLoading={isDetailing}>
                    <Grid container spacing={2}>
                      <Grid item md={6} xs={12}>
                        <Input
                          disabled
                          id="clientName"
                          name="clientName"
                          label="Cliente"
                          value={clientName || formikProps.values.clientName}
                        />
                      </Grid>
                      <Grid item md={3} xs={12}>
                        <SingleDatePicker
                          name="closingDateTime"
                          label="Data de fechamento"
                          disabled={invoicePaid || isInvoiceClosedAndOpened}
                          initialDate={() => getClosingDateTime([closingDateTimeLastDate, closingDateTime, formikProps.values.closingDateTime])}
                          blockPastDates
                          onBlockedDate={() => toast.error('A data de fechamento da fatura não pode ser menor ou igual a data de hoje.')}
                          onDateChange={date => {
                            formikProps.setFieldValue('closingDateTime', convertDateToDB(date));
                            setClosingDateTimeLastDate(convertDateToDB(date));
                          }}
                        />
                      </Grid>
                      <Grid item md={3} xs={12}>
                        <SingleDatePicker
                          name="dueDateTime"
                          label="Data de vencimento"
                          disabled={isInvoiceClosed || invoicePaid || (+opened != 1)}
                          initialDate={dueDateTime || formikProps.values.dueDateTime}
                          blockPastDates={opened == 1}
                          onBlockedDate={() => toast.error('A data de vencimento da fatura não pode ser menor do que a data de hoje.')}
                          onDateChange={date => formikProps.setFieldValue('dueDateTime', convertDateToDB(date))}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item md={3} xs={12}>
                        <Input
                          disabled
                          id="closingAmount"
                          name="closingAmount"
                          label="Valor acumulado"
                          type="money"
                          value={convertToBrl(convertFormattedMoneyToNumber(formikProps.values.closingAmount))}
                        />
                      </Grid>
                      <Grid item md={3} xs={12}>
                        <Input
                          disabled
                          id="amount"
                          name="amount"
                          label="Valor fixo"
                          type="money"
                          value={convertToBrl(convertFormattedMoneyToNumber(formikProps.values.amount))}
                        />
                      </Grid>
                      <Grid item md={3} xs={12}>
                        <Input
                          disabled
                          id="penaltyType"
                          name="penaltyType"
                          label={penaltyType === 1 ? 'Multa (R$)' : 'Multa (%)'}
                          value={penalty}
                        />
                      </Grid>
                      <Grid item md={3} xs={12}>
                        <Input
                          disabled
                          id="currentAmount"
                          name="currentAmount"
                          label="Valor"
                          type="money"
                          value={convertToBrl(convertFormattedMoneyToNumber(formikProps.values.amount) + convertFormattedMoneyToNumber(formikProps.values.closingAmount))}
                        />
                      </Grid>
                    </Grid>
                    <hr />
                    <Grid container spacing={2}>
                      {opened === 1 ? (
                        <>
                          <Grid item md={3} xs={6}>
                            <FormControlLabel
                              id="invoiceSituation"
                              name="invoiceSituation"
                              label="Fechar fatura"
                              control={
                                <Switch
                                  color="primary"
                                  disabled={
                                    formikProps.values.currentDueDateTime !==
                                    formikProps.values.dueDateTime ||
                                    invoicePaid
                                  }
                                  onChange={() => {
                                    togglePaymentOptions(formikProps.values.isPaying);
                                    setIsClientPaying(formikProps.values.isPaying);
                                  }}
                                  checked={
                                    formikProps.values.isPaying ||
                                    invoiceSituation === 'Paga'
                                  }
                                />
                              }
                            />
                          </Grid>
                        </>
                      ) : (
                        <>
                          <Grid item md={3} xs={6}>
                            <FormControlLabel
                              id="invoiceSituation"
                              name="invoiceSituation"
                              label="Fatura Fechada"
                              disabled
                              control={<Switch color="primary" checked />}
                            />
                          </Grid>
                          <Grid
                            item
                            md={9}
                            xs={12}
                            style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
                          >
                            {situationId === 2 && (
                              <Grid item>
                                <p>Recebido por {userOutputName} ás {moment(paymentDateTime).format('DD/MM/YYYY HH:mm')}</p>
                              </Grid>
                            )}
                            {(!!closingUserName && !!closingDateTime) && (
                              <p>Fechada por {closingUserName} no dia {closingDateTime?.split(' ')[0]} as {closingDateTime?.split(' ')[1]}</p>
                            )}
                            {(invoiceSituation === 'Paga' && rpsNumber > 0) && (
                              <Grid item>
                                <p>RPS: {rpsSeries}-{rpsNumber} | N˚ NFSe: {nfNumber}</p>
                              </Grid>
                            )}
                          </Grid>
                        </>
                      )}
                      {opened === 1 && (
                        <Grid item md={9} xs={6} styles={{ marginLeft: -50 }}>
                          <p className={classes.textInfo}>
                            <strong>Atenção</strong>: Fechamento da fatura{' '}
                            <u>não</u> pode ser desfeito.
                          </p>
                        </Grid>
                      )}
                    </Grid>
                    <Collapse in={toggleRecebido}>
                      <Grid container spacing={2}>
                        <Grid item md={3} xs={12}>
                          <SingleDatePicker
                            name="paymentDateTime"
                            label="Data do recebimento"
                            disabled={invoicePaid}
                            initialDate={
                              moment(paymentDateTime).format('DD/MM/YYYY') == '01/01/0001'
                                ? moment().format('YYYY-MM-DD')
                                : paymentDateTime
                            }
                            onDateChange={date => formikProps.setFieldValue('paymentDateTime', convertDateToDB(date))}
                          />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <Select
                            id="paymentMethod"
                            name="paymentMethod"
                            label="Selecione o meio de pagamento"
                            disabled={invoicePaid}
                            options={availablePaymentMethods}
                            error={formikProps.errors.paymentMethod}
                            helperText={formikProps.errors.paymentMethod}
                            value={formikProps.values.paymentMethod}
                            onChange={formikProps.handleChange}
                          />
                        </Grid>
                        <Grid item md={3} xs={12}>
                          <Input
                            id="amountReceived"
                            name="amountReceived"
                            type="money"
                            label="Valor recebido"
                            placeholder="R$ 0,00"
                            disabled={invoicePaid}
                            value={formikProps.values.amountReceived}
                            error={formikProps.errors.amountReceived}
                            helperText={formikProps.errors.amountReceived}
                            onChange={formikProps.handleChange}
                          />
                        </Grid>
                      </Grid>
                    </Collapse>
                    <Collapse in={isCancelingPayment}>
                      <Grid container spacing={2}>
                        <Grid item md={6} xs={12}>
                          <Input
                            id="reason"
                            label="Motivo do cancelamento"
                            error={formikProps.errors.reason}
                            helperText={formikProps.errors.reason}
                            onChange={formikProps.handleChange}
                          />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <Input
                            id="userPassword"
                            type="password"
                            label="Confirme sua senha"
                            error={formikProps.errors.userPassword}
                            helperText={formikProps.errors.userPassword}
                            onChange={formikProps.handleChange}
                          />
                        </Grid>
                      </Grid>
                    </Collapse>
                  </Loader>
                  <MuiDialogActions>
                    <div
                      style={isMobile ? { display: 'flex', flexDirection: 'column', width: '100%' } : {}}
                      className={isMobile ? '' : 'MuiDialogActions-spacing'}
                    >
                      {!isCancelingPayment && (
                        <>
                          <ExportPDF
                            style={isMobile ? { marginBottom: 5 } : {}}
                            type="button"
                            exportType="receiptInvoice"
                            color="primary"
                            fileName="fatura"
                            disabled={!situationId}
                            loading={isDetailing}
                            formatComponentData={convertInvoicePrint}
                            requestData={handlePrintInvoice}
                          >
                            Baixar fatura
                          </ExportPDF>
                          <ExportPDF
                            style={isMobile ? { marginBottom: 5 } : {}}
                            type="button"
                            exportType="receiptOsInvoice"
                            color="primary"
                            fileName="fatura_os"
                            requestData={handleLoadReceiptServiceOrderInvoicePos}
                          >
                            Extrato
                          </ExportPDF>
                        </>
                      )}
                      {(invoiceSituation == 'Paga' && !isCancelingPayment) && (
                        <Button
                          style={isMobile ? { marginBottom: 5 } : {}}
                          type="button"
                          color="error"
                          onClick={() => setIsCancelingPayment(true)}
                        >
                          Desfazer pagamento
                        </Button>
                      )}
                      {isCancelingPayment && (
                        <>
                          <Button
                            style={isMobile ? { marginBottom: 5 } : {}}
                            type="button"
                            color="error"
                            onClick={() => setIsCancelingPayment(false)}
                          >
                            Cancelar
                          </Button>
                          <Button
                            style={isMobile ? { marginBottom: 5 } : {}}
                            type="submit"
                            color="success"
                          >
                            Confirmar
                          </Button>
                        </>
                      )}
                      {(situationId !== 2 || invoiceSituation != 'Paga') && (
                        <>
                          {!isInvoiceOpened && (
                            <Button
                              style={isMobile ? { marginBottom: 5 } : {}}
                              type="button"
                              color="success"
                              onClick={() => updateDueDateTime(formikProps.values.dueDateTime)}
                            >
                              Salvar
                            </Button>
                          )}
                          <Button
                            style={isMobile ? { marginBottom: 5 } : {}}
                            type={invoiceSituation === 'Paga' ? 'button' : 'submit'}
                            color="success"
                            loading={isDetailing}
                            disabled={invoiceSituation === 'Cancelada'}
                            onClick={null}
                          >
                            {handleSaveButton()}
                          </Button>
                        </>
                      )}
                      {!isNfInfo && (
                        <ButtonConfirmation
                          title="Enviar NFS-e"
                          color="success"
                          loading={isSendNfseLoading}
                          modalTitle="Enviar NFS-e"
                          modalText="Tem certeza que deseja enviar a nota fiscal?"
                          modalButtons={[
                            {
                              color: 'success',
                              title: 'Sim',
                              onClick: () => dispatch(sendNfse(userId, submit?.establishmentId, `${clientId}${invoiceDateTime}`))
                            }
                          ]}
                        />
                      )}
                    </div>
                  </MuiDialogActions>
                </Form>
              </>
            );
          }}
        </Formik>
      </Modal>
    </>
  );
}

InvoicePos.propTypes = {
  pageOrigin: PropTypes.string,
  setIsOpenInvoicePos: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired
};

InvoicePos.defaultProps = {
  pageOrigin: 'invoices'
};