import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { map, range } from 'ramda';
import { toast } from 'react-toastify';

import { Paper, Grid, Typography, Tooltip } from '@material-ui/core';
import Status from 'components/Status';
import Loader from 'components/Loader';
import Input from 'components/Input';
import Select from 'components/Select';
import SingleDatePicker from 'components/SingleDatePicker';
import Button from 'components/Button';

import { clearComissionedErrors } from '../store/actions';
import { loadComissioned, upsertComissioned } from '../store/thunk';
import { loadFilters } from 'components/Filter/store/thunk';

import { convertToOptions, convertDateToDB, convertToReal, convertFormattedMoneyToNumber } from 'helpers/converters';
import { formatAvailableEstablishmentsToUuid } from '../../../helpers/formatters';

import { useStyles } from './styles';

export default function ComissionedRegistration() {
  const dispatch = useDispatch();

  const classes = useStyles();

  const history = useHistory();

  const isRegistration = history.location.pathname == '/novo-funcionario';
  const uuid = history?.location?.state?.data?.uuid || '';

  const { userId } = useSelector(state => state.profile);
  const { establishmentId } = useSelector(state => state.businessInfo);
  const { establishments } = useSelector(state => state.login);
  const filters = useSelector(state => state.filters.selects);
  const { isLoading, submit, submitErrors } = useSelector(state => state.comissionedRegistration);
  const { employeeRoles } = useSelector(state => state.comissionedList.filters);

  const establishmentUuid = establishments?.find(establishment => establishment?.establishmentId == establishmentId)?.uuid;

  const payrollCategories = filters
    ?.find(filter => filter?.id == 'category')?.options
    ?.filter(category => +category?.categorySourceId == 9) || [];

  const [availableEstablishments, setAvailableEstablishments] = useState([]);

  const [employeeData, setEmployeeData] = useState({
    name: null,
    status: 1,
    remuneration: null,
    roleId: null,
    userUuid: null,
    establishmentUuid: isRegistration ? establishmentUuid : null,
    payDay: null,
    frequencyId: 1,
    firstPaymentDate: null,
    payrollCategoryId: null
  });

  useEffect(() => {
    dispatch(clearComissionedErrors());
  }, []);

  useEffect(() => {
    dispatch(loadFilters(userId, establishmentId));
  }, []);

  useEffect(() => {
    if(!isRegistration) {
      dispatch(loadComissioned(userId, establishmentId, uuid));
    }
  }, [isRegistration]);

  useEffect(() => {
    if(!isRegistration) {
      setEmployeeData(submit);
    }
  }, [isRegistration, submit]);

  useEffect(() => {
    const formattedAvailableEstablishments = formatAvailableEstablishmentsToUuid(establishments)
      ?.map(establishment => {
        return {
          ...establishment,
          value: establishment?.uuid,
          label: establishment?.label
        }
      })
      ?.filter(establishment => +establishment?.premiumPackage == 1);

    setAvailableEstablishments(formattedAvailableEstablishments);
  }, []);

  const monthDays = map(day => ({ id: 'invoiceDueDay', label: day, value: day }), range(1, 32));

  const profiles = employeeRoles?.options
    ?.map(role => {
      return {
        label: role?.name,
        value: role?.id
      }
    });

  const users = filters?.find(filter => filter?.id == 'user')?.options
    ?.map(user => {
      return {
        label: user?.label,
        value: user?.userUuid
      }
    });

  const availableFrequencies =
    convertToOptions(['frequency'], filters)
    .filter(value => value.label !== '--');

  const handleRegistration = () => {
    const employee = {
      ...employeeData,
      remuneration: convertFormattedMoneyToNumber(employeeData.remuneration),
      firstPaymentDate: convertDateToDB(employeeData.firstPaymentDate),
      userUuid: !!employeeData.userUuid ? employeeData.userUuid : ''
    }

    const establishmentId = establishments
      ?.find(establishment => establishment?.uuid == employeeData.establishmentUuid)?.establishmentId;

    dispatch(upsertComissioned(userId, establishmentId, uuid, employee, history));
  }

  return (
    <Paper className={classes.paper}>
      <Loader isLoading={isLoading}>
        <Grid container>
          <Grid item md={6} xs={12} className={classes.grid}>
            <Input
              name='name'
              label='Nome'
              value={employeeData.name || ''}
              error={!!submitErrors?.nameError}
              helperText={submitErrors?.nameError}
              onChange={event => setEmployeeData({ ...employeeData, name: event.target.value })}
            />
          </Grid>
          <Grid item md={6} xs={12} className={classes.grid}>
            <Status
              name='status'
              value={employeeData?.status?.toString()}
              onChange={event => setEmployeeData({ ...employeeData, status: event.target.value })}
            />
          </Grid>
          <Grid item md={6} xs={12} className={classes.grid}>
            <Select
              name='roleId'
              label='Cargo'
              options={profiles}
              value={employeeData.roleId || ''}
              error={!!submitErrors?.roleIdError}
              helperText={submitErrors?.roleIdError}
              onChange={event => setEmployeeData({ ...employeeData, roleId: event.target.value })}
            />
          </Grid>
          <Grid item md={6} xs={12} className={classes.grid}>
            <Select
              name='payrollCategoryId'
              label='Categoria da despesa'
              options={payrollCategories}
              value={+employeeData.payrollCategoryId || ''}
              error={!!submitErrors?.payrollCategoryIdError}
              helperText={submitErrors?.payrollCategoryIdError}
              onChange={event => setEmployeeData({ ...employeeData, payrollCategoryId: event.target.value })}
            />
          </Grid>
          <Tooltip
            placement='top'
            title={
              <Typography style={{ fontSize: 15 }}>
                Este estabelecimento é necessário para o controle financeiro das folhas de pagamento do funcionário.
              </Typography>
            }
          >
            <Grid item md={6} xs={12} className={classes.grid}>
              <Select
                disabled
                name='establishmentUuid'
                label='Estabelecimento financeiro'
                options={availableEstablishments}
                value={employeeData.establishmentUuid}
                error={!!submitErrors?.establishmentUuidError}
                helperText={submitErrors?.establishmentUuidError}
                onChange={event => setEmployeeData({ ...employeeData, establishmentUuid: event.target.value })}
              />
            </Grid>
          </Tooltip>
          <Grid item md={6} xs={12} className={classes.grid}>
            <Select
              name='userUuid'
              label='Vincular usuário'
              options={users}
              value={employeeData.userUuid || ''}
              onChange={event => setEmployeeData({ ...employeeData, userUuid: event.target.value })}
            />
          </Grid>
          <Grid item md={6} xs={12} className={classes.grid}>
            <Select
              name='frequencyId'
              label='Frequência'
              options={availableFrequencies}
              value={employeeData.frequencyId || ''}
              error={!!submitErrors?.frequencyIdError}
              helperText={submitErrors?.frequencyIdError}
              onChange={event => setEmployeeData({ ...employeeData, frequencyId: event.target.value })}
            />
          </Grid>
          {(+employeeData.frequencyId == 1) && (
            <Grid item md={6} xs={12} className={classes.grid}>
              <Select
                name='payDay'
                label='Pagar sempre no dia'
                options={monthDays}
                value={employeeData.payDay || ''}
                error={!!submitErrors?.payDayError}
                helperText={submitErrors?.payDayError}
                onChange={event => setEmployeeData({ ...employeeData, payDay: event.target.value })}
              />
            </Grid>
          )}
          {isRegistration && (
            <Grid item md={6} xs={12} className={classes.grid}>
              <SingleDatePicker
                name='firstPaymentDate'
                label='Data do primeiro pagamento'
                initialDate={convertDateToDB(employeeData.firstPaymentDate) || ''}
                onDateChange={date => setEmployeeData({ ...employeeData, firstPaymentDate: date })}
                blockPastDates
                onBlockedDate={() => toast.error('A data do primeiro pagamento não pode ser anterior a data atual.')}
                error={!!submitErrors?.firstPaymentDateError}
                helperText={submitErrors?.firstPaymentDateError}
              />
            </Grid>
          )}
          <Grid item md={6} xs={12} className={classes.grid}>
            <Input
              type='money'
              name='remuneration'
              label='Salário'
              value={convertToReal(convertFormattedMoneyToNumber(employeeData.remuneration)) || ''}
              onChange={event => setEmployeeData({ ...employeeData, remuneration: event.target.value })}
            />
          </Grid>
          <Grid item xs={12} className={classes.buttonGrid}>
            <Button
              type='submit'
              color='success'
              onClick={handleRegistration}
            >
              Salvar
            </Button>
          </Grid>
        </Grid>
      </Loader>
    </Paper>
  );
}