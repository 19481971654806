import { set, lensProp } from 'ramda';
import { SELECT_CLIENT } from './constants';

const defaultState = {
  clientTypeId: null,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case SELECT_CLIENT: {
      return set(lensProp('clientTypeId'), action.clientTypeId, state);
    }
    default: {
      return state;
    }
  }
};
