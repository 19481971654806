import { toast } from 'react-toastify';

import {
  fetchProduct,
  createProduct,
  updateProduct,
  updateProductIdentifiers,
  fetchSupplier,
  fetchSupplierList,
  createSupplier,
  updateSupplier,
  fetchServiceProduct,
  createServiceProduct,
  removeServiceProduct
} from './services';

import {
  setProductLoading,
  setSupplierLoading,
  setProduct,
  setSupplier,
  setSuppliers,
  setSupplierErrors,
  clearSupplierErrors,
  setProductBasic,
  setProductBasicAndStorageResponseErrors,
  clearProductBasicAndStorageResponseErrors,
  setProductConsumption,
  setProductConsumptionErrors,
  clearProductConsumptionErrors
} from './actions';

export const loadProduct = (userId, establishmentId, storageProductUuid) =>  dispatch => {
  dispatch(setProductLoading(true));

  return fetchProduct(userId, establishmentId, storageProductUuid)
    .then(({ data }) => dispatch(setProduct(data)))
    .finally(() => dispatch(setProductLoading(false)));
}

export const upsertProduct = (userId, establishmentId, storageProductUuid, params) => dispatch => {
  dispatch(setProductLoading(true));

  if(!storageProductUuid) {
    return createProduct(userId, establishmentId, params)
      .then(({ data }) => {
        dispatch(setProduct(data));
        dispatch(clearProductBasicAndStorageResponseErrors());

        toast.success('Produto criado com sucesso!');

        return Promise.resolve(data?.StorageProduct);
      })
      .catch(data => {
        if(+data?.httpCode == 422) {
          toast.error('Houve um problema ao criar o produto');
          dispatch(setProductBasicAndStorageResponseErrors(data?.errors));
        }

        return Promise.reject();
      })
      .finally(() => dispatch(setProductLoading(false)));
  }

  return updateProduct(userId, establishmentId, storageProductUuid, params)
    .then(({ data }) => {
      dispatch(setProduct(data));
      dispatch(clearProductBasicAndStorageResponseErrors());

      toast.success('Produto atualizado com sucesso!');

      return Promise.resolve(data?.StorageProduct);
    })
    .catch(data => {
      if(+data?.httpCode == 422) {
        toast.error('Houve um problema ao atualizar o produto');
        dispatch(setProductBasicAndStorageResponseErrors(data?.errors));
      }

      return Promise.reject();
    })
    .finally(() => dispatch(setProductLoading(false)));
}

export const insertProductIdentifiers = (userId, establishmentId, storageProductUuid, params) => dispatch => {
  dispatch(setProductLoading(true));

  return updateProductIdentifiers(userId, establishmentId, storageProductUuid, params)
    .then(({ data }) => {
      toast.success('Identificadores atualizados com sucesso');
      return Promise.resolve(data);
    })
    .catch(() => {
      toast.error('Houve um problema ao atualizar os identificadores');
      return Promise.reject();
    })
    .finally(() => dispatch(setProductLoading(false)));
}

export const loadSupplier = (userId, establishmentId, supplierUuid) => dispatch => {
  dispatch(setSupplierLoading(true));

  return fetchSupplier(userId, establishmentId, supplierUuid)
    .then(({ data }) => dispatch(setSupplier(data)))
    .finally(() => dispatch(setSupplierLoading(false)));
}

export const loadSupplierList = (userId, establishmentId) => dispatch => {
  dispatch(setSupplierLoading(true));

  return fetchSupplierList(userId, establishmentId)
    .then(({ data }) => dispatch(setSuppliers(data)))
    .finally(() => dispatch(setSupplierLoading(false)));
}

export const upsertSupplier = (userId, establishmentId, supplierUuid, params) => dispatch => {
  dispatch(setSupplierLoading(true));

  dispatch(clearSupplierErrors());

  if(!supplierUuid) {
    return createSupplier(userId, establishmentId, params)
      .then(({ data }) => {
        const { id } = data;

        dispatch(setProductBasic({ supplierId: id }));
        dispatch(loadSupplierList(userId, establishmentId));

        toast.success('Fornecedor cadastrado com successo!');

        return Promise.resolve();
      })
      .catch(error => {
        if(error?.httpCode) {
          dispatch(setSupplierErrors(error?.errors));
        }

        toast.error('Houve um problema ao cadastrar o fornecedor');

        return Promise.reject();
      })
      .finally(() => dispatch(setSupplierLoading(false)));
  }

  return updateSupplier(userId, establishmentId, supplierUuid, params)
    .then(({ data }) => {
      const { id } = data;

      dispatch(setProductBasic({ supplierId: id }));
      dispatch(loadSupplierList(userId, establishmentId));

      toast.success('Fornecedor atualizado com successo!');

      return Promise.resolve();
    })
    .catch(error => {
      if(error?.httpCode) {
        dispatch(setSupplierErrors(error?.errors));
      }

      toast.error('Houve um problema ao atualizar o fornecedor');

      return Promise.reject();
    })
    .finally(() => dispatch(setSupplierLoading(false)));
}

export const loadServiceProduct = (userId, establishmentId, storageProductUuid) => dispatch => {
  dispatch(setProductLoading(true));

  return fetchServiceProduct(userId, establishmentId, storageProductUuid)
    .then(({ data }) => dispatch(setProductConsumption(data || [])))
    .finally(() => dispatch(setProductLoading(false)));
}

export const insertServiceProduct = (userId, establishmentId, params) => dispatch => {
  dispatch(setProductLoading(true));

  return createServiceProduct(userId, establishmentId, params)
    .then(() => {
      dispatch(clearProductConsumptionErrors());
      dispatch(loadServiceProduct(userId, establishmentId, params?.storageProductUuid));

      toast.success('Serviço criado com sucesso.');

      return Promise.resolve();
    })
    .catch(data => dispatch(setProductConsumptionErrors(data)))
    .finally(() => dispatch(setProductLoading(false)));
}

export const deleteServiceProduct = (userId, establishmentId, params) => dispatch => {
  dispatch(setProductLoading(true));

  return removeServiceProduct(userId, establishmentId, params?.id)
    .then(() => {
      dispatch(clearProductConsumptionErrors());
      dispatch(loadServiceProduct(userId, establishmentId, params?.storageProductUuid));

      toast.success('Serviço removido com sucesso.');
    })
    .finally(() => dispatch(setProductLoading(false)));
}