import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { Grid, Typography } from '@material-ui/core';
import { Row } from '../../Resources';
import ActivitiesRegistrationHeader from '../../ActivitiesRegistrationHeader';

import { convertToReal } from 'helpers/converters';

export default function PayrollSheetUpdate({ lastRegister, activityData, payrollSheet }) {
  return(
    <Grid container>
      <Grid xs={12}>
        <ActivitiesRegistrationHeader activityData={activityData} />
        <Grid container style={{ marginTop: 10 }}>
          <Grid xs={12} md={6} style={{ padding: 5 }}>
            <Typography style={{ color: '#1e5168', marginBottom: 5 }}>Folha de Pagamento Anterior</Typography>
            <Grid style={{ border: '1px solid #1e5168', borderRadius: 8, padding: 8 }}>
              <Row>
                <Typography>Funcionário</Typography>
                <Typography style={{ whiteSpace: 'nowrap', marginLeft: 5 }}>
                  {lastRegister?.name}
                </Typography>
              </Row>
              <Row>
                <Typography>Data de vencimento</Typography>
                <Typography style={{ marginLeft: 15 }}>
                  {moment(lastRegister?.dueDate).format('DD/MM/YYYY')}
                </Typography>
              </Row>
              <Row>
                <Typography>Valor</Typography>
                <Typography>{convertToReal(lastRegister?.paidAmount)}</Typography>
              </Row>
            </Grid>
          </Grid>
          <Grid xs={12} md={6} style={{ padding: 5 }}>
            <Typography style={{ color: '#1e5168', marginBottom: 5 }}>Folha de Pagamento Atual</Typography>
            <Grid style={{ border: '1px solid #1e5168', borderRadius: 8, padding: 8 }}>
              <Row>
                <Typography>Funcionário</Typography>
                <Typography style={{ whiteSpace: 'nowrap', marginLeft: 5 }}>
                  {payrollSheet?.name}
                </Typography>
              </Row>
              <Row>
                <Typography>Data de vencimento</Typography>
                <Typography style={{ marginLeft: 15 }}>
                  {moment(payrollSheet?.dueDate).format('DD/MM/YYYY')}
                </Typography>
              </Row>
              <Row>
                <Typography>Valor</Typography>
                <Typography>{convertToReal(payrollSheet?.paidAmount)}</Typography>
              </Row>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

PayrollSheetUpdate.propTypes = {
  lastRegister: PropTypes.object.isRequired,
  activityData: PropTypes.object.isRequired,
  payrollSheet: PropTypes.object.isRequired
};