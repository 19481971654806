import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import MuiDialogActions from '@material-ui/core/DialogActions';

import { Formik, Form } from 'formik';

import Modal from '../../../../components/Modal';
import Loader from '../../../../components/Loader';
import Input from '../../../../components/Input';
import Button from '../../../../components/Button';
import Status from '../../../../components/Status';
import { upsertCostCenter } from '../../store/thunk';
import { handleRegistration, clearRegistration } from '../../store/actions';
import { validations } from './validations';

export default function CostCenterRegistration({ isOpen }) {
  const dispatch = useDispatch();

  const { userId } = useSelector(state => state.profile);
  const { establishmentId } = useSelector(state => state.businessInfo);
  const page = useSelector(state => state.pagination.activePage);
  const pageSize = useSelector(state => state.pagination.size);
  const { qp } = useSelector(state => state.filters);
  const { isLoading, costCenterId, costCenterName, status } = useSelector(state => state.costCenters);

  const initialState = {
    costCenterId,
    costCenterName,
    status
  }

  const handleModal = () => {
    dispatch(handleRegistration(false));
    dispatch(clearRegistration());
  }

  const handleSave = values => {
    const properties = { page, pageSize, qp };
    const params = { costCenterId, ...values };

    dispatch(upsertCostCenter(userId, establishmentId, params, properties));
  }

  return(
    <Formik
      enableReinitialize
      initialValues={initialState}
      validationSchema={validations}
      onSubmit={params => handleSave(params)}
    >
      {({ ...formikProps }) => (
        <Modal
          id="cost-center"
          title="Cadastro de centro de custo"
          maxWidth="sm"
          open={isOpen}
          onClose={handleModal}
        >
          <Form>
            <Loader isLoading={isLoading}>
              <Grid container spacing={2}>
                <Grid item md={8}>
                  <Input
                    autoFocus
                    id="categoryName"
                    name="costCenterName"
                    label="Nome"
                    value={formikProps.values.costCenterName}
                    error={formikProps.errors.costCenterName}
                    helperText={formikProps.errors.costCenterName}
                    onChange={formikProps.handleChange}
                  />
                </Grid>
                <Grid item md={4}>
                  <Status
                    value={formikProps.values.status}
                    onChange={formikProps.handleChange}
                  />
                </Grid>
              </Grid>
            </Loader>

            <MuiDialogActions>
              <Button color="default" loading={isLoading} onClick={handleModal}>
                Cancelar
              </Button>
              <Button color="primary" loading={isLoading}>
                {costCenterId ? 'Salvar' : 'Adicionar'}
              </Button>
            </MuiDialogActions>
          </Form>
        </Modal>
      )}
    </Formik>
  );
}

CostCenterRegistration.propTypes = {
  isOpen: PropTypes.bool.isRequired
};