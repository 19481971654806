import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Formik, Form } from 'formik';
import { Grid, Typography } from '@material-ui/core';
import Modal from 'components/Modal';
import Loader from 'components/Loader';
import Input from 'components/Input';
import Status from 'components/Status';
import CEP from 'components/CEP';
import Button from 'components/Button';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';

import { clearSupplier } from '../../pages/ProductsSellRegistration/store/actions';
import { loadSupplier, upsertSupplier } from '../../pages/ProductsSellRegistration/store/thunk';

import { emailRegExp, phoneRegExp } from 'helpers/regex';

import { validations, contactValidations } from './validations';

export default function Supplier({ isSupplierModalOpen, setIsSupplierModalOpen }) {
  const dispatch = useDispatch();

  const { userId } = useSelector(state => state.profile);
  const { establishmentId } = useSelector(state => state.businessInfo);
  const { supplier, supplierErrors, supplierUuid, isSupplierLoading } = useSelector(state => state.productsSellRegistration);

  const [initialContact, setInitialContact] = useState({
    name: '',
    email: '',
    phone: ''
  });

  useEffect(() => {
    dispatch(clearSupplier());

    if(supplierUuid) {
      dispatch(loadSupplier(userId, establishmentId, supplierUuid));
    }
  }, []);

  const handleAddContact = (field, value, index, contacts, setFieldValue) => {
    let contactsArray = contacts;
    const currentContact = contacts[index];

    const updatedCurrentContact = {
      ...currentContact,
      [field]: value
    }

    contactsArray[index] = updatedCurrentContact;

    return setFieldValue('contacts', contactsArray);
  }

  const handleAddContactLine = (values, contacts, setFieldValue, resetForm) => {
    setInitialContact({
      name: '',
      email: '',
      phone: ''
    });

    let contactsArray = contacts;

    contactsArray = [
      values,
      ...contacts ? contacts : []
    ];

    setFieldValue('contacts', contactsArray);

    return resetForm();
  }

  const handleRemoveContactLine = (contacts, index, setFieldValue) => {
    let contactsArray = contacts;

    contactsArray.splice(index, 1);

    return setFieldValue('contacts', contactsArray);
  }

  const checkContactToSubmit = (contacts, initialContact) => {
    const isInitialContactValid = !!initialContact?.name && !!emailRegExp?.test(initialContact?.email) && !!phoneRegExp?.test(initialContact?.phone);

    if(contacts?.length > 0) {
      if(isInitialContactValid) {
        return [
          initialContact,
          ...contacts
        ]
      }

      return contacts;
    }

    if(contacts?.length == 0) {
      if(isInitialContactValid) {
        return [initialContact];
      }

      return '[]';
    }
  }

  const handleSubmit = params => {
    const {
      cnpj,
      active,
      fantasyName,
      corporateName,
      contacts,
      observations,
      cep,
      address,
      addressNumber,
      complement,
      city,
      district,
      state
    } = params;

    const submitParams = {
      supplierInfo: {
        cnpj,
        fantasyName,
        corporateName
      },
      contacts: checkContactToSubmit(contacts, initialContact),
      observations,
      active,
      addresses: [
        {
          cep,
          address,
          addressNumber,
          complement,
          city,
          district,
          state
        }
      ]
    }

    dispatch(upsertSupplier(userId, establishmentId, supplierUuid, submitParams))
      .then(() => setIsSupplierModalOpen(false));
  }

  return(
    <Modal
      id='supplier'
      title='Insira as informações básicas do fornecedor de produto'
      scroll='body'
      maxWidth='md'
      open={isSupplierModalOpen}
      onClose={() => setIsSupplierModalOpen(false)}
    >
      <Formik
        enableReinitialize
        initialValues={supplier}
        validationSchema={validations}
        validateOnChange={false}
        onSubmit={handleSubmit}
      >
        {({ ...formikProps }) => {
          return(
            <Form>
              <Loader isLoading={isSupplierLoading}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="h6" color="primary">Fornecedor</Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Input
                      type="document"
                      name="cnpj"
                      label="CNPJ"
                      value={formikProps.values.cnpj}
                      error={formikProps.errors.cnpj || !!supplierErrors?.cnpj}
                      helperText={formikProps.errors.cnpj || supplierErrors?.cnpj}
                      onChange={formikProps.handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Status
                      name="active"
                      value={formikProps.values.active}
                      onChange={formikProps.handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Input
                      type="text"
                      name="fantasyName"
                      label="Nome Fantasia"
                      value={formikProps.values.fantasyName}
                      error={formikProps.errors.fantasyName || !!supplierErrors?.fantasyName}
                      helperText={formikProps.errors.fantasyName || supplierErrors?.fantasyName}
                      onChange={formikProps.handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Input
                      type="text"
                      name="corporateName"
                      label="Razão Social"
                      value={formikProps.values.corporateName}
                      error={formikProps.errors.corporateName || !!supplierErrors?.corporateName}
                      helperText={formikProps.errors.corporateName || supplierErrors?.corporateName}
                      onChange={formikProps.handleChange}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="h6" color="primary">Contatos</Typography>
                  </Grid>
                </Grid>
                <Formik
                  enableReinitialize
                  initialValues={{
                    name: '',
                    email: '',
                    phone: ''
                  }}
                  validationSchema={contactValidations}
                  validateOnChange={false}
                  onSubmit={(values, { resetForm }) => handleAddContactLine(values, formikProps.values.contacts, formikProps.setFieldValue, resetForm)}
                >
                  {({ ...formikProps }) => {
                    return(
                      <Form>
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={4}>
                            <Input
                              type="text"
                              name="name"
                              label="Nome"
                              value={formikProps.values.name}
                              error={formikProps.errors.name}
                              helperText={formikProps.errors.name}
                              onChange={event => {
                                setInitialContact({ ...initialContact, name: event.target.value });
                                formikProps.setFieldValue('name', event.target.value);
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <Input
                              type="email"
                              name="email"
                              label="E-mail"
                              value={formikProps.values.email}
                              error={formikProps.errors.email}
                              helperText={formikProps.errors.email}
                              onChange={event => {
                                setInitialContact({ ...initialContact, email: event.target.value });
                                formikProps.setFieldValue('email', event.target.value);
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} md={3}>
                            <Input
                              type="phone"
                              name="phone"
                              label="Telefone"
                              value={formikProps.values.phone}
                              error={formikProps.errors.phone}
                              helperText={formikProps.errors.phone}
                              onChange={event => {
                                setInitialContact({ ...initialContact, phone: event.target.value });
                                formikProps.setFieldValue('phone', event.target.value);
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} md={1} style={{ display: 'flex', alignItems: 'center' }}>
                            <Button
                              style={{ height: '50px' }}
                              type="submit"
                              color="primary"
                            >
                              <FontAwesomeIcon
                                size="lg"
                                color="#FFFFFF"
                                icon={faPlus}
                              />
                            </Button>
                          </Grid>
                        </Grid>
                      </Form>
                    )
                  }}
                </Formik>
                {Array.isArray(formikProps.values?.contacts) && formikProps.values?.contacts?.length > 0 && formikProps.values?.contacts?.map((contact, index) => {
                  return(
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={4}>
                        <Input
                          type="text"
                          name="name"
                          label="Nome"
                          value={contact.name}
                          error={!!formikProps?.errors?.contacts && formikProps?.errors?.contacts[index]?.name}
                          helperText={!!formikProps?.errors?.contacts && formikProps?.errors?.contacts[index]?.name}
                          onChange={event => handleAddContact(event.target.name, event.target.value, index, formikProps.values.contacts, formikProps.setFieldValue)}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Input
                          type="email"
                          name="email"
                          label="E-mail"
                          value={contact.email}
                          error={!!formikProps?.errors?.contacts && formikProps?.errors?.contacts[index]?.email}
                          helperText={!!formikProps?.errors?.contacts && formikProps?.errors?.contacts[index]?.email}
                          onChange={event => handleAddContact(event.target.name, event.target.value, index, formikProps.values.contacts, formikProps.setFieldValue)}
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <Input
                          type="phone"
                          name="phone"
                          label="Telefone"
                          value={contact.phone}
                          error={!!formikProps?.errors?.contacts && formikProps?.errors?.contacts[index]?.phone}
                          helperText={!!formikProps?.errors?.contacts && formikProps?.errors?.contacts[index]?.phone}
                          onChange={event => handleAddContact(event.target.name, event.target.value, index, formikProps.values.contacts, formikProps.setFieldValue)}
                        />
                      </Grid>
                      <Grid item xs={12} md={1} style={{ display: 'flex', alignItems: 'center' }}>
                        <Button
                          style={{ height: '50px' }}
                          type="button"
                          color="error"
                          onClick={() => handleRemoveContactLine(formikProps.values.contacts, index, formikProps.setFieldValue)}
                        >
                          <FontAwesomeIcon
                            size="lg"
                            color="#FFFFFF"
                            icon={faTrash}
                          />
                        </Button>
                      </Grid>
                    </Grid>
                  );
                })}
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Input
                      type="text"
                      name="observations"
                      label="Observação"
                      value={formikProps.values.observations}
                      onChange={formikProps.handleChange}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="h6" color="primary">Endereço</Typography>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <CEP
                      name="cep"
                      label="CEP"
                      value={formikProps.values.cep || ""}
                      error={formikProps.errors.cep || !!supplierErrors?.cep}
                      helperText={formikProps.errors.cep || supplierErrors?.cep}
                      onChange={postalCode => {
                        if(!postalCode.erro) {
                          const { cep, logradouro, complemento, bairro, localidade, uf } = postalCode;

                          formikProps.setFieldValue('cep', cep);
                          formikProps.setFieldValue('address', logradouro);
                          formikProps.setFieldValue('complement', complemento);
                          formikProps.setFieldValue('district', bairro);
                          formikProps.setFieldValue('city', localidade);
                          formikProps.setFieldValue('state', uf);
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Input
                      type="text"
                      name="address"
                      label="Endereço"
                      value={formikProps.values.address || ""}
                      error={formikProps.errors.address || !!supplierErrors?.address}
                      helperText={formikProps.errors.address || supplierErrors?.address}
                      onChange={formikProps.handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Input
                      type="number"
                      name="addressNumber"
                      label="Número"
                      value={formikProps.values.addressNumber || ""}
                      error={formikProps.errors.addressNumber || !!supplierErrors?.addressNumber}
                      helperText={formikProps.errors.addressNumber || supplierErrors?.addressNumber}
                      onChange={formikProps.handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Input
                      type="text"
                      name="complement"
                      label="Complemento"
                      value={formikProps.values.complement || ""}
                      onChange={formikProps.handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Input
                      type="text"
                      name="city"
                      label="Cidade"
                      value={formikProps.values.city || ""}
                      error={formikProps.errors.city || !!supplierErrors?.city}
                      helperText={formikProps.errors.city || supplierErrors?.city}
                      onChange={formikProps.handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Input
                      type="text"
                      name="district"
                      label="Bairro"
                      value={formikProps.values.district || ""}
                      error={formikProps.errors.district || !!supplierErrors?.district}
                      helperText={formikProps.errors.district || supplierErrors?.district}
                      onChange={formikProps.handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={1}>
                    <Input
                      type="text"
                      name="state"
                      label="UF"
                      value={formikProps.values.state || ""}
                      error={formikProps.errors.state || !!supplierErrors?.state}
                      helperText={formikProps.errors.state || supplierErrors?.state}
                      onChange={formikProps.handleChange}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button type="submit" color="success">
                      Salvar
                    </Button>
                  </Grid>
                </Grid>
              </Loader>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
}