import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const buttonBackgroundColor = ({ props, palette, type }) => {
  let bg = props.color;
  const { success, error } = palette;

  if (props.color === 'success') {
    bg = type === 'main' ? success.main : success.dark;
  }

  if (props.color === 'error') {
    bg = type === 'main' ? error.main : error.dark;
  }

  return bg;
};

export const StyledButton = withStyles(({ palette, spacing }) => ({
  root: {
    backgroundColor: props =>
      buttonBackgroundColor({ props, palette, type: 'main' }),
    color: props =>
      props.color !== 'inherit' && props.color !== 'default'
        ? 'white'
        : palette.primary.main,
    padding: spacing(1),
    fontSize: spacing(1.8),
    margin: props => props.verticalGutter && spacing(1, 0),
    '&:hover': {
      backgroundColor: props =>
        buttonBackgroundColor({ props, palette, type: 'hover' }),
    },
  },
  label: {
    textTransform: 'uppercase',
  },
}))(Button);
