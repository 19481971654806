import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ palette, spacing }) => ({
  success: {
    color: palette.success.main
  },
  error: {
    color: palette.error.main
  },
  title: {
    fontWeight: 'bold',
    marginRight: spacing(1)
  },
  container: {
    marginTop: spacing(2)
  },
  itemContainer: {
    marginBottom: spacing(1)
  },
  marginTop: {
    marginTop: 10
  }
}));