import React from 'react';
import PropTypes from 'prop-types';

import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import PeriodRange from '../PeriodRange';

import { convertToReal } from '../../../../helpers/converters';

import { useStyles } from '../styles';

const ResumeValues = ({ value }) => {
  return (
    <Grid container justify="center">
      <Grid item>
        <Typography variant="subtitle1" color="primary">
          R$
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="h4" color="primary">
          {value}
        </Typography>
      </Grid>
    </Grid>
  );
};

const Resume = ({ resume }) => {
  const classes = useStyles();

  return (
    <Container className={classes.container}>
      <Box mt={2}>
        <Box pt={0} pb={5}>
          <Grid
            container
            spacing={2}
            justify="space-between"
            alignItems="center"
          >
            <Grid item xs={12} md={6}>
              <Typography variant="h5" color="primary">
                Resumo do Fluxo de Caixa
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} alignContent="flex-end">
              <Grid container justify="flex-end" direction="row">
                <PeriodRange />
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={4}>
            <Paper align="center" className={classes.paperSuccess}>
              <Typography paragraph variant="h5" color="success">
                Entradas
              </Typography>
              <ResumeValues value={convertToReal(resume.receipts, false)} />
            </Paper>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Paper align="center" className={classes.paperError}>
              <Typography paragraph variant="h5" color="success">
                Saídas
              </Typography>
              <ResumeValues value={convertToReal(resume.payments, false)} />
            </Paper>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Paper align="center" className={classes.paperPrimary}>
              <Typography paragraph variant="h5" color="success">
                Total
              </Typography>
              <ResumeValues value={convertToReal(resume.balance, false)} />
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

ResumeValues.propTypes = {
  value: PropTypes.string.isRequired,
};

Resume.propTypes = {
  resume: PropTypes.arrayOf(
    PropTypes.shape({
      receipts: PropTypes.string,
      payments: PropTypes.string,
      balance: PropTypes.string,
    })
  ).isRequired,
};

export default Resume;
